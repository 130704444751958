import { Box, Chip, Fade, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';

import { ReactComponent as InterestsIcon } from 'Assets/images/MeditationContent/interests-icon.svg';
import { ReactComponent as HeartIcon } from 'Assets/images/Header/heart-icon.svg';
import { ReactComponent as LikeIcon } from 'Assets/images/MeditationContent/like-icon.svg';
import { ReactComponent as PlayIcon } from 'Assets/images/MeditationContent/play-icon.svg';
import { digitsFormatter } from 'Helpers';
import TaoScrollbar from 'Components/common/Scrollbar';
import DOMPurify from 'dompurify';
import ProgramAbout from 'Components/Pages/Programs/ProgramAbout';
import { BREAKPOINTS_VALUE } from 'Styles/Constants';
import { getWindowDimensions } from 'Helpers';
import ButtonState from '../../ButtonStates/ButtonState';
import { PHASES } from 'Helpers/Constants';

const TAB_LIST = {
    ABOUT: {
        ID: 1,
        VALUE: 'About',
        htmlId: 'about-tab'
    },
    REVIEW: {
        ID: 2,
        VALUE: 'Review',
        htmlId: 'review-tab'
    }
};

const AboutTab = ({ contentDetails, windowDimensions, ...props }) => {
    return (
        <Fade in={true}>
            <Box>
                {!contentDetails?.isInstructional && (
                    <Box className="flex">
                        <Box className="flex meditation-review-container">
                            <InterestsIcon className="icon-margin" />
                            <Typography className="count-text">
                                {digitsFormatter(contentDetails?.ratingsCount)} felt better
                            </Typography>
                        </Box>

                        <Box className="flex meditation-review-container">
                            <LikeIcon className="icon-margin " />
                            <Typography className="count-text">
                                {digitsFormatter(contentDetails?.likesCount)}
                            </Typography>
                        </Box>

                        <Box className="flex meditation-review-container">
                            <HeartIcon className="icon-margin" />
                            <Typography className="count-text">
                                {digitsFormatter(contentDetails?.favouritesCount)}
                            </Typography>
                        </Box>

                        <Box className="flex meditation-review-container">
                            <PlayIcon className="icon-margin" />
                            <Typography className="count-text">
                                {digitsFormatter(contentDetails?.viewCounts)}
                            </Typography>
                        </Box>
                    </Box>
                )}

                <Box height={contentDetails?.isInstructional ? 250 : 160}>
                    <TaoScrollbar>
                        <Box
                            style={{
                                margin: `${
                                    contentDetails?.isInstructional ? `20px 10px` : `0px 10px`
                                }`
                            }}>
                            <Typography
                                className="about-description"
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(contentDetails?.description, {
                                        FORBID_TAGS: ['p']
                                    })
                                }}
                            />
                        </Box>
                    </TaoScrollbar>
                </Box>

                {false && (
                    <Box>
                        {!contentDetails?.isInstructional && (
                            <TaoScrollbar keyId="category-chip-list" horizontalScroll>
                                {contentDetails?.tags?.map((item, index) => (
                                    <React.Fragment key={index}>
                                        <Box className="category-chip-wrapper">
                                            <Typography className="category-chip-text width-max-content">
                                                {item}
                                            </Typography>
                                        </Box>
                                    </React.Fragment>
                                ))}
                            </TaoScrollbar>
                        )}
                    </Box>
                )}
                {(windowDimensions.width < BREAKPOINTS_VALUE.TABLET ||
                    contentDetails?.isInstructional) && (
                    <Box className="bottom-button">
                        <ButtonState contentDetails={contentDetails} {...props} />
                    </Box>
                )}
            </Box>
        </Fade>
    );
};

const ContentPhasesDetails = ({ ...props }) => {
    const [selectedChip, setSelectedChip] = useState(TAB_LIST.ABOUT.ID);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions());
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [windowDimensions.width]);

    return (
        <Box className="details-content">
            {!props?.contentDetails?.isInstructional && (
                <Box className="flex">
                    <Box className="chips">
                        {Object.keys(TAB_LIST).map((chip) => (
                            <Chip
                                key={TAB_LIST[chip]?.ID}
                                onClick={() => setSelectedChip(TAB_LIST[chip]?.ID)}
                                className={
                                    'goal-chip ' +
                                    (TAB_LIST[chip]?.ID === selectedChip
                                        ? 'selected-goal'
                                        : 'non-selected-goal')
                                }
                                size="medium"
                                color="primary"
                                id={TAB_LIST[chip]?.htmlId}
                                variant={
                                    TAB_LIST[chip]?.ID === selectedChip ? 'outlined' : 'filled'
                                }
                                label={TAB_LIST[chip]?.VALUE}
                            />
                        ))}
                    </Box>
                    {props?.contentDetails?.id &&
                        windowDimensions.width >= BREAKPOINTS_VALUE.TABLET && (
                            <Box className="btn-container">
                                <ButtonState
                                    contentDetails={props?.contentDetails}
                                    currentPhase={props?.currentPhase}
                                    PHASES={PHASES}
                                    redirectToPlayer={props?.redirectToPlayer}
                                    setCurrentPhase={props?.setCurrentPhase}
                                    openPurchaseModal={props?.openPurchaseModal}
                                />
                            </Box>
                        )}
                </Box>
            )}

            {selectedChip === TAB_LIST?.ABOUT?.ID ? (
                <AboutTab windowDimensions={windowDimensions} {...props} />
            ) : (
                <Fade in={true}>
                    <Box>
                        <Box height={180}>
                            <ProgramAbout
                                isOnlyProgressContent={true}
                                experiences={props?.contentDetails?.reviewsList}
                                listHeight={160}
                            />
                        </Box>
                        {props?.contentDetails?.id &&
                            windowDimensions.width < BREAKPOINTS_VALUE.TABLET && (
                                <Box className="button-container">
                                    <ButtonState {...props} />
                                </Box>
                            )}
                    </Box>
                </Fade>
            )}
        </Box>
    );
};

export default ContentPhasesDetails;
