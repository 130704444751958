import React, { useEffect, useState } from 'react';
import MeditationMediaPlayer from '../MeditationContent/Media/MeditationMediaPlayer';
import { Box, Button, Typography } from '@mui/material';
import { BeginExperienceWrapper } from './BeginExperience.style';
import { useNavigate } from 'react-router-dom';
import {
    API_URL,
    URL_BEGIN_EXPERIENCE,
    URL_EXPERIENCE_MORE_GUIDED_AUDIO,
    URL_EXPERIENCE_MORE_MUSIC,
    URL_EXPERIENCE_MORE_VIDEO,
    URL_LOGIN,
    URL_SALES_PAGE_ONE,
    URL_SIGN_UP
} from 'Helpers/Paths';
import DOMPurify from 'dompurify';
import { useDispatch } from 'react-redux';
import { hideTopBarInteractionButton } from 'Redux/App/Actions';
import TaoCalligraphyWatermark from 'Components/common/TaoCalligraphyWatermark';
import { hideSideBar, showSideBar } from 'Redux/Sidebar/Actions';
import { useSelector } from 'react-redux';
import { getWindowDimensions } from 'Helpers';

const API_URL_FROM_PATH = {
    [URL_BEGIN_EXPERIENCE]: API_URL.INITIAL_EXPERIENCE,
    [URL_EXPERIENCE_MORE_VIDEO]: API_URL.EXPERIENCE_MORE,
    [URL_EXPERIENCE_MORE_GUIDED_AUDIO]: API_URL.EXPERIENCE_MORE,
    [URL_EXPERIENCE_MORE_MUSIC]: API_URL.EXPERIENCE_MORE
};

const BUTTON_ACTIONs = {
    [URL_BEGIN_EXPERIENCE]: {
        BUTTON_NAME: 'EXPERIENCE MORE',
        NAVIGATE: URL_EXPERIENCE_MORE_VIDEO
    },
    [URL_EXPERIENCE_MORE_VIDEO]: {
        BUTTON_NAME: 'Try a guided audio meditation',
        NAVIGATE: URL_EXPERIENCE_MORE_GUIDED_AUDIO,
        PARAMS: { type: 'Video' }
    },
    [URL_EXPERIENCE_MORE_GUIDED_AUDIO]: {
        BUTTON_NAME: 'Try a music meditation',
        NAVIGATE: URL_EXPERIENCE_MORE_MUSIC,
        PARAMS: { type: 'Guided_Meditation_Audio' }
    },
    [URL_EXPERIENCE_MORE_MUSIC]: {
        BUTTON_NAME: 'continue',
        NAVIGATE: URL_SALES_PAGE_ONE,
        PARAMS: { type: 'Music' }
    }
};

const BeginExperience = () => {
    const navigate = useNavigate();
    const [textData, setTextData] = useState({});
    const [displayScrollShadow, setScrollShadow] = useState(false);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const isLoggedIn = useSelector((state) => state.Auth.isLoggedIn);
    const [playingOnFullScreen, setContentPlayingOnFullScreen] = useState(false);
    const dispatch = useDispatch();

    const handleNavigate = (url, state = {}) => {
        if (url) {
            navigate(url, {
                state
            });
        }
    };

    useEffect(() => {
        dispatch(hideTopBarInteractionButton(false));
    }, [dispatch]);

    useEffect(() => {
        if (isLoggedIn) dispatch(hideSideBar());
        return () => {
            if (isLoggedIn) dispatch(showSideBar());
        };
    }, [dispatch, isLoggedIn]);

    useEffect(() => {
        let container = document?.getElementById?.('container');
        let containerHeight = container?.offsetHeight;
        let descriptionHeight = windowDimensions.height;

        if (descriptionHeight < containerHeight) setScrollShadow(true);
        else setScrollShadow(false);

        const updateShadowStatus = () => {
            if (container.offsetHeight - window.scrollY - 10 > windowDimensions.height)
                setScrollShadow(true);
            else setScrollShadow(false);
        };
        window?.addEventListener?.('scroll', updateShadowStatus);

        return () => {
            window.removeEventListener('scroll', updateShadowStatus);
        };
    }, [textData?.description, windowDimensions.width, windowDimensions.height]);

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions());
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <BeginExperienceWrapper>
            <TaoCalligraphyWatermark />
            <Box
                className={`full-width-section ${playingOnFullScreen && 'remove-padding'}`}
                id="container">
                <MeditationMediaPlayer
                    isInitialExperience={true}
                    setTextData={setTextData}
                    apiUrl={API_URL_FROM_PATH[window.location.pathname]}
                    contentParamsType={BUTTON_ACTIONs?.[window.location.pathname]?.PARAMS?.type}
                    setContentPlayingOnFullScreen={setContentPlayingOnFullScreen}
                />
                {textData?.description && !playingOnFullScreen && (
                    <React.Fragment>
                        <Box className="container">
                            <Typography
                                className="description"
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(textData?.description, {
                                        // FORBID_TAGS: ['p']
                                    })
                                }}
                            />
                        </Box>
                    </React.Fragment>
                )}
            </Box>
            {!playingOnFullScreen && (
                <Box className={`footer-container ${displayScrollShadow && 'shadow'}`}>
                    {displayScrollShadow && <Box className="shadow" />}
                    <Box className="redirection-links">
                        <Button
                            className="redirection-btn"
                            onClick={() => {
                                navigate(BUTTON_ACTIONs[window.location.pathname]?.NAVIGATE);
                            }}>
                            {BUTTON_ACTIONs[window.location.pathname]?.BUTTON_NAME}
                        </Button>
                        <Box className="flex f-h-center">
                            <Typography
                                className="footer-text"
                                onClick={() => handleNavigate(URL_SIGN_UP)}>
                                Create Account
                            </Typography>
                            <Typography className="footer-text divider">|</Typography>
                            <Typography
                                className="footer-text"
                                onClick={() => handleNavigate(URL_LOGIN)}>
                                Login
                            </Typography>
                        </Box>
                    </Box>
                    <Box className="flower-image" />
                </Box>
            )}
        </BeginExperienceWrapper>
    );
};
export default BeginExperience;
