import React, { useEffect, useLayoutEffect } from 'react';
import { Routes as RoutesSwitch, Route, useNavigate, useLocation } from 'react-router-dom';

// ROUTING COMPONENTS
import ProtectedRoute from './ProtectedRoute';
import RoutesList from './RoutesList';
import {
    URL_SIGN_UP,
    URL_LOGIN,
    URL_RESET_PASSWORD,
    URL_RESET_PASSWORD_EMAIL_SENT,
    URL_RESET_NEW_PASSWORD,
    URL_VERIFY_EMAIL,
    URL_PRIVACY_POLICY,
    URL_TERMS_CONDITION,
    URL_COMMUNITY_GUIDELINES,
    URL_HOME_PAGE,
    URL_QUESTIONNAIRE_MAIN,
    URL_WELCOME_PAGE,
    URL_SALES_PAGE_ONE,
    URL_SALES_PAGE_TWO,
    URL_BEGIN_EXPERIENCE,
    URL_EXPERIENCE_MORE_VIDEO,
    URL_EXPERIENCE_MORE_MUSIC,
    URL_EXPERIENCE_MORE_GUIDED_AUDIO,
    URL_SHARE_PAGE
} from 'Helpers/Paths';
import { FreeTrialSignUp } from 'Components/Pages/Signup';
import { Login } from 'Components/Pages/Login';
import { ResetPassword } from 'Components/Pages/ResetPassword/ResetPasswordEmail';
import { ResetPasswordEmailSent } from 'Components/Pages/ResetPassword/ResetPasswordEmailSent';
import { ResetNewPassword } from 'Components/Pages/ResetPassword/ResetNewPassword';
import { VerifyEmail } from 'Components/Pages/VerifyEmail';
import { PrivacyPolicy, TermsCondition, CommunityGuidelines } from 'Components/Pages/Policies';
import { useSelector } from 'react-redux';
import { WelcomePage } from 'Components/Pages/WelcomePage';
import { SalesPageOne } from 'Components/Pages/Sales/One';
import { SalesPageTwo } from 'Components/Pages/Sales/Two';
import { BeginExperience } from 'Components/Pages/BeginExperience';
import { SharedLink } from 'Components/Pages/SharedLink';

const BEFORE_LOGIN_ACCESSIBLE_PATHS = [
    '/',
    URL_LOGIN,
    URL_SIGN_UP,
    URL_RESET_NEW_PASSWORD,
    URL_VERIFY_EMAIL,
    URL_RESET_PASSWORD,
    URL_RESET_PASSWORD_EMAIL_SENT,
    URL_WELCOME_PAGE
];

function Routes() {
    const navigate = useNavigate();
    const isLoggedIn = useSelector((state) => state.Auth.isLoggedIn);
    const userInfo = useSelector((state) => state.Auth.userInfo);
    const location = useLocation();

    useEffect(() => {
        if (isLoggedIn && BEFORE_LOGIN_ACCESSIBLE_PATHS?.includes(window?.location?.pathname)) {
            if (userInfo?.isQuestionnaireCompleted) {
                navigate(URL_HOME_PAGE);
            } else {
                navigate(URL_QUESTIONNAIRE_MAIN);
            }
        }
    }, [isLoggedIn, navigate, userInfo]);

    useLayoutEffect(() => {
        document.documentElement.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    }, [location.pathname]);

    return (
        <RoutesSwitch>
            <Route path={'/'} element={<WelcomePage />} />
            <Route path={URL_WELCOME_PAGE} element={<WelcomePage />} />
            <Route path={URL_SHARE_PAGE} element={<SharedLink />} />
            <Route path={URL_LOGIN} element={<Login />} />
            <Route path={URL_SIGN_UP} element={<FreeTrialSignUp />} />
            <Route path={URL_BEGIN_EXPERIENCE} element={<BeginExperience />} />
            <Route path={URL_EXPERIENCE_MORE_VIDEO} element={<BeginExperience />} />
            <Route path={URL_EXPERIENCE_MORE_GUIDED_AUDIO} element={<BeginExperience />} />
            <Route path={URL_EXPERIENCE_MORE_MUSIC} element={<BeginExperience />} />
            <Route path={URL_SALES_PAGE_ONE} element={<SalesPageOne />} />
            <Route path={URL_SALES_PAGE_TWO} element={<SalesPageTwo />} />
            <Route path={URL_RESET_PASSWORD} element={<ResetPassword />} />
            <Route path={URL_RESET_PASSWORD_EMAIL_SENT} element={<ResetPasswordEmailSent />} />
            <Route path={URL_VERIFY_EMAIL} element={<VerifyEmail />} />
            <Route path={URL_RESET_NEW_PASSWORD} element={<ResetNewPassword />} />
            <Route path={URL_PRIVACY_POLICY} element={<PrivacyPolicy />} />
            <Route path={URL_TERMS_CONDITION} element={<TermsCondition />} />
            <Route path={URL_COMMUNITY_GUIDELINES} element={<CommunityGuidelines />} />

            {RoutesList.map((route, index) => (
                <React.Fragment key={index}>
                    {route.hasChildren ? (
                        <Route
                            path={route.path}
                            element={<ProtectedRoute>{route.Component}</ProtectedRoute>}>
                            {route.children}
                        </Route>
                    ) : (
                        <Route
                            path={route.path}
                            element={<ProtectedRoute>{route.Component}</ProtectedRoute>}
                        />
                    )}
                </React.Fragment>
            ))}
        </RoutesSwitch>
    );
}

export default Routes;
