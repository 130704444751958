import { createGlobalStyle } from 'styled-components';
import { COLORS, FONTS, responsive } from './Constants';

//Jost
import JostRegular from 'Assets/Fonts/Jost/Jost-Regular.ttf';
import JostBold from 'Assets/Fonts/Jost/Jost-Bold.ttf';
import JostBoldItalic from 'Assets/Fonts/Jost/Jost-BoldItalic.ttf';
import JostLight from 'Assets/Fonts/Jost/Jost-Light.ttf';
import JostMedium from 'Assets/Fonts/Jost/Jost-Medium.ttf';
import JostSemiBold from 'Assets/Fonts/Jost/Jost-SemiBold.ttf';

// Cormorant
import CormorantBold from 'Assets/Fonts/Cormorant_Garamond/CormorantGaramond-Bold.ttf';
import CormorantRegular from 'Assets/Fonts/Cormorant_Garamond/CormorantGaramond-Regular.ttf';
import CormorantSemiBold from 'Assets/Fonts/Cormorant_Garamond/CormorantGaramond-SemiBold.ttf';

// Lato
import LatoRegular from 'Assets/Fonts/Lato/Lato-Regular.ttf';
import LatoBold from 'Assets/Fonts/Lato/Lato-Bold.ttf';

// Poppins
import PoppinsRegular from 'Assets/Fonts/Poppins/Poppins-Regular.ttf';

//DM Serif Text
import DmSerifRegular from 'Assets/Fonts/DM_SERIF_TEXT/font_dmseriftext_regular.ttf';

import { isIEBrowser } from 'Helpers/Utils';
const isIE = isIEBrowser();

export const UtilityStyles = createGlobalStyle`
    * {
        box-sizing  : border-box;
        outline     : none;
    }

    @media print {
        html, body {
           display: none;  
        }
    }

    html,
    body {
        margin: 0;
        padding: 0;
        background-color: white;
        /* overflow: hidden; */
        height: 100%;
        -webkit-tap-highlight-color: transparent;
    }

    body {
        font-family         : ${FONTS.PRIMARY};
        line-height         : 1.2;
        position            : relative;
        text-size-adjust    : none;
        -webkit-text-size-adjust: none;
        /* min-height          : 100vh; */
    }

    #root {
        display: flex;
        flex-direction: column;
        height: 100%;
        
    }
    .full-height {
        height: 100%;
    }

    .full-width {
        width: 100%;
    }

    .flex {
        display: flex;
    }
    .f-row {
        flex-direction: row;
    }
    .f-column {
        flex-direction: column;
    }

    .f-v-center { 
        align-items: center;
    }

    .f-h-center {
        justify-content: center;
    }

    .f-h-end {
        justify-content: flex-end;
    }

    .f-h-space-between {
        justify-content: space-between;
    }

    .f-wrap {
        flex-wrap: wrap;
    }
    
    .text-center {
        text-align: center;
    }

    .width-max-content {
        width: max-content;
    }


    // BOX DESIGN LIKE BOOTSTRAP
    .visible-md {
        display: none;
        ${responsive.TABLET`display:block`}
    }
    .hidden-md {
        ${responsive.TABLET`display:none`}
    }

    .hover {
        cursor: pointer;
    }

    .elipsis {
        overflow        : hidden;
        text-overflow   : ellipsis;
        white-space     : nowrap;
    }
    
    .no-data-text {
        color       : #707070;
        font-size   : 18px !important;
        text-align  : center;
        width       : 100%;
    }

    /* ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    ::-webkit-scrollbar-track {
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background-color    : rgba(162, 162, 162, 0.5); 
        border-radius       : 4px;
    } */

    .text-uppercase {
        text-transform: uppercase;
    }

   
    /* ------------------------------------------------------------------------------------------------------------------------ */
    // Jost
    @font-face {
        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: normal;
        /* src: ; */
        src: url(${JostRegular}) format('truetype');
        ${isIE && `src: url(${JostRegular}) format('truetype');`}
        /* src: url(${JostRegular}) format('truetype'); */
    }

    @font-face {
        font-family: ${FONTS.PRIMARY_BOLD};
        font-style: normal;
        font-weight: normal;
        src: url(${JostBold}) format('truetype');
    }

    @font-face {
        font-family: ${FONTS.PRIMARY_SEMI_BOLD};
        font-style: normal;
        font-weight: normal;
        src: url(${JostSemiBold}) format('truetype');
    }

    @font-face {
        font-family: ${FONTS.PRIMARY_LIGHT};
        font-style: normal;
        font-weight: normal;
        src: url(${JostLight}) format('truetype');
    }

    @font-face {
        font-family: ${FONTS.PRIMARY_MEDIUM};
        font-style: normal;
        font-weight: normal;
        src: url(${JostMedium}) format('truetype');
        ${isIE && `src: url(${JostMedium}) format('truetype');`}
    }
    @font-face {
        font-family: ${FONTS.PRIMARY_ITALIC};
        font-style: normal;
        font-weight: normal;
        src: local(${FONTS.PRIMARY_ITALIC}), url(${JostBoldItalic}) format('truetype');
        ${isIE && `src: url(${JostRegular}) format('truetype');`}
    }
    /* ------------------------------------------------------------------------------------------------------------------------ */


    /* ------------------------------------------------------------------------------------------------------------------------ */
    // Cormorant
    /* @font-face {
        font-family: ${FONTS.SECONDARY_MEDIUM};
        font-style: normal;
        font-weight: normal;
        src: local(${FONTS.SECONDARY_MEDIUM}) format('truetype');
    }
    @font-face {
        font-family: ${FONTS.SECONDARY_HEAVY};
        font-style: normal;
        font-weight: normal;
        src: local(${FONTS.SECONDARY_HEAVY}) format('truetype');
    } */

    @font-face {
        font-family: ${FONTS.SECONDARY};
        font-style: normal;
        font-weight: normal;
        src: url(${CormorantRegular}) format('truetype');
    }
    
    /* @font-face {
        font-family: ${'Cormorant Bold'};
        src: url(${CormorantBold}) format('truetype');
        ${isIE && `src: url(${CormorantBold}) format('truetype');`}
        font-style: normal;
        font-weight: normal;
    } */

    @font-face {
        font-family: ${FONTS.SECONDARY_BOLD};
        font-style: normal;
        font-weight: normal;
        src: url(${CormorantBold}) format('truetype');
    }

    @font-face {
        font-family: ${FONTS.SECONDARY_SEMI_BOLD};
        font-style: normal;
        font-weight: normal;
        src: url(${CormorantSemiBold}) format('truetype');
    }

    /* ------------------------------------------------------------------------------------------------------------------------ */


    /* ------------------------------------------------------------------------------------------------------------------------ */
    // Lato
    @font-face {
        font-family: ${FONTS.TERTIARY};
        font-style: normal;
        font-weight: normal;
        src: url(${LatoRegular}) format('truetype');
    }
    
    @font-face {
        font-family: ${FONTS.TERTIARY_BOLD};
        font-style: normal;
        font-weight: normal;
        src: url(${LatoBold}) format('truetype');
    }

    /* ------------------------------------------------------------------------------------------------------------------------ */

    /* ------------------------------------------------------------------------------------------------------------------------ */
    // Poppins
    @font-face {
        font-family: ${FONTS.QUATERNARY};
        font-style: normal;
        font-weight: normal;
        src: url(${PoppinsRegular}) format('truetype');
    }

    /* ------------------------------------------------------------------------------------------------------------------------ */

    /* ------------------------------------------------------------------------------------------------------------------------ */
    // DM SERIF TEXT
    @font-face {
        font-family: ${FONTS.QUINARY};
        font-style: normal;
        font-weight: normal;
        src: url(${DmSerifRegular}) format('truetype');
    }

`;
