import { Box } from '@mui/material';
import { COLORS, FONTS } from 'Styles/Constants';
import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1
};

export const SlickDotSlider = ({ children, ...props }) => {
    return (
        <SliderWrapper className="slider-main">
            <Slider {...settings}>{children}</Slider>
        </SliderWrapper>
    );
};

const SliderWrapper = styled(Box)`
    &.slider-main {
        width: 100%;

        .slide-title {
            font-size: 20px;
            font-weight: 400;
            line-height: 27px;
            text-align: center;
            color: ${COLORS.PRIMARY_DARK};
            font-family: ${FONTS.SECONDARY};
        }

        .slick-slider {
            .slick-list {
                font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                font-size: 18px;
                line-height: 27px;
                text-align: center;
                color: ${COLORS.TEXT_DARK};
            }

            .slick-dots {
                bottom: -40px;
                li {
                    margin: 0 3px;
                }

                button {
                    display: block;
                    width: 0.75rem;
                    height: 0.75rem;
                    padding: 0;
                    border: none;
                    border-radius: 100%;
                    background-color: #e8e8e8;

                    ::before {
                        content: '';
                    }
                }
            }

            .slick-dots li.slick-active button {
                background-color: #b08c3d;
            }
        }
    }
`;
