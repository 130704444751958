import { Box } from '@mui/material';
import styled from 'styled-components';
import { COLORS, FONTS, responsive } from 'Styles/Constants';

export const ReferralWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: ${COLORS.WHITE};
    position: relative;
    height: 100%;
    border-radius: 30px 30px 0 0;
    padding: 20px 0;

    ${responsive.TABLET`
        height: calc(100% - 80px);
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
        border-radius: 30px 30px 0px 0px;
        background: #FFFFFF;
        padding: 0;
    `}

    ${responsive.PHABLET`
        background: none;
        box-shadow: none;
        border-radius: none;
    `}

    .referral-link-container {
        height: 230px;
        margin: 100px 0 0 0;
        position: relative;

        ${responsive.TABLET`
            margin: 0px;
        `}

        .color-container {
            background: ${COLORS.PRIMARY_EXTRA_LIGHT};
            opacity: 0.4;
            position: absolute;
            height: 100%;
            width: 100%;
            z-index: 10;
        }

        .referral-link-header {
            font-family: ${FONTS.PRIMARY};
            font-size: 18px;
            text-transform: capitalize;
            color: ${COLORS.MEDIUM_GREY};
            padding: 20px 0 0 20px;
            z-index: 20;
            position: relative;
        }

        .share-content-wrapper {
            padding: 0px 20px;
            z-index: 20;
            position: relative;

            .share-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 80px;

                .share-link-input {
                    width: calc(100% - 100px);
                    height: 40px;
                    background: rgba(255, 255, 255, 0.66);
                    border: 2px solid ${COLORS.PRIMARY_DARK};
                    border-radius: 6px;
                }

                .input {
                    padding: 10px 8px;
                    font-size: 18px;
                    color: ${COLORS.TEXT_DARK};
                }

                .extra-feature-icon {
                    width: max-content;

                    .share-btn-container {
                        height: 40px;
                        background: linear-gradient(180deg, #cbac69 0%, #b6954f 100%);
                        border-radius: 12px;
                        box-shadow: none;

                        .share-btn-text {
                            font-size: 16px;
                            font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                            color: ${COLORS.WHITE};
                            padding: 0 10px;
                        }
                    }
                }
            }

            .share-information-wrapper {
                height: 90px;

                .share-information-text {
                    height: 90px;
                    font-family: ${FONTS.PRIMARY};
                    font-size: 18px;
                    line-height: 27px;
                    color: ${COLORS.PRIMARY_WARM};
                }
            }
        }
    }
`;

export const YourReferralWrapper = styled(Box)`
    .your-referral-wrapper {
        padding: 20px 20px 0 20px;
        min-height: 200px;

        .your-referral-header {
            font-family: ${FONTS.PRIMARY};
            font-size: 18px;
            line-height: 26px;
            text-transform: capitalize;
            color: ${COLORS.MEDIUM_GREY};
        }

        .your-referral-list-wrapper {
            height: 100%;
            width: 100%;
            margin: 0;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;

            -ms-overflow-style: none;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .placeholder-title {
            margin: 20px 0px;
            font-family: ${FONTS.PRIMARY};
            font-size: 18px;
            line-height: 27px;
            color: ${COLORS.PRIMARY_WARM};
        }
    }
`;

export const YourReferralCardWrapper = styled(Box)`
    width: 80px;
    height: 115px;
    margin: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;

    .user-wrapper {
        height: 80px;
        width: 80px;

        .user-image {
            height: 80px;
            width: 80px;
        }
    }

    .user-information-wrapper {
        display: flex;
        flex-direction: column;
        margin: 5px 0 0 0;
        width: 75px;

        .user-name {
            font-family: ${FONTS.PRIMARY};
            font-size: 12px;
            color: ${COLORS.BLACK};
            line-height: 17px;
            text-align: center;
        }

        .user-join-date {
            font-family: ${FONTS.PRIMARY};
            font-size: 10px;
            line-height: 14px;
            text-align: center;
            color: ${COLORS.TEXT_DARK};
        }
    }
`;

export const ListWrapper = styled(Box)`
    display: flex;
    margin: 20px 0 0;
    height: calc(100vh - 390px);
    overflow: auto;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    ${responsive.TABLET`
        height: calc(100vh - 200px);
    `}
`;
