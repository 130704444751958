import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SalesPageTwoWrapper } from './SalesPageTwo.style';
import TaoCalligraphyWatermark from 'Components/common/TaoCalligraphyWatermark';
import { useDispatch } from 'react-redux';
import { hideTopBarInteractionButton } from 'Redux/App/Actions';
import { hideSideBar, showSideBar } from 'Redux/Sidebar/Actions';
import { Box, CardActions, CardContent, CardMedia, IconButton, Typography } from '@mui/material';
import Api from 'Helpers/ApiHandler';
import { API_URL, URL_LOGIN, URL_SHARE_PAGE, URL_SIGN_UP } from 'Helpers/Paths';
import TaoSlider from 'Components/common/TaoSlider';
import VideoIcon from 'Assets/images/Common/video-icon.svg';
import ProgramIcon from 'Assets/images/Common/program-icon.svg';
import AudioIcon from 'Assets/images/Common/audio-icon.svg';
import MusicIcon from 'Assets/images/Common/music-icon.svg';
import {
    BoxLoader,
    TitleLoader,
    WellnessCategoryLoader
} from 'Components/common/ShimmerEffectLoader/ShimmerEffectLoader';
import { MEDIA_TYPE, PAGE_SHARING, TAO_CARD_TYPE } from 'Helpers/Constants';
import TaoCard from 'Components/common/TaoCard';
import { SlickDotSlider } from 'Components/common/TaoSlider/SlickDotSlider';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TaoButton } from 'Components/common/Button.style';
import { dateFormatter } from 'Helpers';
import ShareSocial from 'Components/common/Share/Share';
import DefaultThumbnail from 'Assets/Logo/whiteLogo.png';
import { useSelector } from 'react-redux';

const WellnessCategory = ({ loading, data, selectedCategoryId, setCategoryId, ...props }) => {
    const wellnessRef = useRef();

    return loading ? (
        <TaoSlider
            gap={0}
            ref={wellnessRef}
            cardHeight={80}
            showArrows={false}
            slidesToShow={6}
            showShadow={false}>
            {[1, 2, 3, 4, 5, 6]?.map((value, index) => (
                <Box key={index} className="wellness-list-loader-wrapper">
                    <Box className="loader-circle flex f-v-center f-h-center">
                        <WellnessCategoryLoader
                            width={60}
                            height={60}
                            fillWidth={60}
                            fillHeight={60}
                            customStyle={{ display: 'flex', alignItems: 'center' }}
                        />
                    </Box>
                    <TitleLoader
                        width={40}
                        height={10}
                        fillWidth={40}
                        fillHeight={10}
                        marginLeft={0}
                    />
                </Box>
            ))}
        </TaoSlider>
    ) : (
        <TaoSlider
            gap={5}
            ref={wellnessRef}
            cardHeight={80}
            slidesToShow={6}
            showArrows={false}
            showShadow={false}>
            {data?.map((value, index) => (
                <Box key={index} className="wellness-content">
                    <Box className="wellness-icon-button flex f-v-center f-h-center">
                        <Box
                            className="wellness-icon"
                            onClick={() => setCategoryId(value.categoryId)}
                            style={{
                                backgroundImage: `url(${
                                    selectedCategoryId === value.categoryId
                                        ? value?.selectedIcon
                                        : value?.icon
                                })`
                            }}
                        />
                    </Box>
                    <Typography color="primary" className="wellness-category-name">
                        {value?.categoryName}
                    </Typography>
                </Box>
            ))}
        </TaoSlider>
    );
};

const Content = ({ loading, data, ...props }) => {
    const contentRef = useRef(null);

    return loading ? (
        <Box className="meditation-contents">
            <Box color="primary" className="content-type-container">
                <Box
                    className="content-icons"
                    style={{ opacity: props?.type === TAO_CARD_TYPE.PROGRAM ? '40%' : 1 }}>
                    <img src={props?.typeIcon} alt="" />
                </Box>
                <Typography className="content-type-text">{props?.title}</Typography>
            </Box>

            <Box className="card-list-container" sx={{ height: 170 }}>
                <TaoSlider gap={10} ref={contentRef} slidesToShow={1} cardHeight={90}>
                    {[1, 2, 4, 5, 6]?.map((value, index) => {
                        return (
                            <Box key={index} className="meditation-contents-list-loader-wrapper">
                                <BoxLoader
                                    customStyle={{
                                        borderRadius: '24px 8px'
                                    }}
                                    width={210}
                                    height={120}
                                />
                                <TitleLoader
                                    width={210}
                                    height={24}
                                    fillWidth={140}
                                    fillHeight={10}
                                    marginLeft={40}
                                />
                            </Box>
                        );
                    })}
                </TaoSlider>
            </Box>
        </Box>
    ) : (
        <Box className="meditation-contents">
            <Box className="flex f-v-center">
                <Box color="primary" className="content-type-container">
                    <Box
                        className="content-icons"
                        style={{ opacity: props?.type === TAO_CARD_TYPE.PROGRAM ? '40%' : 1 }}>
                        <img src={props?.typeIcon} alt="" />
                    </Box>
                    <Typography className="content-type-text">{props?.title}</Typography>
                </Box>
            </Box>

            <Box className="card-list-container" sx={{ height: 170 }}>
                <TaoSlider gap={10} ref={contentRef} slidesToShow={2} cardHeight={90}>
                    {data?.length > 0 &&
                        data?.map((value, index) => {
                            return (
                                <TaoCard
                                    key={index}
                                    backgroundImage={value?.thumbnailImage || value?.contentImage}
                                    type={props?.type}
                                    title={value?.contentName || value?.title}
                                    favoriteIconVisible={false}
                                    isFav={value?.isFavorite}
                                    badge={value?.subscription?.badge}
                                    isAccessible
                                    isPaidContent
                                    isPurchased={false}
                                    isFeatured={value?.isFeatured}
                                    textFromStart
                                    forceDisplayPlayButton
                                />
                            );
                        })}
                    {!data?.length && (
                        <Box className="placeholder-box-wrapper">
                            <Typography className="placeholder-box-text">
                                {`No ${props?.title} Found`}
                            </Typography>
                        </Box>
                    )}
                </TaoSlider>
            </Box>
        </Box>
    );
};

const DailyWisdom = ({ loading, data, ...props }) => {
    const [currentSlide, setCurrentSlide] = useState(1);
    const wisdomRef = useRef();
    const [params] = useSearchParams();

    const handleSwipe = (index) => {
        setCurrentSlide(index);
    };

    useEffect(() => {
        const quoteId = params?.get('quoteId');
        if (quoteId && data?.length) {
            let findIndex = data.findIndex((item) => item.id === parseInt(quoteId));
            wisdomRef.current.slickGoTo(findIndex);
            const wisdomId = document.getElementById('wisdom');

            if (wisdomId) {
                wisdomId?.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    }, [params, data]);

    return (
        <Box className="wisdom-slider" id="wisdom">
            <TaoSlider
                ref={wisdomRef}
                slidesToShow={1}
                slidesToScroll={1}
                showArrows={false}
                isLeftSideShadeVisible={true}
                slidesToShowLaptop={1}
                slidesToShowDesktop={1}
                initialSlide={1}
                centerMode={true}
                shadeWidths={{ laptop: 80 }}
                currentSlide={currentSlide}
                slickTrackPadding="0px"
                loading={loading}
                onSwipe={handleSwipe}>
                {loading
                    ? [1, 2, 3, 4, 5, 6]?.map((value, index) => (
                          <Box className="wisdom-section" key={value}>
                              <BoxLoader width={300} height={490} />
                          </Box>
                      ))
                    : data?.map((value, index) => (
                          <Box className="wisdom-section" key={index}>
                              {value?.thumbnailImage ? (
                                  <CardMedia
                                      component="img"
                                      alt="quote"
                                      image={value?.thumbnailImage}
                                      className="card-media"
                                  />
                              ) : (
                                  <Box className="card-media flex f-v-center f-h-center">
                                      <Box
                                          className="default-image"
                                          style={{ backgroundImage: `url(${DefaultThumbnail})` }}
                                      />
                                  </Box>
                              )}
                              <CardContent className="card-content">
                                  <Typography className="quote-date">
                                      {dateFormatter(value?.date)}
                                  </Typography>
                                  <Typography variant="body2" className="wisdom-quote">
                                      {value?.text}
                                  </Typography>
                                  <Typography className="quote-by">{value?.personName}</Typography>
                              </CardContent>
                              <CardActions className="card-action">
                                  <ShareSocial
                                      title={`By ${value.personName}`}
                                      details={{ description: value.text }}
                                      ButtonComponent={
                                          <Typography
                                              variant="body2"
                                              className="card-action-button">
                                              <span className="button-text">Share</span>
                                          </Typography>
                                      }
                                      dynamicDimension
                                      disableRipple
                                      url={`${window?.location?.protocol}//${window.location.host}${URL_SHARE_PAGE}?page=${PAGE_SHARING.SALES_PAGE_TWO}&quoteId=${value.id}`}
                                  />
                              </CardActions>
                          </Box>
                      ))}
            </TaoSlider>
        </Box>
    );
};

const SalesPageTwo = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isLoggedIn = useSelector((state) => state.Auth.isLoggedIn);
    const [selectedCategoryId, setCategoryId] = useState(null);
    const API = useMemo(() => new Api(), []);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();

    const handleNavigate = (url, state = {}) => {
        if (url) {
            navigate(url, {
                state
            });
        }
    };

    const fetchSlider = useCallback(async () => {
        setLoading(true);
        const response = await API.get(API_URL.SALES_PAGE_TWO_DATA, {
            params: { categoryId: selectedCategoryId }
        });
        if (response?.data) {
            setData(response?.data?.data);
        }
        setLoading(false);
    }, [API, selectedCategoryId]);

    useEffect(() => {
        fetchSlider();
        dispatch(hideTopBarInteractionButton(false));
    }, [dispatch, fetchSlider]);

    useEffect(() => {
        if (isLoggedIn) dispatch(hideSideBar());
        return () => {
            if (isLoggedIn) dispatch(showSideBar());
        };
    }, [dispatch, isLoggedIn]);

    return (
        <SalesPageTwoWrapper>
            <TaoCalligraphyWatermark />
            <Box className="container">
                <Typography className="title">
                    Be empowered by a Unique Wellness Experience
                </Typography>
                <Typography className="sub-title">
                    Tap a category to see your selections below.
                </Typography>
                <WellnessCategory
                    data={data?.categoryList}
                    loading={!selectedCategoryId && loading}
                    setCategoryId={setCategoryId}
                    selectedCategoryId={selectedCategoryId}
                />

                <Content
                    loading={loading}
                    data={data?.programList}
                    type={TAO_CARD_TYPE.PROGRAM}
                    typeIcon={ProgramIcon}
                    title="Programs"
                />
                <Content
                    loading={loading}
                    data={data?.contentDetails?.videoDetail}
                    type={MEDIA_TYPE.VIDEO}
                    typeIcon={VideoIcon}
                    title="Videos"
                />
                <Content
                    loading={loading}
                    data={data?.contentDetails?.guidedMeditationAudioDetail}
                    type={MEDIA_TYPE.GUIDED_AUDIO}
                    typeIcon={AudioIcon}
                    title="Guided Audio"
                />
                <Content
                    loading={loading}
                    data={data?.contentDetails?.musicDetail}
                    type={MEDIA_TYPE.MUSIC}
                    typeIcon={MusicIcon}
                    title="Music"
                />

                <DailyWisdom data={data?.dailyWisdomList} loading={loading} />

                <Box className="testimonials">
                    <SlickDotSlider>
                        {data?.testimonials?.map((item, index) => (
                            <React.Fragment key={index}>
                                {item?.title ? (
                                    <Typography className="slide-text primary">
                                        {item?.title}
                                    </Typography>
                                ) : (
                                    <Box className="quote-image" />
                                )}
                                <Typography className="slide-text" key={index}>
                                    {item?.description}
                                </Typography>
                                <Box className="flex f-v-center f-h-center">
                                    <Box className="author-by-dash" />
                                    <Typography className="slide-text">{item?.author}</Typography>
                                </Box>
                            </React.Fragment>
                        ))}
                    </SlickDotSlider>
                </Box>
            </Box>
            <Box className="footer-container">
                <Box className="redirection-links">
                    <TaoButton
                        className="redirection-btn"
                        onClick={() => {
                            handleNavigate(URL_SIGN_UP);
                        }}>
                        Continue
                    </TaoButton>
                    <Box className="flex f-h-center">
                        <Typography
                            className="footer-text"
                            onClick={() => handleNavigate(URL_SIGN_UP)}>
                            Create Account
                        </Typography>
                        <Typography className="footer-text divider">|</Typography>
                        <Typography
                            className="footer-text"
                            onClick={() => handleNavigate(URL_LOGIN)}>
                            Login
                        </Typography>
                    </Box>
                </Box>
                <Box className="flower-image" />
            </Box>
        </SalesPageTwoWrapper>
    );
};

export default SalesPageTwo;
