import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Box, Typography, Button, Chip, IconButton, InputAdornment } from '@mui/material';
// import { StarOutlined, StarBorderOutlined } from '@mui/icons-material'; PHASE 2

// import Slider from 'react-slick'; PHASE 2

import { BREAKPOINTS_VALUE } from 'Styles/Constants';

// import ProfileImage1 from 'Assets/images/Common/profile-image-1.png'; PHASE 2
// import ProfileImage2 from 'Assets/images/Common/profile-image-2.png'; PHASE 2
// import ProfileImage4 from 'Assets/images/Common/profile-image-4.png'; PHASE 2
// import MeditationRoomUserImage from 'Assets/images/Common/meditation-room-user-image.png'; PHASE 2
// import AddedToFavHeartImage from 'Assets/images/Common/added-to-fav-heart-icon.png'; PHASE 2
import { ReactComponent as CrossIcon } from 'Assets/images/MeditationTimer/cross-icon.svg';
import Ribbon from 'Assets/images/Common/ribbon.svg';

// import SliderNextButton from 'Assets/images/Common/slider-next-button.svg'; PHASE 2
// import SliderPreviousButton from 'Assets/images/Common/slider-previous-button.svg'; PHASE 2

import Footer from 'Components/common/Footer/Footer';
import TaoCalligraphyWatermark from 'Components/common/TaoCalligraphyWatermark';
// import TaoSessionRecoCard from 'Components/common/TaoSessionRecoCard/TaoSessionRecoCard'; PHASE 2

import { SearchSectionWrapper, CustomSearchInputBox, FooterSectionWrapper } from './Search.style';

import { useDispatch } from 'react-redux';
import { showFooter, hideFooter, showSubscriptionModal } from 'Redux/App/Actions';

import { getWindowDimensions, handleContentRedirection } from 'Helpers';
import Api from 'Helpers/ApiHandler';
import { API_URL, URL_MEDITATION_READ, URL_WELLNESS_CATEGORY } from 'Helpers/Paths';
import TaoLoader from 'Components/common/TaoBackdropLoader';
import TaoMeditationCard from 'Components/common/TaoMeditationCard/TaoMeditationCard';
import { ACCESS_LEVELS_MODULEs, CONTENT, MEDIA_TYPE, SLIDER_PER_PAGE } from 'Helpers/Constants';
import { useNavigate } from 'react-router-dom';
import TaoSlider from 'Components/common/TaoSlider';
import TaoScrollbar from 'Components/common/Scrollbar';
import { SortFilter } from 'Components/common/SortFilter';
import usePermissions from 'Components/common/Permissions';
import { SubscriptionDarkLock } from 'Components/common/SubscriptionLock';
import TaoCard from 'Components/common/TaoCard';

const SORT_BY_VALUE = {
    LATEST: 'Latest',
    A_Z: 'A-Z'
};

// PHASE 2
// const SORT_BY_VALUE_SESSIONS = {
//     RATING: 'Rating',
//     A_Z: 'A-Z'
// };

const SORT_BY_API_VALUE = {
    Latest: 'latest',
    'A-Z': 'A-Z'
};

const SORT_BY_API_ORDER = {
    Latest: 'desc',
    'A-Z': 'asc'
};

const SearchIcon = () => {
    return (
        <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.39518 0C14.4387 0 18.5321 4.032 18.5321 9C18.5321 13.968 14.4387 18 9.39518 18C4.35162 18 0.258301 13.968 0.258301 9C0.258301 4.032 4.35162 0 9.39518 0ZM9.39518 16C13.321 16 16.5016 12.867 16.5016 9C16.5016 5.132 13.321 2 9.39518 2C5.46835 2 2.28872 5.132 2.28872 9C2.28872 12.867 5.46835 16 9.39518 16ZM18.0092 16.071L20.8813 18.899L19.4447 20.314L16.5737 17.485L18.0092 16.071Z"
                fill="#656565"
            />
        </svg>
    );
};

const ChipSearchIcon = () => {
    return (
        <svg
            width="15"
            height="14"
            viewBox="0 0 15 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.26986 0C9.73082 0 12.5397 2.77877 12.5397 6.20262C12.5397 9.62646 9.73082 12.4052 6.26986 12.4052C2.8089 12.4052 0 9.62646 0 6.20262C0 2.77877 2.8089 0 6.26986 0ZM6.26986 11.0269C8.9638 11.0269 11.1464 8.86768 11.1464 6.20262C11.1464 3.53687 8.9638 1.37836 6.26986 1.37836C3.57521 1.37836 1.3933 3.53687 1.3933 6.20262C1.3933 8.86768 3.57521 11.0269 6.26986 11.0269ZM11.1809 10.0758L14.1518 13.0248L13.166 14L10.1959 11.0503L11.1809 10.0758Z"
                fill="#656565"
            />
        </svg>
    );
};

// PHASE 2
// const liveSessionsList = [
//     {
//         isLive: true,
//         avatarImage: ProfileImage2,
//         sessionTime: '3:00 - 4:00 PM',
//         sessionTitle: 'Breathwork for Boosting Energy',
//         sessionWith: 'with Harsha',
//         isTaoCalligraphyTeacher: false,
//         isPlaylist: false
//     },
//     {
//         isLive: false,
//         avatarImage: ProfileImage4,
//         sessionTime: '3:00 - 4:00 PM',
//         sessionTitle: 'Wellness Boost',
//         sessionWith: 'with Harsha',
//         isTaoCalligraphyTeacher: true,
//         isPlaylist: false,
//         needSignUp: true
//     },
//     {
//         isLive: true,
//         avatarImage: ProfileImage1,
//         sessionTime: '3:00 - 4:00 PM',
//         sessionTitle: 'Breathwork for Boosting Energy',
//         sessionWith: 'with Harsha',
//         isTaoCalligraphyTeacher: false,
//         isPlaylist: false
//     },
//     {
//         isLive: true,
//         avatarImage: ProfileImage1,
//         sessionTime: '3:00 - 4:00 PM',
//         sessionTitle: 'Breathwork for Boosting Energy',
//         sessionWith: 'with Harsha',
//         isTaoCalligraphyTeacher: false,
//         isPlaylist: false
//     },
//     {
//         isLive: true,
//         avatarImage: ProfileImage1,
//         sessionTime: '3:00 - 4:00 PM',
//         sessionTitle: 'Breathwork for Boosting Energy',
//         sessionWith: 'with Harsha',
//         isTaoCalligraphyTeacher: false,
//         isPlaylist: false
//     },
//     {
//         isLive: true,
//         avatarImage: ProfileImage1,
//         sessionTime: '3:00 - 4:00 PM',
//         sessionTitle: 'Breathwork for Boosting Energy',
//         sessionWith: 'with Harsha',
//         isTaoCalligraphyTeacher: false,
//         isPlaylist: false
//     },
//     {
//         isLive: true,
//         avatarImage: ProfileImage1,
//         sessionTime: '3:00 - 4:00 PM',
//         sessionTitle: 'Breathwork for Boosting Energy',
//         sessionWith: 'with Harsha',
//         isTaoCalligraphyTeacher: false,
//         isPlaylist: false
//     }
// ];

// PHASE 2
// const meditationRoomsList = [
//     {
//         id: 1,
//         userImage: MeditationRoomUserImage,
//         roomTitle: 'Mindfulness for IT Professionals',
//         roomRating: '27'
//     },
//     {
//         id: 2,
//         userImage: MeditationRoomUserImage,
//         roomTitle: 'Mindfulness for IT Professionals',
//         roomRating: '27'
//     },
//     {
//         id: 3,
//         userImage: MeditationRoomUserImage,
//         roomTitle: 'Mindfulness for IT Professionals',
//         roomRating: '27'
//     },
//     {
//         id: 4,
//         userImage: MeditationRoomUserImage,
//         roomTitle: 'Mindfulness for IT Professionals',
//         roomRating: '27'
//     },
//     {
//         id: 5,
//         userImage: MeditationRoomUserImage,
//         roomTitle: 'Mindfulness for IT Professionals',
//         roomRating: '27'
//     },
//     {
//         id: 6,
//         userImage: MeditationRoomUserImage,
//         roomTitle: 'Mindfulness for IT Professionals',
//         roomRating: '27'
//     },
//     {
//         id: 7,
//         userImage: MeditationRoomUserImage,
//         roomTitle: 'Mindfulness for IT Professionals',
//         roomRating: '27'
//     }
// ];

const Search = () => {
    const navigate = useNavigate();
    const API = useMemo(() => new Api(), []);
    const dispatch = useDispatch();
    const [pagination, setPagination] = useState({
        current_page: 1,
        per_page: SLIDER_PER_PAGE,
        totalRecords: 0,
        search: '',
        sort: {
            field: SORT_BY_API_VALUE.Latest,
            order: SORT_BY_API_ORDER.Latest
        }
    });
    const [programPagination, setProgramPagination] = useState({
        current_page: 1,
        per_page: SLIDER_PER_PAGE,
        totalRecords: 0,
        search: '',
        sort: {
            field: SORT_BY_API_VALUE.Latest,
            order: SORT_BY_API_ORDER.Latest
        }
    });
    const [searchedKeyword, setSearchKeyword] = useState('');
    const [sortBy, setSortBy] = useState(SORT_BY_VALUE?.LATEST);
    const [programSortBy, setProgramSortBy] = useState(SORT_BY_VALUE?.LATEST);

    // const [sessionSortBy] = useState(SORT_BY_VALUE_SESSIONS?.RATING); PHASE 2
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [meditationContentList, setMeditationContentList] = useState([]);
    const [programList, setProgramList] = useState([]);
    const [popularSearchedKeyword, setPopularSearchedKeyword] = useState([]);
    const [isSearched, setIsSearched] = useState(false);
    const [wellnessCategory, setWellnessCategory] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isProgramLoading, setIsProgramLoading] = useState(false);
    const MeditationSlickSliderRef = useRef(null);
    const ProgramSlickSliderRef = useRef(null);
    const contentLibraryPermission = usePermissions(ACCESS_LEVELS_MODULEs.CONTENT_LIBRARY);

    const searchButtonRef = useRef(null);

    const navigateTo = (url, value, isAccessible = true) => {
        if (!url) return;

        if (!isAccessible) {
            dispatch(showSubscriptionModal());
            return;
        }

        navigate(url, {
            state: value
        });
    };

    const getWellnessCategory = useCallback(async () => {
        setIsLoading(true);
        const response = await API.get(API_URL.WELLNESS_CATEGORY);

        if (response) {
            setWellnessCategory(response?.data?.data?.list);
        }
        setIsLoading(false);
    }, [API]);

    const postSearchContentApiData = useCallback(async () => {
        if (pagination?.search) {
            const data = {
                current_page: pagination?.current_page - 1 || 0,
                per_page: pagination?.per_page,
                search: pagination?.search || '',
                sort: { ...pagination?.sort }
            };
            setIsLoading(true);
            const response = await API.post(API_URL.SEARCH_CONTENT, {
                data
            });

            if (response) {
                setMeditationContentList((prev) => {
                    let arr =
                        pagination?.current_page === 1
                            ? response?.data?.data?.list
                            : prev.concat(response?.data?.data?.list);
                    return [...new Map(arr.map((item) => [item['id'], item])).values()];
                });
                setPagination((prev) => ({
                    ...prev,
                    totalRecords: response?.data?.data?.totalRecords
                }));
                setIsLoading(false);
                setIsSearched(pagination?.search ? true : false);
            }
        }
    }, [pagination?.sort, pagination?.current_page, pagination?.per_page, API, pagination?.search]);

    const getPrograms = useCallback(async () => {
        if (programPagination?.search) {
            const data = {
                current_page: programPagination?.current_page - 1 || 0,
                per_page: programPagination?.per_page,
                search: programPagination?.search || '',
                sort: { ...programPagination?.sort }
            };
            setIsProgramLoading(true);
            const response = await API.post(API_URL.SEARCH_PROGRAM, {
                data
            });

            if (response) {
                setProgramList(response?.data?.data?.list);
                setProgramPagination((prev) => ({
                    ...prev,
                    totalRecords: response?.data?.data?.totalRecords
                }));
                setIsProgramLoading(false);
                setIsSearched(programPagination?.search ? true : false);
            }
        }
    }, [
        programPagination?.sort,
        programPagination?.current_page,
        programPagination?.per_page,
        API,
        programPagination?.search
    ]);

    const getSearchKeyword = useCallback(async () => {
        const response = await API.get(API_URL.SEARCH);

        if (response) {
            setPopularSearchedKeyword(response?.data?.data?.searchedKeyword);
        }
    }, [API]);

    const handleSearch = (popularSearches) => {
        setSearchKeyword(popularSearches);
        setPagination((prev) => ({
            ...prev,
            search: popularSearches
        }));
        setProgramPagination((prev) => ({
            ...prev,
            search: popularSearches
        }));
    };

    const searchContent = () => {
        if (!searchedKeyword) return;
        setPagination({ ...pagination, search: searchedKeyword });
        setProgramPagination({ ...programPagination, search: searchedKeyword });
    };

    const handleSearchOnEnter = (e) => {
        if (e.keyCode === 13) {
            setIsSearched(true);
            setPagination((prev) => ({ ...prev, search: e.target.value }));
            setProgramPagination((prev) => ({ ...prev, search: e.target.value }));
        }
    };

    const handleFavoriteMeditationContent = async (data, index) => {
        let copyMeditationContentList = [...meditationContentList];
        if (copyMeditationContentList[index]) {
            copyMeditationContentList[index].isFavorite =
                !copyMeditationContentList[index].isFavorite;

            setMeditationContentList(copyMeditationContentList);

            await API.post(API_URL.CONTENT_FAVOURITES, {
                params: {
                    contentId: data?.id
                }
            });
        }
    };

    const handleFavoriteProgram = async (data, index) => {
        let copyProgramList = [...programList];
        if (copyProgramList[index]) {
            copyProgramList[index].isFavorite = !copyProgramList[index].isFavorite;

            setProgramList(copyProgramList);

            await API.post(API_URL.PROGRAM_FAVORITE, {
                params: {
                    programId: data?.id
                }
            });
        }
    };

    const handleReadContentRedirection = (value) => {
        navigateTo(URL_MEDITATION_READ, {
            contentId: value?.id
        });
    };

    useEffect(() => {
        if (!isSearched) {
            getSearchKeyword();
            getWellnessCategory();
        }
    }, [isSearched, getSearchKeyword, getWellnessCategory]);

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        if (windowDimensions.width <= BREAKPOINTS_VALUE.TABLET) {
            dispatch(hideFooter());
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            dispatch(showFooter());
        };
    }, [dispatch, windowDimensions?.width]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (!pagination?.search) setIsSearched(false);
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [pagination?.search]);

    useEffect(() => {
        postSearchContentApiData();
    }, [
        pagination?.sort,
        pagination?.current_page,
        pagination?.per_page,
        API,
        pagination?.search,
        postSearchContentApiData
    ]);
    useEffect(() => {
        getPrograms();
    }, [
        programPagination?.sort,
        programPagination?.current_page,
        programPagination?.per_page,
        API,
        programPagination?.search,
        getPrograms
    ]);

    const backToSearchPage = () => {
        setSearchKeyword('');
        setPagination((prev) => ({
            ...prev,
            search: ''
        }));
        setProgramPagination((prev) => ({
            ...prev,
            search: ''
        }));
        setIsSearched(false);
    };

    return (
        <>
            <TaoCalligraphyWatermark />
            <TaoLoader isLoading={isLoading || isProgramLoading} />
            <SearchSectionWrapper $searched={isSearched}>
                <Box className="search-section">
                    <Box className="search-box-section">
                        <Typography className="search-title">Search</Typography>
                        <Box className="flex search-input-button f-v-center">
                            <CustomSearchInputBox
                                InputProps={{
                                    classes: {
                                        notchedOutline: 'notchedOutline',
                                        root: 'base-root',
                                        focused: 'focused',
                                        adornedStart: 'start'
                                    },
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment
                                            position="end"
                                            className="search-input-text-icon hover">
                                            {searchedKeyword && (
                                                <CrossIcon onClick={backToSearchPage} />
                                            )}
                                        </InputAdornment>
                                    )
                                }}
                                value={searchedKeyword}
                                placeholder="What's on your mind?"
                                onChange={(e) => {
                                    if (!e.target.value) {
                                        setSearchKeyword('');
                                        setIsSearched(false);
                                        return;
                                    }
                                    setSearchKeyword(e.target.value);
                                }}
                                onKeyDown={handleSearchOnEnter}
                            />

                            <Button
                                ref={searchButtonRef}
                                variant="contained"
                                className="search-go-button"
                                onClick={() => searchContent()}>
                                <Typography>GO</Typography>
                            </Button>
                        </Box>
                    </Box>

                    {!isSearched && (
                        <>
                            <Box className="popular-search-section">
                                {windowDimensions.width > BREAKPOINTS_VALUE.TABLET && (
                                    <Typography className="popular-search-title">
                                        {popularSearchedKeyword.length !== 0 && 'Popular Searches'}
                                    </Typography>
                                )}

                                <Box className="popular-searches-list">
                                    <TaoScrollbar horizontalScroll keyId="searchChips">
                                        {popularSearchedKeyword.map((popularSearches, index) => {
                                            return (
                                                <Chip
                                                    key={index}
                                                    classes={{
                                                        label: 'chip-label'
                                                    }}
                                                    className="popular-searches-chip large not-selected-chip hover"
                                                    size="medium"
                                                    color="primary"
                                                    variant="outlined"
                                                    label={popularSearches}
                                                    icon={<ChipSearchIcon />}
                                                    onClick={() => handleSearch(popularSearches)}
                                                />
                                            );
                                        })}
                                    </TaoScrollbar>
                                </Box>
                            </Box>

                            <Box className="search-by-category-section">
                                <Box className="search-by-category-details">
                                    <Typography className="you-can-also-text">
                                        You can also...
                                    </Typography>
                                    <Box className="flex f-v-center">
                                        <Typography className="search-by-category-text">
                                            Search By Category
                                        </Typography>
                                        {!contentLibraryPermission?.canAccess &&
                                            contentLibraryPermission?.permissionLoaded && (
                                                <SubscriptionDarkLock
                                                    height={18}
                                                    customStyle={{ margin: '0 0 0 5px' }}
                                                />
                                            )}
                                    </Box>

                                    <Box
                                        className={`wellness-list ${
                                            !contentLibraryPermission?.canAccess &&
                                            'decrease-opacity'
                                        }`}>
                                        {wellnessCategory.map((value) => {
                                            return (
                                                <Box
                                                    className="wellness-content"
                                                    key={value.categoryId}
                                                    onClick={() =>
                                                        navigateTo(URL_WELLNESS_CATEGORY, {
                                                            wellnessCategoryDetails: value
                                                        })
                                                    }>
                                                    <IconButton className="wellness-icon-button">
                                                        <img
                                                            className="wellness-icon"
                                                            src={value.icon}
                                                            alt=""
                                                        />
                                                        {value?.isFeatured && (
                                                            <img
                                                                className="wellness-ribbon-icon"
                                                                src={Ribbon}
                                                                alt=""
                                                            />
                                                        )}
                                                    </IconButton>
                                                    <Typography
                                                        color="primary"
                                                        className="wellness-category-name">
                                                        {value.categoryName}
                                                    </Typography>
                                                </Box>
                                            );
                                        })}
                                    </Box>
                                </Box>
                            </Box>
                        </>
                    )}

                    {isSearched && (
                        <>
                            <Typography className="result-count">
                                <strong>
                                    {pagination.totalRecords + programPagination.totalRecords}
                                </strong>{' '}
                                Results
                            </Typography>

                            <Box className="meditation-content">
                                <Box className="meditation-videos-title-section">
                                    <Typography className="meditation-videos-title">
                                        Meditations
                                    </Typography>

                                    {!!meditationContentList?.length && (
                                        <Box className="meditation-content-filter" variant="filled">
                                            <SortFilter
                                                sortBy={sortBy}
                                                sortObject={SORT_BY_VALUE}
                                                onClick={(item) => {
                                                    if (sortBy !== item) {
                                                        setPagination((prev) => ({
                                                            ...prev,
                                                            current_page: 1,
                                                            sort: {
                                                                field: SORT_BY_API_VALUE[item],
                                                                order: SORT_BY_API_ORDER[item]
                                                            }
                                                        }));
                                                    }
                                                    setSortBy(item);
                                                }}
                                            />
                                        </Box>
                                    )}
                                </Box>

                                <Box className="meditation-videos-content-details">
                                    <TaoSlider
                                        ref={MeditationSlickSliderRef}
                                        slidesToShow={3}
                                        // onNextArrowClick={handleNextArrowClick}
                                    >
                                        {meditationContentList.map((value, index) => {
                                            return (
                                                <TaoMeditationCard
                                                    key={value.id}
                                                    contentImage={value?.thumbnailImage}
                                                    contentName={value?.title}
                                                    description={value?.description}
                                                    type={value?.type}
                                                    contentDuration={value?.contentDuration}
                                                    isDangerouslySetInnerHTML={true}
                                                    favoriteIconVisible={true}
                                                    badge={value?.subscription?.badge}
                                                    isAccessible={
                                                        value?.subscription
                                                            ? value?.subscription?.isAccessible
                                                            : true
                                                    }
                                                    isFav={value?.isFavorite}
                                                    isPaidContent={value?.isPaidContent}
                                                    isPurchased={value?.isPurchased}
                                                    onCardClick={() => {
                                                        if (
                                                            MEDIA_TYPE.MUSIC === value?.type ||
                                                            MEDIA_TYPE.AUDIO === value?.type ||
                                                            MEDIA_TYPE.GUIDED_AUDIO ===
                                                                value?.type ||
                                                            MEDIA_TYPE.VIDEO === value?.type
                                                        ) {
                                                            return handleContentRedirection(
                                                                { ...value, type: CONTENT },
                                                                navigateTo
                                                            );
                                                        }
                                                        if (MEDIA_TYPE.TEXT === value?.type) {
                                                            handleReadContentRedirection(value);
                                                        }
                                                    }}
                                                    onFavClick={() => {
                                                        handleFavoriteMeditationContent(
                                                            value,
                                                            index
                                                        );
                                                    }}
                                                />
                                            );
                                        })}
                                    </TaoSlider>
                                    {pagination.totalRecords === 0 && (
                                        <Box
                                            className="flex f-v-center f-h-center"
                                            width={'100%'}
                                            height={'240px'}>
                                            <Typography className="no-record-para">
                                                {isLoading
                                                    ? 'Meditations Loading...'
                                                    : 'No meditations found'}
                                            </Typography>
                                        </Box>
                                    )}
                                </Box>
                            </Box>

                            <Box className="meditation-content program-section">
                                <Box className="meditation-videos-title-section">
                                    <Typography className="meditation-videos-title">
                                        Programs
                                    </Typography>

                                    {!!programList?.length && (
                                        <Box className="meditation-content-filter" variant="filled">
                                            <SortFilter
                                                sortBy={programSortBy}
                                                sortObject={SORT_BY_VALUE}
                                                onClick={(item) => {
                                                    if (programSortBy !== item) {
                                                        setProgramPagination((prev) => ({
                                                            ...prev,
                                                            current_page: 1,
                                                            sort: {
                                                                field: SORT_BY_API_VALUE[item],
                                                                order: SORT_BY_API_ORDER[item]
                                                            }
                                                        }));
                                                    }
                                                    setProgramSortBy(item);
                                                }}
                                            />
                                        </Box>
                                    )}
                                </Box>

                                <Box className="meditation-videos-content-details">
                                    <TaoSlider
                                        ref={ProgramSlickSliderRef}
                                        slidesToShow={4}
                                        gap={10}
                                        cardHeight={90}>
                                        {programList.map((value, index) => {
                                            return (
                                                <TaoCard
                                                    cardRootClass="tao-card"
                                                    key={index}
                                                    backgroundImage={value?.thumbnailImage}
                                                    mediaTextType="primary"
                                                    type={value.type}
                                                    title={value.title}
                                                    favoriteIconVisible={true}
                                                    isFav={value?.isFavorite}
                                                    badge={value?.subscription?.badge}
                                                    isAccessible={
                                                        value?.subscription
                                                            ? value?.subscription?.isAccessible
                                                            : true
                                                    }
                                                    isPaidContent={value?.isPaidContent}
                                                    isPurchased={value?.isPurchased}
                                                    isFeatured={value?.isFeatured}
                                                    isTextColorDark
                                                    textFromStart={true}
                                                    onFavClick={() => {
                                                        handleFavoriteProgram(value, index);
                                                    }}
                                                    onClick={() => {
                                                        handleContentRedirection(value, navigateTo);
                                                    }}
                                                />
                                            );
                                        })}
                                    </TaoSlider>
                                    {programPagination.totalRecords === 0 && (
                                        <Box
                                            className="flex f-v-center f-h-center"
                                            width={'100%'}
                                            height={'240px'}>
                                            <Typography className="no-record-para">
                                                {isProgramLoading
                                                    ? 'Programs Loading...'
                                                    : 'No programs found'}
                                            </Typography>
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                            {/* <Box className="live-session-content">
                                <Typography className="live-session-title">
                                    Live Sessions
                                </Typography>

                                <Box className="live-session-content-details">
                                    <Slider ref={slickSliderRef} {...SLIDER_SETTINGS}>
                                        {liveSessionsList.map((liveSession, index) => (
                                            <TaoSessionRecoCard
                                                key={index}
                                                {...liveSession}
                                                verticalCard
                                                cardRootClass="live-session-card"
                                            />
                                        ))}
                                    </Slider>
                                </Box>
                            </Box> */}

                            {/* <Box className="meditation-rooms-content">
                                <Box className="meditation-rooms-title-section">
                                    <Typography className="meditation-rooms-title">
                                        Meditation Rooms
                                    </Typography>

                                    <Box className="meditation-rooms-filter">
                                        <SortFilter
                                            sortBy={sessionSortBy}
                                            sortObject={SORT_BY_VALUE_SESSIONS}
                                        />
                                    </Box>
                                </Box>

                                <Box className="meditation-rooms-content-details">
                                    <Slider {...SLIDER_SETTINGS}>
                                        {meditationRoomsList.map((meditationRoomContent) => {
                                            return (
                                                <React.Fragment key={meditationRoomContent.id}>
                                                    <Box className="meditation-room">
                                                        <img
                                                            className="user-image"
                                                            src={meditationRoomContent.userImage}
                                                            alt=""
                                                        />
                                                        <Typography className="meditation-room-title">
                                                            {meditationRoomContent.roomTitle}
                                                        </Typography>

                                                        <Box className="room-rating">
                                                            <StarOutlined
                                                                color="primary"
                                                                width="15"
                                                            />
                                                            <StarOutlined
                                                                color="primary"
                                                                width="15"
                                                            />
                                                            <StarOutlined
                                                                color="primary"
                                                                width="15"
                                                            />
                                                            <StarOutlined
                                                                color="primary"
                                                                width="15"
                                                            />
                                                            <StarBorderOutlined
                                                                color="primary"
                                                                width="15"
                                                            />

                                                            <Typography className="meditation-room-rating">
                                                                {meditationRoomContent.roomRating}
                                                            </Typography>
                                                        </Box>

                                                        <img
                                                            className="added-to-fav-heart-image"
                                                            src={AddedToFavHeartImage}
                                                            alt=""
                                                        />
                                                    </Box>
                                                </React.Fragment>
                                            );
                                        })}
                                    </Slider>
                                </Box>
                            </Box> */}
                        </>
                    )}
                </Box>
            </SearchSectionWrapper>
            <FooterSectionWrapper>
                <Footer showWhiteBackground={false} topBorderColor="gray" />
            </FooterSectionWrapper>
        </>
    );
};

export default Search;
