import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {
    Button,
    Checkbox,
    IconButton,
    InputAdornment,
    OutlinedInput,
    Typography,
    FormHelperText,
    Box
} from '@mui/material';
import FacebookLogin from 'react-facebook-login';
import {
    LinearProgressWrapper,
    SignupWrapper,
    PasswordStrengthWrapper,
    CustomCheckedIcon,
    CustomIcon
} from './Signup.style.js';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { ReactComponent as AppleIcon } from 'Assets/images/Signup/apple-icon.svg';
import { ReactComponent as FacebookIcon } from 'Assets/images/Signup/facebook-icon.svg';
import { ReactComponent as GoogleIcon } from 'Assets/images/Signup/google-icon.svg';
import {
    API_URL,
    // URL_PRIVACY_POLICY,
    URL_QUESTIONNAIRE_MAIN,
    URL_SIGN_UP,
    // URL_TERMS_CONDITION,
    URL_VERIFY_EMAIL,
    URL_HOME_PAGE
} from 'Helpers/Paths.js';
import { useNavigate, useLocation } from 'react-router-dom';
import Footer from 'Components/common/Footer/Footer';
import { ReactComponent as CheckIcon } from 'Assets/images/Common/check.svg';
import { ReactComponent as CrossIcon } from 'Assets/images/Common/cross.svg';
import { ReactComponent as VisibilityOff } from 'Assets/images/Common/chevron-down.svg';
import { ReactComponent as Visibility } from 'Assets/images/Common/eye.svg';

// FORM VALIDATION
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { DEVICE_TYPE, PAGE, SIGN_UP_TYPE } from 'Helpers/Constants.js';
import Api from 'Helpers/ApiHandler.js';
import { showToast } from 'Redux/App/Actions.js';
import CODES from 'Helpers/StatusCodes.js';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { loginUser } from 'Redux/Auth/Actions.js';
import { decodeToken } from 'react-jwt';
import { detectBrowser, detectOS, EMAIL_REGEX } from 'Helpers/Utils.js';
import TaoLoader from 'Components/common/TaoBackdropLoader/index.js';
import Page from 'Components/common/Footer/FooterDialog/FooterDialog.jsx';
import { SlickDotSlider } from 'Components/common/TaoSlider/SlickDotSlider.jsx';

const AppURL = process.env.PUBLIC_URL;

const SOCIAL_SIGN_UP_VALIDATION_SCHEMA = {
    firstName: Yup.string()
        .required('Please enter your first name')
        .matches(/(?!^\d+$)^.+$/, 'Please enter a valid first name')
        .test(
            'firstName',
            'Please enter valid name',
            (value) => !/\p{Extended_Pictographic}/gu.test(value)
        )
        .min(2, 'The first name must contain at least 2 characters long')
        .max(30, 'First name should not exceed more than 30 characters'),
    lastName: Yup.string()
        .required('Please enter your last name')
        .matches(/(?!^\d+$)^.+$/, 'Please enter a valid last name')
        .test(
            'lastName',
            'Please enter valid name',
            (value) => !/\p{Extended_Pictographic}/gu.test(value)
        )
        .min(2, 'The Last name must contain at least 2 characters long')
        .max(30, 'Last name should not exceed more than 30 characters'),
    email: Yup.string()
        .required('Please enter email address')
        .email('Please enter a valid email address')
        .matches(EMAIL_REGEX, 'Please enter a valid email address'),
    termsConditions: Yup.boolean().optional()
};

const SIGN_UP_VALIDATION_SCHEMA = {
    firstName: Yup.string()
        .required('Please enter your first name')
        .matches(/(?!^\d+$)^.+$/, 'Please enter a valid first name')
        .test(
            'firstName',
            'Please enter valid name',
            (value) => !/\p{Extended_Pictographic}/gu.test(value)
        )
        .min(2, 'The first name must contain at least 2 characters long')
        .max(30, 'First name should not exceed more than 30 characters'),
    lastName: Yup.string()
        .required('Please enter your last name')
        .matches(/(?!^\d+$)^.+$/, 'Please enter a valid last name')
        .test(
            'lastName',
            'Please enter valid name',
            (value) => !/\p{Extended_Pictographic}/gu.test(value)
        )
        .min(2, 'The Last name must contain at least 2 characters long')
        .max(30, 'Last name should not exceed more than 30 characters'),
    email: Yup.string()
        .required('Please enter email address')
        .email('Please enter a valid email address')
        .matches(EMAIL_REGEX, 'Please enter a valid email address'),
    password: Yup.string()
        .required('Please enter password')
        .matches(
            /(?=[A-Za-z0-9@#$%^&+!=\\/\]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#?!@$%^&<>*~.:+`-])(?=.{10,}).*$/g,
            'Password must contain one upper case, one lowercase, one special character, one digit and must be of 10 characters.'
        ),
    termsConditions: Yup.boolean().optional(),
    confirmPassword: Yup.string()
        .required('Please enter confirm password')
        .oneOf([Yup.ref('password'), null], 'Password Do Not Match')
};

const USER_NAME_REGEX = /[!@#£€$¢¥§%&*()_+={}[\]|\\/:;"<>,?0-9]/gi;

const SliderList = [
    {
        title: 'Personalize Your Wellness Needs'
    },
    { title: 'Track Your Progress' },
    { title: 'Receive Daily Wisdom' },
    { title: 'Start Your Free Trial' }
];

const Signup = (props) => {
    const API = useMemo(() => new Api(), []);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [signUpChoice, setSignUpChoice] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [passwordStrengthDetails, setPasswordStrengthDetails] = useState({
        value: 0,
        text: 'Password Strength',
        background: ''
    });
    const [userValueFilledBySocial, setUserValueFilledBySocial] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location?.search);
    const referralCode = queryParams && queryParams?.get?.('referralCode');
    const [openPageDialog, setOpenPageDialog] = useState(false);
    const [selectedPageTitle, setSelectedPageTitle] = useState('');
    const [selectedPageType, setSelectedPageType] = useState('');
    const [gettingEmailInSocial, setSocialEmailStatus] = useState(false);
    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: '',
            termsConditions: false,
            socialId: '',
            emailVerified: false
        },
        validationSchema: Yup.object(
            userValueFilledBySocial ? SOCIAL_SIGN_UP_VALIDATION_SCHEMA : SIGN_UP_VALIDATION_SCHEMA
        ),
        onSubmit: (values) => {
            submitSignUP(values, userValueFilledBySocial ? true : false);
        }
    });

    const { setFieldValue, resetForm } = formik;

    const initApple = () => {
        window.AppleID.auth.init({
            clientId: 'com.taocalligraphy.onenessheartweb', // This is the service ID we created.
            scope: 'name', // To tell apple we want the user name and emails fields in the response it sends us.
            redirectURI: AppURL + URL_SIGN_UP, // As registered along with our service ID
            state: 'origin:web', // Any string of your choice that you may use for some logic. It's optional and you may omit it.
            usePopup: true // Important if we want to capture the data apple sends on the client side.
        });
    };

    const googleLogin = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            const userInfo = await axios.get(API_URL?.GOOGLE_APIs_USER_INFO, {
                headers: { Authorization: `Bearer ${tokenResponse.access_token}` }
            });
            if (userInfo) {
                const { data } = userInfo;
                let values = {
                    firstName: data?.given_name,
                    lastName: data?.family_name,
                    socialId: data?.sub,
                    email: data?.email,
                    emailVerified: data?.email_verified,
                    socialType: SIGN_UP_TYPE.GOOGLE
                };

                let result = null;
                if (values) result = await checkUserAlreadySignUp(values, true);

                if (result === CODES.NOT_FOUND) {
                    setUserValueFilledBySocial(true);
                    setSocialEmailStatus(values?.email ? true : false);
                    formik.setFieldValue('firstName', values?.firstName);
                    formik.setFieldValue('lastName', values?.lastName);
                    formik.setFieldValue('email', values?.email);
                    formik.setFieldValue('socialId', values?.socialId);
                    formik.setFieldValue('socialType', values?.socialType);
                    formik.setFieldValue('emailVerified', values?.emailVerified);
                    setSignUpChoice(true);
                }
            }
        },
        onError: (errorResponse) => console.log(errorResponse)
    });

    const singInApple = async () => {
        const response = await window.AppleID.auth.signIn();

        if (response) {
            const { user, authorization } = response;
            let decodedToken = decodeToken(authorization?.id_token);
            let values = {
                firstName: user?.name?.firstName || '',
                lastName: user?.name?.lastName || '',
                socialId: decodedToken?.sub || '',
                email: decodedToken?.email || '',
                emailVerified: true,
                socialType: SIGN_UP_TYPE.APPLE
            };

            let result = null;
            if (values) result = await checkUserAlreadySignUp(values, true);

            if (result === CODES.NOT_FOUND) {
                setUserValueFilledBySocial(true);
                setSocialEmailStatus(values?.email ? true : false);
                formik.setFieldValue('firstName', values?.firstName);
                formik.setFieldValue('lastName', values?.lastName);
                formik.setFieldValue('email', values?.email);
                formik.setFieldValue('socialId', values?.socialId);
                formik.setFieldValue('socialType', values?.socialType);
                formik.setFieldValue('emailVerified', values?.emailVerified);
                setSignUpChoice(true);
            }
        }
    };

    const facebookResponse = async (data) => {
        let values = {
            firstName: data?.name.split(' ')[0],
            lastName: data?.name.split(' ')[1],
            socialId: data?.userID,
            email: data?.email,
            emailVerified: data?.email ? true : false,
            socialType: SIGN_UP_TYPE.FACEBOOK
        };
        let result = null;
        if (values) result = await checkUserAlreadySignUp(values, true);

        if (result === CODES.NOT_FOUND) {
            setUserValueFilledBySocial(true);
            setSocialEmailStatus(values?.email ? true : false);
            formik.setFieldValue('firstName', values?.firstName);
            formik.setFieldValue('lastName', values?.lastName);
            formik.setFieldValue('email', values?.email);
            formik.setFieldValue('socialId', values?.socialId);
            formik.setFieldValue('socialType', values?.socialType);
            formik.setFieldValue('emailVerified', values?.emailVerified);
            setSignUpChoice(true);
        }
    };

    const checkUserAlreadySignUp = async (values, isSocial = false) => {
        let reqBody = {
            ...values,
            password: '',
            deviceType: DEVICE_TYPE.WEB,
            deviceToken: '',
            loginType: isSocial ? values?.socialType : SIGN_UP_TYPE.NORMAL,
            location: '',
            browserType: detectBrowser(),
            osType: detectOS(),
            deviceModel: '',
            deviceVersion: ''
        };

        try {
            setIsLoading(true);
            let response = await API.post(API_URL.LOG_IN, {
                data: reqBody,
                skipErrorHandling: isSocial ? true : false
            });

            setIsLoading(false);
            if (
                response &&
                response.status === CODES.SUCCESS &&
                response?.data?.data?.isEmailVerified
            ) {
                dispatch(loginUser(response?.data?.data));
                response?.data?.data?.isQuestionnaireCompleted
                    ? navigate(URL_HOME_PAGE)
                    : navigate(URL_QUESTIONNAIRE_MAIN);
                return 'REDIRECT';
            }
        } catch (error) {
            if (error?.response?.status === CODES.NOT_FOUND) {
                setIsLoading(false);
                return CODES.NOT_FOUND;
            }
        }
    };

    const submitSignUP = async (values, isSocial = false) => {
        try {
            if (!values?.termsConditions) {
                dispatch(
                    showToast('Please agree to our Terms & Conditions and Privacy Policy.', 'error')
                );
                return;
            }

            setIsLoading(true);
            let reqBody = {
                firstName: values?.firstName,
                lastName: values?.lastName,
                email: values?.email,
                password: values?.password || '',
                signupType: isSocial ? values?.socialType : SIGN_UP_TYPE.NORMAL,
                socialId: isSocial ? values.socialId : '',
                deviceType: DEVICE_TYPE?.WEB,
                deviceToken: '',
                isEmailIdEnteredManually: !values?.emailVerified,
                location: '',
                browserType: detectBrowser(),
                osType: detectOS(),
                deviceModel: '',
                deviceVersion: '',
                referralCode
            };

            let response = await API.post(API_URL.SIGN_UP, {
                data: reqBody
            });

            if (response?.status === CODES.CREATED && isSocial && values?.emailVerified) {
                dispatch(loginUser(response?.data?.data));
                navigate(URL_QUESTIONNAIRE_MAIN);
                return;
            }

            if (response?.status === CODES.CREATED) {
                dispatch(showToast(response?.data?.message, 'success', 10000));
                navigate(URL_VERIFY_EMAIL, {
                    state: { email: values?.email, password: values?.password }
                });
            }

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    const passwordStrength = useCallback(() => {
        if (!formik?.values?.password) {
            setPasswordStrengthDetails({
                value: 0,
                text: 'Password Strength'
            });
            return;
        }

        let count = 0;
        let passwordStrengthResponse = {
            10: {
                value: 10,
                text: 'Weak Password',
                background: '#BC1218'
            },
            20: {
                value: 30,
                text: 'Weak Password',
                background: '#BC1218'
            },
            30: {
                value: 50,
                text: 'Can Be Stronger',
                background: '#FFD26D'
            },
            40: {
                value: 80,
                text: 'Can Be Stronger',
                background: '#FFD26D'
            },
            50: {
                value: 100,
                text: 'Strong Password',
                background: '#83D841'
            }
        };

        if (formik?.values?.password) {
            if (formik?.values?.password?.length >= 10) {
                count += 10;
            }
            // ONE UPPERCASE
            if (new RegExp('(?=.*[A-Z])').test(formik?.values?.password)) {
                count += 10;
            }
            // ONE LOWERCASE
            if (new RegExp('(?=.*[a-z].*[a-z].*[a-z])').test(formik?.values?.password)) {
                count += 10;
            }

            // ONE NUMBER
            if (new RegExp('(?=.*[0-9])').test(formik?.values?.password)) {
                count += 10;
            }

            // ONE SPECIAL CHARACTER
            if (new RegExp('(?=.*[#?!@$%^&<>*~.:+`-])').test(formik?.values?.password)) {
                count += 10;
            }
        }

        if (count) {
            setPasswordStrengthDetails(passwordStrengthResponse[count]);
        }
    }, [formik?.values?.password]);

    // USER SOCIAL SIGN UP VALUE FILLED
    const userSocialSignUpValuesFilled = useCallback(
        (values) => {
            setUserValueFilledBySocial(true);
            setFieldValue('firstName', values?.firstName || '');
            setFieldValue('lastName', values?.lastName || '');
            setFieldValue('email', values?.email || '');
            setFieldValue('socialId', values?.socialId || '');
            setFieldValue('socialType', values?.socialType || '');
            setFieldValue('emailVerified', values?.emailVerified || false);
            setSignUpChoice(true);
        },
        [setFieldValue]
    );

    const clearUserSocialSignUpFilledValues = useCallback(() => {
        setUserValueFilledBySocial(false);
        resetForm();
        setSignUpChoice(false);
    }, [resetForm]);

    const handleBackButton = () => {
        if (signUpChoice) setSignUpChoice(false);
        else navigate(-1);

        clearUserSocialSignUpFilledValues();
    };

    const handlePageDialog = (type) => {
        if (type === PAGE?.PRIVACY_POLICY) setSelectedPageTitle('Privacy Policy');
        if (type === PAGE?.TERMS_AND_CONDITIONS) setSelectedPageTitle('Terms & Conditions');
        setSelectedPageType(type);
        setOpenPageDialog(true);
    };

    useEffect(() => initApple(), []);

    useEffect(() => {
        passwordStrength();
    }, [formik?.values?.password, passwordStrength]);

    useEffect(() => {
        if (location?.state && location?.state?.userSocialSignUpValues) {
            userSocialSignUpValuesFilled(location?.state?.userSocialSignUpValues);
        }
    }, [location?.state, userSocialSignUpValuesFilled]);

    useEffect(() => {
        return () => {
            clearUserSocialSignUpFilledValues();
        };
    }, [clearUserSocialSignUpFilledValues]);

    return (
        <>
            <SignupWrapper>
                <TaoLoader isLoading={isLoading} />
                <div className="container">
                    <div className="signup-container">
                        <div className="margin-bottom-spacing flex f-v-center top-margin">
                            <div className="arrow-icon">
                                <ArrowBackIosIcon
                                    onClick={() => handleBackButton()}
                                    style={{ color: '#848484', cursor: 'pointer' }}
                                />
                            </div>
                            <div>
                                <Typography className="title">Create Your Account</Typography>
                            </div>
                        </div>

                        {!signUpChoice && (
                            <SlickDotSlider>
                                {SliderList.map((item, index) => (
                                    <Typography className="slide-text" key={index}>
                                        {item?.title}
                                    </Typography>
                                ))}
                            </SlickDotSlider>
                        )}
                        <form
                            onSubmit={formik.handleSubmit}
                            style={{ display: `${signUpChoice ? 'block' : 'none'}` }}>
                            <div>
                                <div className="margin-bottom-spacing flex f-h-space-between">
                                    <div className="text-field-container">
                                        <OutlinedInput
                                            className="full-width"
                                            style={{ backgroundColor: 'white' }}
                                            placeholder="First Name"
                                            onChange={(e) =>
                                                formik.setFieldValue(
                                                    'firstName',
                                                    e?.target?.value.replace(USER_NAME_REGEX, '')
                                                )
                                            }
                                            key={'firstName'}
                                            name="firstName"
                                            value={formik.values.firstName}
                                        />
                                        {formik?.touched?.firstName && formik.errors.firstName && (
                                            <FormHelperText error={true}>
                                                {formik.errors.firstName}
                                            </FormHelperText>
                                        )}
                                    </div>

                                    <div
                                        style={{ marginLeft: '10px' }}
                                        className="text-field-container">
                                        <OutlinedInput
                                            className="full-width"
                                            style={{ backgroundColor: 'white' }}
                                            onChange={(e) =>
                                                formik.setFieldValue(
                                                    'lastName',
                                                    e?.target?.value.replace(USER_NAME_REGEX, '')
                                                )
                                            }
                                            placeholder="Last Name"
                                            key={'lastName'}
                                            name="lastName"
                                            value={formik.values.lastName}
                                        />
                                        {formik?.touched?.lastName && formik.errors.lastName && (
                                            <FormHelperText error={true}>
                                                {formik.errors.lastName}
                                            </FormHelperText>
                                        )}
                                    </div>
                                </div>

                                <div className="margin-bottom-spacing">
                                    <OutlinedInput
                                        style={{ width: '100%', backgroundColor: 'white' }}
                                        placeholder="Email address"
                                        onChange={formik.handleChange}
                                        disabled={userValueFilledBySocial && gettingEmailInSocial}
                                        key={'email'}
                                        name="email"
                                        value={formik.values.email}
                                    />
                                    {formik?.touched?.email && formik.errors.email && (
                                        <FormHelperText error={true}>
                                            {formik.errors.email}
                                        </FormHelperText>
                                    )}
                                </div>

                                {!userValueFilledBySocial && (
                                    <>
                                        <div className="margin-bottom-spacing">
                                            <OutlinedInput
                                                style={{ width: '100%', backgroundColor: 'white' }}
                                                type={passwordVisible ? 'text' : 'password'}
                                                placeholder="Password"
                                                onChange={(e) => {
                                                    formik.setFieldTouched('password');
                                                    formik.handleChange(e);
                                                }}
                                                key={'password'}
                                                name="password"
                                                value={formik.values.password}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() =>
                                                                setPasswordVisible(
                                                                    (prevState) => !prevState
                                                                )
                                                            }
                                                            aria-label="toggle password visibility">
                                                            {!passwordVisible ? (
                                                                <VisibilityOff />
                                                            ) : (
                                                                <Visibility />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                            {formik?.touched?.password &&
                                                formik.errors.password && (
                                                    <FormHelperText
                                                        error={true}
                                                        style={{ maxWidth: '430px' }}>
                                                        {formik.errors.password}
                                                    </FormHelperText>
                                                )}
                                        </div>

                                        <Box className="margin-bottom-spacing">
                                            <Box sx={{ width: '100%' }}>
                                                <LinearProgressWithLabel
                                                    value={passwordStrengthDetails}
                                                />
                                            </Box>
                                        </Box>

                                        {formik?.values?.password && !formik?.errors?.password && (
                                            <div className="margin-bottom-spacing">
                                                <OutlinedInput
                                                    style={{
                                                        width: '100%',
                                                        backgroundColor: 'white'
                                                    }}
                                                    type={
                                                        confirmPasswordVisible ? 'text' : 'password'
                                                    }
                                                    placeholder="Confirm Password"
                                                    onChange={(e) => {
                                                        formik.setFieldTouched('confirmPassword');
                                                        formik.handleChange(e);
                                                    }}
                                                    key={'confirmPassword'}
                                                    name="confirmPassword"
                                                    value={formik.values.confirmPassword}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={() =>
                                                                    setConfirmPasswordVisible(
                                                                        (prevState) => !prevState
                                                                    )
                                                                }
                                                                aria-label="toggle password visibility">
                                                                {!confirmPasswordVisible ? (
                                                                    <VisibilityOff />
                                                                ) : (
                                                                    <Visibility />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                                {formik?.touched?.confirmPassword &&
                                                    (formik.errors.confirmPassword &&
                                                    formik.values.confirmPassword ? (
                                                        <FormHelperText
                                                            error={true}
                                                            component={'span'}>
                                                            <Box className="confirm-password-container">
                                                                <CrossIcon />
                                                                <Typography className="text danger">
                                                                    {formik.errors.confirmPassword}
                                                                </Typography>
                                                            </Box>
                                                        </FormHelperText>
                                                    ) : !formik.errors.confirmPassword &&
                                                      formik.values.confirmPassword ? (
                                                        <FormHelperText
                                                            error={false}
                                                            component={'span'}>
                                                            <Box className="confirm-password-container">
                                                                <CheckIcon />
                                                                <Typography className="text success">
                                                                    Password Match!
                                                                </Typography>
                                                            </Box>
                                                        </FormHelperText>
                                                    ) : (
                                                        <>
                                                            <Box className="confirm-password-container">
                                                                <Typography className="text">
                                                                    Password Must Match
                                                                </Typography>
                                                            </Box>
                                                        </>
                                                    ))}
                                                {!formik?.touched?.confirmPassword && (
                                                    <>
                                                        <Box className="confirm-password-container">
                                                            <Typography className="text">
                                                                Password Must Match
                                                            </Typography>
                                                        </Box>
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </>
                                )}

                                <Box className="margin-bottom-spacing">
                                    <Box className="policy-div policy-color">
                                        <Checkbox
                                            classes={{
                                                root: 'checkbox-root'
                                            }}
                                            onChange={formik.handleChange}
                                            key={'termsConditions'}
                                            name="termsConditions"
                                            value={formik.values.termsConditions}
                                        />
                                        <Typography className="policy-color">
                                            I agree to the
                                        </Typography>
                                        <Typography
                                            className="policy-color link"
                                            onClick={() => {
                                                handlePageDialog(PAGE?.TERMS_AND_CONDITIONS);
                                            }}>
                                            Terms and Conditions
                                        </Typography>
                                        <Typography className="policy-color">and</Typography>
                                        <Typography
                                            className="policy-color link"
                                            onClick={() => {
                                                handlePageDialog(PAGE?.PRIVACY_POLICY);
                                            }}>
                                            Privacy Policy
                                        </Typography>
                                    </Box>
                                </Box>

                                <Button
                                    type="submit"
                                    disabled={isLoading}
                                    className="button button-color"
                                    variant="contained">
                                    SIGN UP
                                </Button>
                            </div>
                        </form>

                        <div
                            style={{
                                display: `${signUpChoice ? 'none' : 'block'}`,
                                margin: '40px 0 0 0'
                            }}>
                            <div id="sign-by-mail-btn">
                                <Button
                                    type="button"
                                    className="button button-color"
                                    variant="contained"
                                    onClick={() => setSignUpChoice(true)}>
                                    CONTINUE WITH EMAIL
                                </Button>
                            </div>
                            <div className="sign-up-options-container">
                                <div className="divider-container">
                                    {/* <div className="divider" /> */}
                                    <span style={{ padding: '0 10px' }}>or continue with</span>
                                    {/* <div
                                    className="divider"
                                    style={{ transform: 'matrix(-1, 0, 0, 1, 0, 0)' }}
                                /> */}
                                </div>

                                <div className="social-icon">
                                    <div className="social-background apple-background">
                                        <AppleIcon
                                            style={{ marginBottom: '4px' }}
                                            onClick={() => singInApple()}
                                        />
                                    </div>

                                    <div className="social-background google-background">
                                        <GoogleIcon onClick={() => googleLogin()} />
                                    </div>

                                    <div className="social-background facebook-background">
                                        <FacebookLogin
                                            appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                                            size="small"
                                            textButton=""
                                            autoLoad={false}
                                            icon={<FacebookIcon />}
                                            fields="name,email,picture"
                                            callback={facebookResponse}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!signUpChoice && (
                            <React.Fragment>
                                <div className="login-text-container">
                                    <div className="login-text">
                                        By signing up, you agree to the{' '}
                                        <span
                                            className="login-link"
                                            onClick={() =>
                                                handlePageDialog(PAGE?.TERMS_AND_CONDITIONS)
                                            }>
                                            Terms and Conditions
                                        </span>{' '}
                                        and{' '}
                                        <span
                                            className="login-link"
                                            onClick={() => handlePageDialog(PAGE?.PRIVACY_POLICY)}>
                                            Privacy Policy
                                        </span>
                                        .
                                    </div>
                                </div>

                                <div className="checkbox-container flex">
                                    <Checkbox
                                        disableRipple
                                        defaultChecked
                                        checkedIcon={<CustomCheckedIcon />}
                                        icon={<CustomIcon />}
                                    />
                                    <div className="checkbox-text">
                                        Send me tips and special offers for my wellness.
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                    {openPageDialog && (
                        <Page
                            openPageDialog={openPageDialog}
                            setOpenPageDialog={setOpenPageDialog}
                            selectedPageTitle={selectedPageTitle}
                            selectedPageType={selectedPageType}
                        />
                    )}
                </div>
            </SignupWrapper>
            <Footer
                showWhiteBackground={true}
                topBorderColor="gray"
                isBottomZero={true}
                position="absolute"
            />
        </>
    );
};

const LinearProgressWithLabel = (props) => {
    const { value } = props;
    return (
        <PasswordStrengthWrapper>
            <Box className="progress-bar-wrapper">
                <LinearProgressWrapper
                    sx={{
                        height: 6,
                        borderRadius: 4,
                        backgroundColor: '#fff',
                        border: '1px solid rgba(132, 132, 132, 0.4)'
                    }}
                    classes={{ barColorPrimary: 'bg-color' }}
                    variant="determinate"
                    {...value}
                />
            </Box>
            <Box className="password-strength-text-wrapper">
                <Typography className="password-strength-text">{value?.text}</Typography>
            </Box>
        </PasswordStrengthWrapper>
    );
};

export default Signup;
