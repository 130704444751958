import { Box } from '@mui/material';
import { COLORS, FONTS, responsive } from 'Styles/Constants';
import styled from 'styled-components';
import Flower from 'Assets/images/Signup/sales-page-flower-bg.svg';

export const SalesPageOneWrapper = styled(Box)`
    height: 100%;

    .bg-color {
        background: linear-gradient(180deg, rgba(75, 161, 188, 0) 0%, rgba(75, 161, 188, 0.7) 100%);
        height: 400px;
        position: fixed;
        bottom: 0;
        width: 100%;
    }

    .container {
        width: 50%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -70%);

        ${responsive.TABLET`
            width: 80%;
        `}

        .title {
            color: ${COLORS.PRIMARY};
            font-family: ${FONTS.SECONDARY};
            font-weight: 700;
            font-size: 40px;

            ${responsive.MOBILE`
                font-size: 24px;    
            `}
        }
        .description {
            color: ${COLORS.TEXT_DARK};
            font-family: ${FONTS.PRIMARY};
            font-weight: 500;
            font-size: 24px;

            ${responsive.MOBILE`
                font-size: 18px;    
            `}
        }
    }

    .footer-container {
        position: absolute;
        bottom: 20px;
        transform: translate(-50%, -0%);
        left: 50%;
        z-index: 2;

        .redirection-btn {
            background: rgba(255, 255, 255, 0.9);
            box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
            backdrop-filter: blur(10px);
            border-radius: 12px;
            height: 60px;
            width: 370px;
            color: ${COLORS.PRIMARY_WARM};
            font-size: 16px;
            text-transform: uppercase;
            font-family: ${FONTS.PRIMARY_SEMI_BOLD};

            ${responsive.MOBILE`
                width: 200px;
            `}
        }

        .footer-text {
            color: ${COLORS.WHITE};
            font-family: ${FONTS.PRIMARY};
            font-weight: 700;
            font-size: 18px;
            margin: 10px 0 0;
            text-decoration: underline;

            &:hover {
                cursor: pointer;
            }

            &.divider {
                padding: 0 5px;
            }
        }
    }
    .flower-image {
        background-image: url(${Flower});
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        bottom: 0;
        right: 0;
        height: 250px;
        width: 450px;
        z-index: 1;

        ${responsive.PHABLET`
            height: 150px;
            width: 250px;
        `}
    }
`;
