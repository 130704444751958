import { Box } from '@mui/material';
import { COLORS, FONTS, responsive } from 'Styles/Constants';
import styled from 'styled-components';

export const WebsiteTourWrapper = styled(Box)`
    .tour {
        position: absolute;
        min-height: 700px;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #0000008c;
        z-index: 1000;
        mix-blend-mode: hard-light;

        .tour-starting-box {
            transition: 0.5s all;
            position: absolute;
            border-radius: 50%;
            background-color: gray;
        }
    }

    .content-container {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;

        .mascot {
            position: absolute;
            z-index: 20;
            width: 300px;
            height: 300px;

            ${responsive.PHABLET`
                width: 200px;
                height: 200px;
            `}
        }

        .tour-content {
            transition: 1s all;
            position: absolute;
            background-color: ${COLORS.WHITE};
            border-radius: 20px;
            padding: 20px;
            z-index: 21;

            .polygon {
                transition: 0.5s all;
                background-color: white;
                position: absolute;
                height: 35px;
                width: 35px;
                right: -15px;
            }

            .content {
                height: 100%;
                position: relative;
                overflow: hidden;

                .text {
                    font-size: 24px;

                    ${responsive.TABLET`
                        font-size: 22px;
                    `}
                }

                .footer-buttons {
                    position: relative;
                    top: 40px;

                    ${responsive.TABLET`
                        top: 70px;
                    `}
                    ${responsive.PHABLET`
                        top: 45px;
                    `}
                    ${responsive.MOBILE`
                        top: 20px;
                    `}

                    .button-parent-wrapper {
                        display: flex;
                        justify-content: space-between;
                        .btn {
                            width: 48%;
                            height: 60px;
                            border-radius: 12px;
                            &.hide {
                                visibility: hidden;
                            }
                            &.back {
                                background: #e8e8e8;
                                color: ${COLORS.BLACK};
                            }

                            &.primary {
                                background: linear-gradient(180deg, #cbac69 0%, #b6954f 100%);
                                color: ${COLORS.WHITE};
                            }

                            .font {
                                font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                                font-size: 16px;
                                text-transform: uppercase;

                                ${responsive.TABLET`
                                    font-size: 14px;
                                `}
                                ${responsive.MOBILE`
                                    font-size: 12px;
                                `}
                                &.text-transform {
                                    text-transform: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .close-tour {
        position: fixed;
        bottom: 0;
        z-index: 1001;
        width: 100%;
        display: flex;
        justify-content: center;

        .close-tour-text {
            color: ${COLORS.WHITE};
            font-size: 24px;
        }
    }
`;
