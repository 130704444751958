import styled from 'styled-components';
import { responsive, FONTS, COLORS } from 'Styles/Constants';

export const FooterWrapper = styled.footer`
    position: ${(props) => (props?.position ? props?.position : 'relative')};
    background-color: ${(props) => props.isBottomZero && '#fff'};
    bottom: ${(props) => (props.isBottomZero ? '0px' : '50px')};
    overflow: hidden;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    min-height: 50px;
    z-index: 10;

    display: ${(props) => (props.showFooter ? 'flex !important' : 'none !important')};

    ${responsive.TABLET`
        display: none;
        min-height: 80px;
        height: 80px;
        bottom: 80px;
        border-top: 1px solid #e8e8e8;
        border-top: ${(props) =>
            props.topBorderColor === 'gray'
                ? '1px solid #E8E8E8'
                : 'border-top: 2px solid #ffffff'};
    `}

    .footer-content {
        width: 60%;

        ${responsive.LAPTOP`
            width: 80%;
        `}

        ${responsive.TABLET`
            width: 85%;
        `}
    }

    &.black-background {
        color: #656565;
    }

    &.white-background {
        color: #ffffff;
        background-color: transparent;
    }

    .footer-subContent {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 5%;
        border-top: ${(props) =>
            props.topBorderColor === 'gray'
                ? '1px solid #E8E8E8'
                : 'border-top: 1px solid #ffffff'};

        ${responsive.LAPTOP`
            padding: 0 3%;
        `}

        ${responsive.TABLET`
            display: flex;
            flex-direction: column-reverse;
            justify-content: center;
            align-items: center;
            border-top: none;
        `}

        .links {
            display: flex;
            align-items: center;
            margin: 0 0 0 15px;
        }

        .typo-root {
            font-family: ${FONTS.PRIMARY};
            font-weight: 400;
            font-size: 14px;
            line-height: 27px;
            color: ${(props) => (props?.isWhite ? '#fff' : COLORS.TEXT_DARK)};
            width: max-content;

            ${responsive.MOBILE_325`
                font-size: 12px;
            `}
        }

        .policy-separate {
            margin: 0 5px;
        }

        .icons {
            display: flex;
        }
    }
`;
