import React from 'react';
import ContentLoader from 'react-content-loader';

import { LoaderBase } from './ShimmerEffectLoader.style';

const SETTINGS = {
    SPEED: 2,
    COLOR: {
        BACKGROUND: '#f3f3f3',
        FOREGROUND: '#ecebeb'
    }
};

const ShimmerEffectLoader = (props) => {
    return (
        <ContentLoader
            style={{ ...props?.customStyle }}
            speed={SETTINGS.SPEED}
            width={props?.width}
            height={props?.height}
            viewBox={props?.viewBox}
            backgroundColor={SETTINGS.COLOR.BACKGROUND || props?.backgroundColor}
            foregroundColor={SETTINGS.COLOR.FOREGROUND || props?.foregroundColor}>
            {props?.children}
        </ContentLoader>
    );
};

export const BoxLoader = (props) => {
    let width = props?.isFullWidth ? '100%' : `${props?.width}px`;
    let height = props?.isFullHeight ? '100%' : `${props?.height}px`;

    return (
        <LoaderBase width={width} height={height} style={{ ...props?.baseCustomStyle }}>
            <ShimmerEffectLoader width={width} height={height} {...props}>
                <rect x="0" y="0" rx="0" ry="0" width={width} height={height} />
            </ShimmerEffectLoader>
        </LoaderBase>
    );
};

export const TitleLoader = (props) => {
    let height = props?.isFullHeight ? '100%' : `${props?.height}px`;
    let width = props?.isFullWidth ? '100%' : `${props?.width}px`;

    return (
        <LoaderBase width={width} height={height} style={{ ...props?.baseCustomStyle }}>
            <ShimmerEffectLoader width={width} height={height}>
                <rect
                    x={`${props?.marginLeft || 0}`}
                    y={`${props?.marginTop || 0}`}
                    rx="6"
                    ry="6"
                    width={`${props?.fillWidth}`}
                    height={`${props?.fillHeight}`}
                />
            </ShimmerEffectLoader>
        </LoaderBase>
    );
};

export const WellnessCategoryLoader = (props) => {
    return (
        <LoaderBase width={props?.width} height={props?.height} style={{ ...props?.customStyle }}>
            <ShimmerEffectLoader
                width={props?.width}
                height={props?.height}
                viewBox={`0 0 ${props?.width} ${props?.height}`}>
                <rect
                    x="0"
                    y="0"
                    rx="100"
                    ry="100"
                    width={props?.fillWidth}
                    height={props?.fillHeight}
                />
            </ShimmerEffectLoader>
        </LoaderBase>
    );
};

export const TitleWithProgressBarLoader = (props) => {
    return (
        <LoaderBase
            width={props?.width}
            height={props?.height}
            style={{ ...props?.baseCustomStyle }}>
            <ShimmerEffectLoader width={props?.width} height={props?.height}>
                <rect
                    x={`${props?.marginLeft || 0}`}
                    y="0"
                    rx="4"
                    ry="4"
                    width={`${props?.fillWidth}`}
                    height={`${props?.fillHeight}`}
                />
                <rect
                    x={`${props?.marginLeft || 0}`}
                    y="20"
                    rx="4"
                    ry="4"
                    width={`${props?.fillWidth}`}
                    height={`${props?.fillHeight}`}
                />
            </ShimmerEffectLoader>
        </LoaderBase>
    );
};

export const TitleWithFourProgressBarLoader = (props) => {
    // let height = props?.isFullHeight ? '100%' : `${props?.height}px`;
    let width = props?.isFullWidth ? '100%' : `${props?.width}px`;
    let height = props?.isFullHeight ? '100%' : `${props?.height}px`;
    let fillWidth = props?.isFullWidth ? '100%' : `${props?.fillWidth}`;

    return (
        <LoaderBase width={width} height={height} style={{ ...props?.baseCustomStyle }}>
            <ShimmerEffectLoader width={width} height={height}>
                <rect
                    x={`${props?.marginLeft}`}
                    y="0"
                    rx="4"
                    ry="4"
                    width={`${fillWidth}`}
                    height={`${props?.fillHeight}`}
                />
                <rect
                    x={`${props?.marginLeft}`}
                    y="20"
                    rx="4"
                    ry="4"
                    width={`${fillWidth}`}
                    height={`${props?.fillHeight}`}
                />
                <rect
                    x={`${props?.marginLeft}`}
                    y="40"
                    rx="4"
                    ry="4"
                    width={`${fillWidth}`}
                    height={`${props?.fillHeight}`}
                />
                <rect
                    x={`${props?.marginLeft}`}
                    y="60"
                    rx="4"
                    ry="4"
                    width={`${fillWidth}`}
                    height={`${props?.fillHeight}`}
                />
            </ShimmerEffectLoader>
        </LoaderBase>
    );
};

export const DynamicTitleLoader = (props) => {
    // let height = props?.isFullHeight ? '100%' : `${props?.height}px`;
    let width = props?.isFullWidth ? '100%' : `${props?.width}px`;
    let height = props?.isFullHeight ? '100%' : `${props?.height}px`;
    let fillWidth = props?.isFullWidth ? '100%' : `${props?.fillWidth}`;

    const Bar = () => {
        let barList = [],
            gap = 0;
        for (let index = 0; index < props?.bar; index++) {
            if (index) {
                gap = gap + 20;
            }
            barList.push(
                <rect
                    key={index}
                    x={`${props?.marginLeft}`}
                    y={`${index === 0 ? `0` : gap}`}
                    rx="4"
                    ry="4"
                    width={`${fillWidth}`}
                    height={`${props?.fillHeight}`}
                />
            );
        }
        return barList;
    };

    return (
        <LoaderBase width={width} height={height} style={{ ...props?.baseCustomStyle }}>
            <ShimmerEffectLoader width={width} height={height}>
                <Bar />
            </ShimmerEffectLoader>
        </LoaderBase>
    );
};
