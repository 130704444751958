import React, { useState, useEffect, useMemo } from 'react';
import { Box, Typography, Button, Divider } from '@mui/material';

import AccountInfoIcon from 'Assets/images/Profile/account-info-icon.png';
// import CommunityIcon from 'Assets/images/Profile/community-icon.png'; PHASE 2
import GoalsIcon from 'Assets/images/Profile/goals-icon.png';
import InterestsIcon from 'Assets/images/Profile/interests-icon.png';
import ReferralIcon from 'Assets/images/Profile/referral.png';
// import RewardsIcon from 'Assets/images/Profile/rewards-icon.png'; PHASE 2
import SettingsIcon from 'Assets/images/Profile/settings-icon.png';
// import SubscriptionIcon from 'Assets/images/Profile/subscription-icon.png'; PHASE 2
import Logout from 'Assets/images/Profile/logout-icon.png';

import ProfileView from '../ProfileView/ProfileView';
import ProfileInterest from '../Interest/ProfileInterest';
import ProfileSettings from '../Settings/ProfileSettings';
import ProfileAccountInfo from '../AccountInfo/ProfileAccountInfo';
import Referral from '../Referral/Referral';
import { BREAKPOINTS_VALUE } from 'Styles/Constants';
import { getWindowDimensions } from 'Helpers';
import ProfileGoals from '../Goals/ProfileGoals';
import { logoutUser } from 'Redux/Auth/Actions';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ACCESS_LEVELS_MODULEs, PROFILE_TAB_TYPE } from 'Helpers/Constants';
import { URL_PROFILE } from 'Helpers/Paths';
import Api from 'Helpers/ApiHandler';
import { API_URL } from 'Helpers/Paths';
import { useCallback } from 'react';
import TaoScrollbar from 'Components/common/Scrollbar';
import { DeleteConfirmation } from 'Components/common/Modal/Delete';
import usePermissions from 'Components/common/Permissions';
import { SubscriptionDarkLock } from 'Components/common/SubscriptionLock';
import { showSubscriptionModal } from 'Redux/App/Actions';
import { useSelector } from 'react-redux';

const MENU_INDEXs = {
    [PROFILE_TAB_TYPE.ACCOUNT_INFO]: 0,
    [PROFILE_TAB_TYPE.GOALS]: 1,
    [PROFILE_TAB_TYPE.INTERESTS]: 2,
    [PROFILE_TAB_TYPE.REFERRALS]: 3,
    [PROFILE_TAB_TYPE.SETTINGS]: 4
};

const RightSideArrowIconButton = (props) => {
    return (
        <svg
            width="15"
            height="15"
            viewBox="0 0 15 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.10317 11.3817L0.577148 19.9077L3.01266 22.3433L13.9742 11.3817L3.01266 0.420183L0.577148 2.8557L9.10317 11.3817Z"
                fill="#B08C3D"
            />
        </svg>
    );
};

const ProfileItems = ({
    setMenuDisplay,
    setIsLoading,
    menuDisplay,
    type,
    setSelectedMenu,
    ...props
}) => {
    const API = useMemo(() => new Api(), []);

    const dispatch = useDispatch();
    const location = useLocation();
    const [leftMenuReset, setLeftMenuReset] = useState(true);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const navigate = useNavigate();
    const resetLeftMenuHandler = () => {
        setLeftMenuReset((prev) => !prev);
    };
    const [openLogoutAccountDialog, setOpenLogoutAccountDialog] = useState(false);
    const [permissions, setPermissions] = useState({});
    const accountInfoPermission = usePermissions(ACCESS_LEVELS_MODULEs.ACCOUNT_INFO);
    const goalsPermission = usePermissions(ACCESS_LEVELS_MODULEs.GOALS);
    const interestPermission = usePermissions(ACCESS_LEVELS_MODULEs.INTERESTS);
    const referralPermission = usePermissions(ACCESS_LEVELS_MODULEs.REFERRALS);
    const settingsPermission = usePermissions(ACCESS_LEVELS_MODULEs.SETTINGS);
    const [boxesPositions, setBoxesPositions] = useState([]);
    const [currentSelectedBox, setCurrentSelectedBox] = useState(0);
    const userInfo = useSelector((state) => state.Auth.userInfo);

    const [profileMenuList, setProfileMenuList] = useState([
        {
            profileMenuName: 'View Profile',
            profileMenuIcon: null,
            type: PROFILE_TAB_TYPE.VIEW_PROFILE,
            profileMenuDetails: '',
            component: <ProfileView setIsLoading={setIsLoading} />
        },
        {
            profileMenuName: 'Account Info',
            profileMenuIcon: AccountInfoIcon,
            type: PROFILE_TAB_TYPE.ACCOUNT_INFO,
            profileMenuDetails: 'Personal Info, Password, Public Profile',
            component: <ProfileAccountInfo setIsLoading={setIsLoading} />,
            canAccess: true
        },
        {
            profileMenuName: 'Goals',
            profileMenuIcon: GoalsIcon,
            type: PROFILE_TAB_TYPE.GOALS,
            profileMenuDetails: '',
            component: (
                <ProfileGoals
                    setIsLoading={setIsLoading}
                    resetLeftMenuHandler={resetLeftMenuHandler}
                />
            )
        },
        {
            profileMenuName: 'Interests',
            profileMenuIcon: InterestsIcon,
            type: PROFILE_TAB_TYPE.INTERESTS,
            profileMenuDetails: '',
            component: (
                <ProfileInterest
                    setIsLoading={setIsLoading}
                    resetLeftMenuHandler={resetLeftMenuHandler}
                />
            )
        },
        {
            profileMenuName: 'Referrals',
            profileMenuIcon: ReferralIcon,
            type: PROFILE_TAB_TYPE.REFERRALS,
            profileMenuDetails: 'referral link, Referral rewards',
            component: <Referral setIsLoading={setIsLoading} />
        },

        {
            profileMenuName: 'Settings',
            profileMenuIcon: SettingsIcon,
            type: PROFILE_TAB_TYPE.SETTINGS,
            profileMenuDetails: 'Password, Notifications, Language,...',
            component: <ProfileSettings setIsLoading={setIsLoading} />
        }
    ]);

    const getLeftMenuData = useCallback(async () => {
        const response = await API.get(API_URL.LEFT_MENU);

        if (response) {
            setProfileMenuList((prev) => {
                let arr = [...prev];
                arr.forEach((value, index) => {
                    if (arr[index].type === PROFILE_TAB_TYPE.GOALS) {
                        arr[index].profileMenuDetails = response?.data?.data?.goals.join(', ');
                    } else if (arr[index].type === PROFILE_TAB_TYPE.INTERESTS) {
                        arr[index].profileMenuDetails = response?.data?.data?.interests.join(', ');
                    }
                });
                return arr;
            });
        }
    }, [API]);

    const handleViewMyProfile = () => {
        const queryParams = new URLSearchParams(location?.search);
        const userId = queryParams && queryParams.get('userId');

        if (userId) navigate(URL_PROFILE);
        let data = profileMenuList?.find((item) => item?.type === PROFILE_TAB_TYPE.VIEW_PROFILE);

        location.state.type = data.type;
        setSelectedMenu(data);
        setMenuDisplay((prevState) => !prevState);
    };

    const handleMenuDisplay = (menuValue) => {
        location.state.type = menuValue.type;
        setSelectedMenu(menuValue);
        setMenuDisplay((prevState) => !prevState);
    };

    const logoutCurrentUser = async () => {
        let response = await API.post(API_URL.LOGOUT_DEVICES);
        if (response) {
            dispatch(logoutUser());
        }
    };

    useEffect(() => {
        setPermissions({
            [PROFILE_TAB_TYPE.ACCOUNT_INFO]: accountInfoPermission?.canAccess,
            [PROFILE_TAB_TYPE.GOALS]: goalsPermission?.canAccess,
            [PROFILE_TAB_TYPE.INTERESTS]: interestPermission?.canAccess,
            [PROFILE_TAB_TYPE.REFERRALS]: referralPermission?.canAccess,
            [PROFILE_TAB_TYPE.SETTINGS]: settingsPermission?.canAccess
        });
    }, [
        accountInfoPermission?.canAccess,
        goalsPermission?.canAccess,
        interestPermission?.canAccess,
        referralPermission?.canAccess,
        settingsPermission?.canAccess
    ]);

    useEffect(() => {
        getLeftMenuData();
    }, [getLeftMenuData, leftMenuReset]);

    useEffect(() => {
        let currentType = location?.state?.type;
        if (currentType) {
            let data = profileMenuList?.find((item) => item?.type === currentType);
            setCurrentSelectedBox(MENU_INDEXs[data?.type]);
            setSelectedMenu(data);
            setMenuDisplay(true);
        }
    }, [location?.state?.type, profileMenuList, setSelectedMenu, setMenuDisplay]);

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const boxIds = document?.getElementsByClassName?.('menu-details');
        const idsArr = [];
        for (let i = 0; i < boxIds.length; i++) {
            if (!boxIds[i]?.classList?.['value'].includes?.('logout-margin'))
                idsArr.push(boxIds[i].offsetTop);
        }
        setBoxesPositions(idsArr);
    }, [windowDimensions?.width]);

    return (
        <>
            <Box className="profile-page-menu-list">
                <Box className="profile-title-section">
                    <Typography className="profile-title">Profile</Typography>
                    <Button
                        variant="contained"
                        disabled={
                            windowDimensions.width > BREAKPOINTS_VALUE.TABLET &&
                            type === PROFILE_TAB_TYPE.VIEW_PROFILE
                        }
                        className={`view-my-profile-button ${
                            windowDimensions.width > BREAKPOINTS_VALUE.TABLET &&
                            type === PROFILE_TAB_TYPE.VIEW_PROFILE &&
                            'disabled'
                        }`}
                        classes={{ root: 'profile-btn-root' }}
                        onClick={() => handleViewMyProfile()}>
                        View My Profile
                    </Button>
                </Box>

                <Box className="profile-menu-list">
                    <TaoScrollbar displayScroll>
                        <Box className="profile-view-tablet-section">
                            <Box
                                className="user-profile-image"
                                style={{ backgroundImage: `url(${userInfo?.thumbProfilePicUrl})` }}
                            />
                            <Box className="flex f-column" overflow="hidden">
                                <Typography className="user-name">
                                    {userInfo?.firstName} {userInfo.lastName}
                                </Typography>
                                <Typography className="user-email elipsis">
                                    {userInfo?.email}
                                </Typography>
                            </Box>
                        </Box>
                        {type !== PROFILE_TAB_TYPE.VIEW_PROFILE && (
                            <Box
                                className={`white-box`}
                                style={{
                                    transform: `translate(0,${boxesPositions[currentSelectedBox]}px)`
                                }}>
                                <Box className="border" />
                                <Box className="border lower" />
                            </Box>
                        )}
                        {profileMenuList?.slice(1).map((menuValue, index) => (
                            <React.Fragment key={index}>
                                <Box
                                    className="menu-details"
                                    onClick={
                                        !permissions[menuValue.type]
                                            ? () => dispatch(showSubscriptionModal())
                                            : () => {
                                                  setCurrentSelectedBox(index);
                                                  handleMenuDisplay(menuValue);
                                              }
                                    }>
                                    <img src={menuValue.profileMenuIcon} alt="" />
                                    <Box className="menu-name-details">
                                        <Box className="flex f-v-center">
                                            <Typography className="menu-name">
                                                {menuValue.profileMenuName}
                                            </Typography>
                                        </Box>
                                        <Typography className="menu-description">
                                            {menuValue.profileMenuDetails}
                                        </Typography>
                                    </Box>

                                    {!permissions[menuValue.type] ? (
                                        <SubscriptionDarkLock height={18} />
                                    ) : (
                                        <RightSideArrowIconButton />
                                    )}
                                </Box>
                            </React.Fragment>
                        ))}
                    </TaoScrollbar>
                    {windowDimensions.width >= BREAKPOINTS_VALUE.TABLET && <Divider />}
                    <Box
                        className="menu-details logout-margin"
                        onClick={() => setOpenLogoutAccountDialog(true)}>
                        <img src={Logout} alt="" />
                        <Box className="menu-name-details">
                            <Typography className="menu-name">Logout</Typography>
                        </Box>
                        <RightSideArrowIconButton />
                    </Box>
                </Box>
            </Box>
            {openLogoutAccountDialog && (
                <DeleteConfirmation
                    title="Are you sure you want to logout?"
                    onClose={() => {
                        setOpenLogoutAccountDialog(false);
                    }}
                    onConfirm={() => {
                        logoutCurrentUser();
                    }}
                />
            )}
        </>
    );
};

export default ProfileItems;
