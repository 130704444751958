import { Route } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import {
    URL_HOME_PAGE,
    URL_NOTIFICATIONS,
    URL_WELLNESS_CATEGORY,
    URL_CALENDAR,
    URL_FIELD_HEALING,
    URL_BODY_ORGANS_DETAILS,
    URL_FIELD_HEALING_PLAYER,
    URL_MEDIATION_ROOMS,
    URL_MEDITATION_ROOM_DETAILS,
    URL_MEDITATION_ROOM_SESSION,
    URL_MEDITATION_TIMER,
    URL_PROGRAMS,
    URL_PROGRAMS_DETAILS,
    URL_BOOK_A_CONSULTATION,
    URL_MEDITATION_ROOM_ADD_UPDATE,
    URL_CHAT,
    URL_MEDITATION_CONTENT,
    URL_HOW_TO_MEDITATE,
    URL_SEARCH,
    URL_PROFILE,
    URL_QUESTIONNAIRE_MAIN,
    URL_PLAYLIST,
    URL_PLAY_MEDITATION,
    URL_JOURNAL,
    URL_MEDITATION_MEDIA_PLAYER,
    URL_DOWNLOAD,
    URL_FAVORITES,
    URL_MEDITATION_READ,
    URL_STATISTICS,
    URL_POST_ASSESSMENT,
    URL_HISTORY,
    URL_HOW_TO_MEDITATE_CONTENT,
    URL_HOW_TO_MEDITATE_MEDIA_PLAYER,
    URL_CONTENT_SEE_ALL,
    URL_CATEGORY_SEE_ALL,
    URL_PROFILE_VIEW,
    URL_PROGRAMS_SUGGESTIONS
} from 'Helpers/Paths';
import { HomePage } from 'Components/Pages/HomePage';
import { Notifications } from 'Components/Pages/Notifications';
import { WellnessCategory } from 'Components/Pages/Category';
import { Calendar } from 'Components/Pages/Calendar';
import { FieldHealing } from 'Components/Pages/FieldHealing';
import BodyOrgansDetails from 'Components/Pages/FieldHealing/BodyOrgansDetails';
import FieldHealingPlayer from 'Components/Pages/FieldHealing/FieldHealingPlayer';
import { MeditationTimer } from 'Components/Pages/MeditationTimer';

import { MeditationRoom } from 'Components/Pages/MeditationRooms';
import { MeditationRoomDetail } from 'Components/Pages/MeditationRooms';

import MeditationRoomMain from 'Components/Pages/MeditationRoomSession';
import { MeditationRoomAddEditSession } from 'Components/Pages/MeditationRoomSession/AddEditSession';
import { AudioSessionParticipantView } from 'Components/Pages/MeditationRoomSession/AudioSession/ParticipantView';
import { AudioSessionHostView } from 'Components/Pages/MeditationRoomSession/AudioSession/HostView';
import { AddUpdateRoom } from 'Components/Pages/MeditationRooms';
import { Programs } from 'Components/Pages/Programs';
import Journal from 'Components/Pages/Journal/Journal';
import ProgramsMain from 'Components/Pages/Programs/ProgramsMain';

import { BookAConsultation } from 'Components/Pages/BookAConsultation';
import { Chat } from 'Components/Pages/Chat';
import QuestionnaireLayOut from 'Components/Pages/Questionnaire';
import QuestionnaireResult from 'Components/Pages/Questionnaire/QuestionnaireResult';
import QuestionnaireQuestions from 'Components/Pages/Questionnaire/Questions';
import { HowToMeditate } from 'Components/Pages/HowToMeditate';
import { Search } from 'Components/Pages/Search';
import MeditationMediaPlayer from 'Components/Pages/MeditationContent/Media/MeditationMediaPlayer';
import { Downloads } from 'Components/Pages/Downloads';
import { Favorites } from 'Components/Pages/Favorites';
import MeditationRead from 'Components/Pages/MeditationContent/ReadContent/MeditationRead';
import { Statistics } from 'Components/Pages/Statistics';
import { Profile } from 'Components/Pages/Profile';
import ShowProfileView from 'Components/Pages/Profile/ShareProfileView/ShareProfileView';
import { Playlist } from 'Components/Pages/PlayList';
import PlayMeditationList from 'Components/Pages/PlayList/PlayMeditationList/PlayMeditationList';
import { PostAssessment } from 'Components/common/PostAssessment';
import { History } from 'Components/Pages/History';
import { MeditationContentPhase } from 'Components/Pages/MeditationContent';
import { ContentSeeAll } from 'Components/common/SeeAll';
import CategorySeeAll from 'Components/common/SeeAll/CategorySeeAll/CategorySeeAll';
import { ProgramSuggestion } from 'Components/common/ProgramSuggestion';

const RoutesList = [
    {
        path: '/',
        exact: true,
        Component: <HomePage />
    },

    {
        path: URL_HOME_PAGE,
        exact: true,
        Component: <HomePage />
    },
    {
        path: URL_NOTIFICATIONS,
        exact: true,
        Component: <Notifications />
    },
    {
        path: URL_WELLNESS_CATEGORY,
        exact: true,
        Component: <WellnessCategory />
    },
    {
        path: URL_CALENDAR,
        exact: true,
        Component: <Calendar />
    },
    {
        path: URL_FIELD_HEALING,
        exact: true,
        Component: <FieldHealing />
    },
    {
        path: URL_BODY_ORGANS_DETAILS,
        exact: true,
        Component: <BodyOrgansDetails />
    },
    {
        path: URL_FIELD_HEALING_PLAYER,
        exact: true,
        Component: <FieldHealingPlayer />
    },
    {
        path: URL_MEDIATION_ROOMS,
        exact: true,
        Component: <MeditationRoom />
    },
    {
        path: URL_MEDITATION_ROOM_DETAILS + '/:roomId',
        Component: <MeditationRoomDetail />
    },
    {
        path: URL_QUESTIONNAIRE_MAIN,
        exact: true,
        hasChildren: true,
        Component: <QuestionnaireLayOut />,
        children: [
            <Route
                index
                key={1}
                element={
                    <ProtectedRoute>
                        <QuestionnaireQuestions />
                    </ProtectedRoute>
                }
            />,
            <Route
                path={URL_QUESTIONNAIRE_MAIN + '/questions'}
                key={2}
                element={
                    <ProtectedRoute>
                        <QuestionnaireQuestions />
                    </ProtectedRoute>
                }
            />,
            <Route
                key={3}
                path={URL_QUESTIONNAIRE_MAIN + '/result'}
                element={
                    <ProtectedRoute>
                        <QuestionnaireResult />
                    </ProtectedRoute>
                }
            />
        ]
    },
    {
        path: URL_MEDITATION_ROOM_SESSION,
        exact: true,
        hasChildren: true,
        Component: <MeditationRoomMain />,
        children: [
            <Route
                index
                key={1}
                element={
                    <ProtectedRoute>
                        <MeditationRoomAddEditSession />
                    </ProtectedRoute>
                }
            />,
            <Route
                path={URL_MEDITATION_ROOM_SESSION + '/create'}
                key={2}
                element={
                    <ProtectedRoute>
                        <MeditationRoomAddEditSession editMeditationRoomSession={false} />
                    </ProtectedRoute>
                }
            />,
            <Route
                path={URL_MEDITATION_ROOM_SESSION + '/edit'}
                key={3}
                element={
                    <ProtectedRoute>
                        <MeditationRoomAddEditSession editMeditationRoomSession />
                    </ProtectedRoute>
                }
            />,
            <Route
                path={URL_MEDITATION_ROOM_SESSION + '/participant-view'}
                key={4}
                element={
                    <ProtectedRoute>
                        <AudioSessionParticipantView />
                    </ProtectedRoute>
                }
            />,
            <Route
                path={URL_MEDITATION_ROOM_SESSION + '/host-view'}
                key={5}
                element={
                    <ProtectedRoute>
                        <AudioSessionHostView />
                    </ProtectedRoute>
                }
            />
        ]
    },
    {
        path: URL_MEDITATION_TIMER,
        exact: true,
        Component: <MeditationTimer />
    },
    {
        path: URL_MEDITATION_ROOM_ADD_UPDATE,
        Component: <AddUpdateRoom />
    },
    {
        path: URL_PROGRAMS,
        exact: true,
        Component: <ProgramsMain />
    },
    {
        path: URL_PROGRAMS_DETAILS,
        exact: true,
        Component: <Programs />
    },
    {
        path: URL_BOOK_A_CONSULTATION,
        exact: true,
        Component: <BookAConsultation />
    },
    {
        path: URL_CHAT,
        exact: true,
        Component: <Chat />
    },
    {
        path: URL_MEDITATION_CONTENT,
        exact: true,
        Component: <MeditationContentPhase />
    },
    {
        path: URL_CONTENT_SEE_ALL,
        exact: true,
        Component: <ContentSeeAll />
    },
    {
        path: URL_CATEGORY_SEE_ALL,
        exact: true,
        Component: <CategorySeeAll />
    },
    {
        path: URL_HOW_TO_MEDITATE,
        exact: true,
        Component: <HowToMeditate />
    },
    {
        path: URL_HOW_TO_MEDITATE_CONTENT,
        exact: true,
        Component: <MeditationContentPhase />
    },
    {
        path: URL_SEARCH,
        exact: true,
        Component: <Search />
    },
    {
        path: URL_MEDITATION_MEDIA_PLAYER,
        exact: true,
        Component: <MeditationMediaPlayer />
    },
    {
        path: URL_HOW_TO_MEDITATE_MEDIA_PLAYER,
        exact: true,
        Component: <MeditationMediaPlayer />
    },
    {
        path: URL_DOWNLOAD,
        exact: true,
        Component: <Downloads />
    },
    {
        path: URL_PLAYLIST,
        exact: true,
        Component: <Playlist />
    },
    {
        path: URL_PLAY_MEDITATION,
        exact: true,
        Component: <PlayMeditationList />
    },
    {
        path: URL_FAVORITES,
        exact: true,
        Component: <Favorites />
    },
    {
        path: URL_JOURNAL,
        exact: true,
        Component: <Journal />
    },
    {
        path: URL_MEDITATION_READ,
        exact: true,
        Component: <MeditationRead />
    },
    {
        path: URL_PROFILE,
        exact: true,
        Component: <Profile />
    },
    {
        path: URL_PROFILE_VIEW,
        exact: true,
        Component: <ShowProfileView />
    },
    {
        path: URL_STATISTICS,
        exact: true,
        Component: <Statistics />
    },
    {
        path: URL_POST_ASSESSMENT,
        exact: true,
        Component: <PostAssessment />
    },
    {
        path: URL_HISTORY,
        exact: true,
        Component: <History />
    },
    {
        path: URL_PROGRAMS_SUGGESTIONS,
        exact: true,
        Component: <ProgramSuggestion />
    }
];

export default RoutesList;
