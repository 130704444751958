import { Typography, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideSideBar, showSideBar } from 'Redux/Sidebar/Actions';
import { handleTopBarInteractionDetail, hideTopBarInteractionButton } from 'Redux/App/Actions';
import { ContentPhaseWrapper } from './PhasesMain.style';

import Marquee from 'react-fast-marquee';

import { useLocation, useNavigate } from 'react-router-dom';
import Api from 'Helpers/ApiHandler';
import {
    API_URL,
    URL_MEDITATION_MEDIA_PLAYER,
    URL_MEDITATION_READ,
    URL_HOW_TO_MEDITATE_MEDIA_PLAYER
} from 'Helpers/Paths';
import { useCallback } from 'react';
import TaoLoader from 'Components/common/TaoBackdropLoader';

import { MEDIA_TYPE, PHASES } from 'Helpers/Constants';
import { showUserMenu } from 'Redux/App/Actions';
import PreAssessment from './PreAssessment/PreAssessment';
import ContentPhasesDetails from './ContentDetails/ContentDetails';
import { getWindowDimensions } from 'Helpers';
import { BREAKPOINTS_VALUE } from 'Styles/Constants';
import PurchaseContentModal from 'Components/common/Modal/Purchase/Purchase';
import ButtonState from '../ButtonStates/ButtonState';
import WebsiteTour from '../WebsiteTour/WebsiteTour';

const MeditationContentPhase = () => {
    const [currentPhase, setCurrentPhase] = useState(PHASES.BUY);
    const [enableTour, setTourEnable] = useState(false);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const marqueeContent = useRef(null);
    const marqueeDiv = useRef(null);
    const [loop, setLoop] = useState(
        marqueeContent.current?.clientWidth > marqueeDiv.current?.clientWidth ? 0 : 1
    );

    const [showPurchaseModal, setPurchaseModal] = useState(false);
    const [contentDetails, setContentDetails] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    const API = useMemo(() => new Api(), []);
    const queryParams = new URLSearchParams(location?.search);
    const linkContentId = queryParams && queryParams?.get?.('contentId');
    const navigate = useNavigate();

    let containerRef = useRef(null);

    const dispatch = useDispatch();

    const openPurchaseModal = () => {
        setPurchaseModal(true);
    };

    const closePurchaseModal = () => {
        setPurchaseModal(false);
    };

    const onPurchase = () => {
        setCurrentPhase(PHASES.LISTEN);
        closePurchaseModal();
    };

    const redirectToPlayer = () => {
        let redirectUrl = null;

        if (contentDetails?.type === MEDIA_TYPE?.TEXT) {
            redirectUrl = URL_MEDITATION_READ;
        } else {
            if (contentDetails?.isInstructional) {
                redirectUrl = URL_HOW_TO_MEDITATE_MEDIA_PLAYER;
            } else {
                redirectUrl = URL_MEDITATION_MEDIA_PLAYER;
            }
        }

        navigate(redirectUrl, {
            state: {
                contentId: location?.state?.contentId || linkContentId,
                programContentId: location?.state?.programContentId
            }
        });
    };

    const fetchContentById = useCallback(async () => {
        if (!location?.state?.contentId && !linkContentId) return;
        setIsLoading(true);
        let response = await API.get(API_URL.CONTENT_LIST, {
            params: {
                contentId: location?.state?.contentId || linkContentId
            }
        });

        if (response) {
            setContentDetails(response?.data?.data);
            setTourEnable(!response?.data?.data?.isInstructional);
            dispatch(handleTopBarInteractionDetail(response?.data?.data || {}));
        }

        if (
            response?.data?.data?.isPaidContent &&
            !response?.data?.data?.isPurchased &&
            !response?.data?.data?.isSubscribed
        ) {
            setCurrentPhase(PHASES.BUY);
            dispatch(hideTopBarInteractionButton(true));
        } else {
            setCurrentPhase(PHASES.LISTEN);
        }

        setIsLoading(false);
    }, [API, location?.state?.contentId, linkContentId, dispatch]);

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions());
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setLoop(marqueeContent.current?.clientWidth > marqueeDiv.current?.clientWidth ? 0 : 1);
    }, [marqueeContent.current?.clientWidth, marqueeDiv.current?.clientWidth]);

    useEffect(() => {
        dispatch(hideSideBar());
        fetchContentById();

        return () => {
            dispatch(showUserMenu({ show: false }));
            dispatch(showSideBar());
            dispatch(handleTopBarInteractionDetail({}));
            dispatch(hideTopBarInteractionButton(false));
        };
    }, [dispatch, fetchContentById]);

    return (
        <ContentPhaseWrapper ref={containerRef}>
            {enableTour && <WebsiteTour isLoading={isLoading} setTourEnable={setTourEnable} />}
            <TaoLoader isLoading={isLoading} />
            <PurchaseContentModal
                open={showPurchaseModal}
                onClose={closePurchaseModal}
                onPurchase={onPurchase}
                {...contentDetails}
            />

            <Box className="image-container">
                <Box
                    className="bg-image"
                    style={{ backgroundImage: `url(${contentDetails?.backgroundImageWeb})` }}
                />
                <Box className="dark-shadow" />
                <Box className="text-container">
                    {currentPhase !== PHASES.PRE_ASSESSMENT && (
                        <>
                            <Box className="flex text-content" style={{ alignItems: 'center' }}>
                                <Box
                                    className="title-container"
                                    sx={{ width: '160px' }}
                                    ref={marqueeDiv}>
                                    <Marquee gradient={false} speed={60} delay={2} loop={loop}>
                                        <Tooltip
                                            title={contentDetails?.title || ''}
                                            placement="top">
                                            <Box className="marquee-child" ref={marqueeContent}>
                                                <Typography className="title">
                                                    {contentDetails?.title}
                                                </Typography>
                                            </Box>
                                        </Tooltip>
                                    </Marquee>

                                    {contentDetails?.authorName && (
                                        <Typography className="description">
                                            with {contentDetails?.authorName}
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                        </>
                    )}
                </Box>

                <Box className="details-container">
                    {currentPhase === PHASES.PRE_ASSESSMENT
                        ? !isLoading && (
                              <PreAssessment
                                  contentDetails={contentDetails}
                                  setIsLoading={setIsLoading}
                                  redirectToPlayer={redirectToPlayer}
                                  contentId={location?.state?.contentId || linkContentId}
                              />
                          )
                        : !isLoading && (
                              <ContentPhasesDetails
                                  contentDetails={contentDetails}
                                  currentPhase={currentPhase}
                                  PHASES={PHASES}
                                  redirectToPlayer={redirectToPlayer}
                                  setCurrentPhase={setCurrentPhase}
                                  openPurchaseModal={openPurchaseModal}
                              />
                          )}
                </Box>
            </Box>
        </ContentPhaseWrapper>
    );
};

export default MeditationContentPhase;
