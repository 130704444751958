import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
    ReferralWrapper,
    YourReferralWrapper,
    YourReferralCardWrapper,
    ListWrapper
} from './Referral.style';
import { Box, Typography, Input } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import ShareSocial from 'Components/common/Share/Share';
import Api from 'Helpers/ApiHandler';
import { API_URL, URL_PROFILE_VIEW } from 'Helpers/Paths';
import ProfileImagePlaceholder from 'Assets/images/Profile/user-without-bg.svg';
import TaoScrollbar from 'Components/common/Scrollbar';
import moment from 'moment';
import { useNavigate, createSearchParams } from 'react-router-dom';

const PER_PAGE = 50;

const YourReferralCard = ({ profilePicUrl, name, joinDate, userDetail, ...props }) => {
    const navigate = useNavigate();

    const handleUserProfile = (userId) => {
        if (!userId) return;

        return navigate({
            pathname: URL_PROFILE_VIEW,
            search: `?${createSearchParams({
                userId: userId
            })}`
        });
    };

    return (
        <YourReferralCardWrapper>
            <Box className="user-wrapper" onClick={() => handleUserProfile(userDetail?.userId)}>
                <img className="user-image" src={profilePicUrl || ProfileImagePlaceholder} alt="" />
            </Box>
            <Box className="user-information-wrapper">
                <Typography className="user-name elipsis">{`${name}`}</Typography>
                <Typography className="user-join-date elipsis">
                    {moment(joinDate).format('MMM DD YYYY')}
                </Typography>
            </Box>
        </YourReferralCardWrapper>
    );
};

const YourReferral = ({ items = [], totalRecords = 0, currentPage = 0, getUserReferralList }) => {
    const loadMoreItems = () => {
        getUserReferralList(currentPage);
    };

    return (
        <>
            <YourReferralWrapper>
                <Box className="your-referral-wrapper">
                    {
                        <Typography className="your-referral-header">{`Your Referrals ${
                            totalRecords ? `(${totalRecords})` : ''
                        }`}</Typography>
                    }

                    {items?.length > 0 && (
                        <ListWrapper id="scrollableDiv">
                            <InfiniteScroll
                                dataLength={items.length}
                                next={loadMoreItems}
                                hasMore={items?.length <= totalRecords}
                                className="your-referral-list-wrapper"
                                scrollableTarget="scrollableDiv">
                                {items?.map((value, index) => (
                                    <React.Fragment key={index}>
                                        <YourReferralCard
                                            profilePicUrl={value?.profilePicUrl}
                                            name={value?.name}
                                            joinDate={value?.joinDate}
                                            userDetail={value}
                                        />
                                    </React.Fragment>
                                ))}
                            </InfiniteScroll>
                        </ListWrapper>
                    )}

                    {!items?.length && (
                        <>
                            <Typography className="placeholder-title">
                                You don’t have any referrals yet.
                            </Typography>
                        </>
                    )}
                </Box>
            </YourReferralWrapper>
        </>
    );
};

const Referral = (props) => {
    const API = useMemo(() => new Api(), []);
    const [referralUsersList, setReferralUsersList] = useState({
        list: [],
        totalRecords: 0,
        currentPage: 0,
        referralLink: ''
    });

    const getUserReferralList = useCallback(
        async (current_page = 0) => {
            props?.setIsLoading(true);

            let response = await API.post(API_URL.REFERRAL_LIST, {
                data: {
                    current_page,
                    per_page: PER_PAGE,
                    search: ''
                }
            });

            if (response?.data?.data) {
                if (response?.data?.data?.referralUsersList) {
                    let responseReferralUsersList = response?.data?.data?.referralUsersList || {};

                    setReferralUsersList((prev) => ({
                        list: [...prev?.list, ...responseReferralUsersList?.list],
                        totalRecords: responseReferralUsersList?.totalRecords,
                        currentPage: prev?.currentPage + 1,
                        referralLink: response?.data?.data?.referralLink
                    }));
                }
            }

            props?.setIsLoading(false);
        },
        [API, props]
    );

    useEffect(() => {
        getUserReferralList();
    }, [getUserReferralList]);

    return (
        <ReferralWrapper>
            <TaoScrollbar customStyle={{ borderRadius: '30px 30px 0 0' }} displayScroll>
                <Box className="referral-link-container">
                    <Box className="color-container" />
                    <Typography className="referral-link-header">Your Referral Link</Typography>
                    <Box className="share-content-wrapper">
                        <Box className="share-container">
                            <Input
                                value={referralUsersList?.referralLink || ''}
                                disableUnderline
                                disabled
                                classes={{ input: 'input' }}
                                className="share-link-input"
                                variant="filled"
                                placeholder="xxxx://xxxx.xxx/xxxx/xx"
                            />

                            <ShareSocial
                                ButtonComponent={
                                    <Box className="share-btn-container flex f-v-center">
                                        <Typography className="share-btn-text">SHARE</Typography>
                                    </Box>
                                }
                                title="Sign up Test with referral"
                                url={referralUsersList?.referralLink}
                                callFetchShorterLink={false}
                                disableRipple={true}
                            />
                        </Box>
                        <Box className="share-information-wrapper">
                            <Typography className="share-information-text">
                                Share this with your contacts to invite them to use this App. Earn
                                rewards when they sign up!
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <YourReferral
                    items={referralUsersList?.list}
                    totalRecords={referralUsersList?.totalRecords}
                    getUserReferralList={getUserReferralList}
                    currentPage={referralUsersList?.currentPage}
                />
            </TaoScrollbar>
        </ReferralWrapper>
    );
};

export default Referral;
