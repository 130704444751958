import React, { useMemo, useState } from 'react';
import { Typography, Box, Button } from '@mui/material';
import RatingFirstAnimation from 'Assets/AnimationFiles/Rating/MascotRating01.json';
import RatingSecondAnimation from 'Assets/AnimationFiles/Rating/MascotRating02.json';
import RatingThirdAnimation from 'Assets/AnimationFiles/Rating/MascotRating03.json';
import RatingFourAnimation from 'Assets/AnimationFiles/Rating/MascotRating04.json';
import RatingFiveAnimation from 'Assets/AnimationFiles/Rating/MascotRating05.json';

// Emoji SVG
import {
    RatingEmoji6,
    RatingEmoji7,
    RatingEmoji8,
    RatingEmoji9,
    RatingEmoji10
} from '../RatingEmoji';
import Lottie from 'lottie-react';
import { TaoButton } from 'Components/common/Button.style';
import StandingDollAnimation from 'Assets/AnimationFiles/Questionnaire/MascotStandingIdleLoop.json';
import Api from 'Helpers/ApiHandler';
import { API_URL } from 'Helpers/Paths';

const MOOD = [
    { type: 1, icon: RatingEmoji6, animationFile: RatingFirstAnimation },
    { type: 2, icon: RatingEmoji7, animationFile: RatingSecondAnimation },
    { type: 3, icon: RatingEmoji8, animationFile: RatingThirdAnimation },
    { type: 4, icon: RatingEmoji9, animationFile: RatingFourAnimation },
    { type: 5, icon: RatingEmoji10, animationFile: RatingFiveAnimation }
];

const PreAssessment = ({ contentDetails, setIsLoading, redirectToPlayer, contentId, ...props }) => {
    const [animationFile, setAnimationFile] = useState(StandingDollAnimation);
    const API = useMemo(() => new Api(), []);
    const [selectedRating, setSelectedRating] = useState(0);

    const submitMood = async () => {
        setIsLoading(true);
        let response = await API.post(API_URL.MOOD_PRE_FEEDBACK, {
            params: {
                id: contentId,

                ratingNumber: selectedRating
            }
        });
        if (response) {
            setIsLoading(false);
            redirectToPlayer();
        }
    };

    return (
        <React.Fragment>
            <Box className="mascot flex">
                <Lottie loop autoplay animationData={animationFile} className="lottie-animation" />
            </Box>
            <Box className="assessment-content">
                <Typography className="title">You and your wellness matter to us!</Typography>

                <Typography className="description">
                    {contentDetails?.ratingDetails?.ratingQuestion}
                </Typography>

                <Box className="flex f-column">
                    <Box>
                        {MOOD.map((item) => (
                            <item.icon
                                key={item?.type}
                                onClick={() => {
                                    setSelectedRating(item?.type);
                                    setAnimationFile(item?.animationFile);
                                }}
                                fill={selectedRating === item?.type}
                                className={`rating-margin hover ${
                                    selectedRating &&
                                    selectedRating !== item?.type &&
                                    'not-selected'
                                }`}
                            />
                        ))}
                    </Box>

                    <Box className="flex f-h-space-between">
                        <Typography className="pain-text">
                            {contentDetails?.ratingDetails?.ratingWorst}
                        </Typography>
                        <Typography className="pain-text">
                            {contentDetails?.ratingDetails?.ratingBest}
                        </Typography>
                    </Box>

                    <Box className="flex f-column f-h-center" style={{ marginTop: '20px' }}>
                        <TaoButton disabled={!selectedRating} onClick={() => submitMood()}>
                            <Typography>{'Save & Start'}</Typography>
                        </TaoButton>

                        <Button
                            variant="text"
                            style={{ marginTop: '10px' }}
                            onClick={() => submitMood()}>
                            <Typography className="skip-text">{'Skip for now'}</Typography>
                        </Button>
                    </Box>
                </Box>
            </Box>
        </React.Fragment>
    );
};

export default PreAssessment;
