import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import {
    QuestionnaireWrapper,
    WavesAnimationWrapper,
    QuestionFooterWrapper
} from './Questions.style';
import Questionnaire_birds from 'Assets/images/Questionnaire/questionnaire-birds.png';
import Questionnaire_Clouds from 'Assets/images/Questionnaire/questionnaire-clouds.png';

import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { CSSTransition } from 'react-transition-group';

import WelcomeUser from './WelcomeUser';
import UserSelection from './UserChoices';
import { TaoButton } from 'Components/common/Button.style';

import { BREAKPOINTS_VALUE } from 'Styles/Constants';
import {
    showTopBarWithTransition,
    hideTopBarWithTransition,
    showFooter,
    hideFooter,
    showToast,
    showTopBar
} from 'Redux/App/Actions';
import { showSideBar, hideSideBar } from 'Redux/Sidebar/Actions';
import { API_URL, URL_QUESTIONNAIRE_MAIN_RESULT } from 'Helpers/Paths';
import Lottie from 'lottie-react';
import * as wavesAnimation from 'Assets/AnimationFiles/wavy-lines-animation.json';

import TaoCalligraphyWatermark from 'Components/common/TaoCalligraphyWatermark';

import { getWindowDimensions } from 'Helpers';
import Api from 'Helpers/ApiHandler';
import CODES from 'Helpers/StatusCodes';
import { cloneDeep } from 'lodash';
import { logoutUser, updateUserInfo } from 'Redux/Auth/Actions';
import TaoLoader from 'Components/common/TaoBackdropLoader';
import DollAnimationStandingIdle from 'Assets/AnimationFiles/Questionnaire/MascotStandingIdleLoop.json';
import DollAnimationStandingToLevitating from 'Assets/AnimationFiles/Questionnaire/MascotStandingToLevitating.json';
import DollAnimationEyesClosed from 'Assets/AnimationFiles/Questionnaire/MascotLevitatingLoopEyesClosed.json';

function initCloudPosition() {
    const windowDimensions = getWindowDimensions();
    if (windowDimensions.width < BREAKPOINTS_VALUE.PHABLET) {
        return -193;
    } else if (windowDimensions.width < BREAKPOINTS_VALUE.LAPTOP) {
        return -640;
    } else if (windowDimensions.width < BREAKPOINTS_VALUE.DESKTOP) {
        return -600;
    } else {
        return -540;
    }
}

function initBirdPosition() {
    const windowDimensions = getWindowDimensions();
    if (windowDimensions.width < BREAKPOINTS_VALUE.LAPTOP) {
        return -42;
    } else if (windowDimensions.width < BREAKPOINTS_VALUE.DESKTOP) {
        return -39;
    } else if (windowDimensions.width < BREAKPOINTS_VALUE.DISPLAY) {
        return -35;
    } else {
        return -30;
    }
}

const Questionnaire = (props) => {
    const API = useMemo(() => new Api(), []);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const welcomeRef = useRef();
    const goalsRef = useRef();
    const achievementsRef = useRef();
    const feelingsRef = useRef();
    const modalitiesRef = useRef();
    const regionRef = useRef();
    const languagesRef = useRef();
    const ageRef = useRef();
    const genderRef = useRef();

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [cloudPosition, setCloudPosition] = useState(initCloudPosition());
    const [birdsPosition, setBirdPosition] = useState(initBirdPosition());
    const [isLoading, setIsLoading] = useState(false);
    const [questionnaireData, setQuestionnaireData] = useState([]);

    const userDetails = useSelector((state) => state.Auth.userInfo);

    const [questionNum, setQuestionNum] = useState(0);
    const [btnContainerMargin, setBtnContainerMargin] = useState(0);
    const [btnContainerWidth, setBtnContainerWidth] = useState(0);

    const QUESTION_BY_CATEGORY = [
        {
            questionNumber: 0,
            ref: welcomeRef
        },
        {
            questionNumber: 1,
            ref: goalsRef,
            className: 'large-container',
            initAnimation: DollAnimationStandingIdle
        },
        {
            questionNumber: 2,
            ref: achievementsRef,
            className: 'large-container',
            largeChip: true,
            initAnimation: DollAnimationStandingToLevitating,
            repeatAnimation: DollAnimationEyesClosed
        },
        {
            questionNumber: 3,
            ref: feelingsRef,
            className: 'large-container',
            initAnimation: DollAnimationEyesClosed
        },
        {
            questionNumber: 4,
            ref: modalitiesRef,
            className: 'large-container',
            initAnimation: DollAnimationEyesClosed
        },
        {
            questionNumber: 5,
            ref: regionRef,
            className: 'max-width-container',
            initAnimation: DollAnimationEyesClosed
        },
        {
            questionNumber: 6,
            ref: languagesRef,
            className: 'max-width-container',
            initAnimation: DollAnimationEyesClosed
        },
        {
            questionNumber: 7,
            ref: ageRef,
            className: 'max-width-container',
            largeChip: true,
            initAnimation: DollAnimationEyesClosed
        },
        {
            questionNumber: 8,
            ref: genderRef,
            className: 'max-width-container',
            largeChip: true,
            initAnimation: DollAnimationEyesClosed
        }
    ];

    const moveToFirstStep = () => {
        setCloudPosition((preState) => (preState += calculateCloudFlyingDistance()));
        setBirdPosition((preState) => (preState += calculateBirdsFlyingDistance()));
        setQuestionNum(1);
    };

    //TODO: HANDLING OF VALIDATION WILL BE HERE
    const nextClickHandler = () => {
        switch (questionNum) {
            case 1:
                submitAnswer(cloneDeep(goalsRef.current.list));
                break;
            case 2:
                submitAnswer(cloneDeep(achievementsRef.current.list));
                break;

            case 3:
                submitAnswer(cloneDeep(feelingsRef.current.list));
                break;
            case 4:
                submitAnswer(cloneDeep(modalitiesRef.current.list));
                break;
            case 5:
                submitAnswer(cloneDeep(regionRef.current.list));
                break;
            case 6:
                submitAnswer(cloneDeep(languagesRef.current.list));
                break;

            case 7:
                submitAnswer(cloneDeep(ageRef.current.list));
                break;

            case 8:
                submitAnswer(cloneDeep(genderRef.current.list));
                break;
            default:
                break;
        }
    };

    const BackButtonHandler = () => {
        if (questionNum === 0) {
            return;
        }
        setCloudPosition((preState) => (preState -= calculateCloudFlyingDistance()));
        setBirdPosition((preState) => (preState -= calculateBirdsFlyingDistance()));
        setQuestionNum((preState) => --preState);
    };

    const calculateCloudFlyingDistance = () => {
        if (windowDimensions.width < BREAKPOINTS_VALUE.MOBILE) {
            return 33;
        } else if (windowDimensions.width < BREAKPOINTS_VALUE.PHABLET) {
            return 38;
        } else if (windowDimensions.width < BREAKPOINTS_VALUE.LAPTOP) {
            return 55;
        } else if (windowDimensions.width < BREAKPOINTS_VALUE.DESKTOP) {
            return 65;
        } else {
            return 75;
        }
    };
    const calculateBirdsFlyingDistance = () => {
        if (windowDimensions.width < BREAKPOINTS_VALUE.MOBILE) {
            return 24;
        } else if (windowDimensions.width < BREAKPOINTS_VALUE.PHABLET) {
            return 40;
        } else if (windowDimensions.width < BREAKPOINTS_VALUE.LAPTOP) {
            return 44;
        } else if (windowDimensions.width < BREAKPOINTS_VALUE.DESKTOP) {
            return 47;
        } else {
            return 52;
        }
    };

    let wavesLottieRef = useRef(null);
    let secondWavesAnimationRef = useRef(null);

    const submitAnswer = async (data) => {
        try {
            const prevData = questionnaireData[questionNum - 1];

            let anySelected = data.findIndex((item) => item?.isSelected);

            if (prevData?.isMandatory && anySelected < 0)
                return dispatch(showToast('Please select at least one option !', 'error'));
            setIsLoading(true);

            let prevSelected = prevData?.keywords.filter((item) => item.isSelected);
            let prevSelectedKeywordIds = prevSelected.map((item) => item.keywordId);
            let newSelected = data
                .filter(
                    (item) => item.isSelected && !prevSelectedKeywordIds.includes(item.keywordId)
                )
                .map((ele) => ele.keywordId);
            let deletedIds = data
                .filter(
                    (item) => !item.isSelected && prevSelectedKeywordIds.includes(item.keywordId)
                )
                .map((ele) => ele.keywordId);

            let bodyData = {
                keywordsIds: prevData?.orderNo === 7 || prevData?.orderNo === 8 ? [] : newSelected,
                questionId: prevData?.questionId,
                orderNo: prevData?.orderNo,
                deletedKeywordsIds: !prevData?.canSelectMultipleOption ? [] : deletedIds
            };

            let ageRange = '';
            let gender = '';

            if (prevData?.orderNo === 7)
                ageRange = data.find((item) => item?.isSelected)?.name || '';
            if (prevData?.orderNo === 8) gender = data.find((item) => item?.isSelected)?.name || '';

            const response = await API.post(API_URL.SUBMIT_QUESTIONNAIRE, {
                data: {
                    ageRange,
                    gender,
                    ...bodyData
                }
            });
            if (response.status === CODES.SUCCESS) {
                if (questionNum === 8) {
                    dispatch(
                        updateUserInfo({
                            ...userDetails,
                            isQuestionnaireCompleted: true
                        })
                    );
                    navigate(URL_QUESTIONNAIRE_MAIN_RESULT);
                    return;
                }
                if (questionNum < 8) {
                    setCloudPosition((preState) => (preState += calculateCloudFlyingDistance()));
                    setBirdPosition((preState) => (preState += calculateBirdsFlyingDistance()));
                }
                getQuestionnaireData();
            }
        } catch (error) {
            setIsLoading(false);
        }
    };

    const getQuestionnaireData = useCallback(async () => {
        try {
            setIsLoading(true);
            const response = await API.get(API_URL.QUESTIONNAIRE_LIST);
            if (response.status === CODES.SUCCESS) {
                setQuestionNum(
                    response?.data?.data?.lastAttemptedQuestionOrderNo
                        ? Math.min(response?.data?.data?.lastAttemptedQuestionOrderNo + 1, 8)
                        : 0
                );
                setQuestionnaireData(response?.data?.data?.list || []);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }, [API]);

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        function logOutUser() {
            if (!userDetails?.isQuestionnaireCompleted) dispatch(logoutUser());
        }
        dispatch(hideFooter());
        dispatch(hideSideBar());

        if (wavesLottieRef && wavesLottieRef.current) {
            wavesLottieRef.current.setSpeed(0.5);
        }

        if (secondWavesAnimationRef && secondWavesAnimationRef.current) {
            secondWavesAnimationRef.current.setSpeed(0.5);
        }

        window.addEventListener('resize', handleResize);

        setBtnContainerMargin(welcomeRef?.current?.scrollHeight);
        window.addEventListener('beforeunload', logOutUser);

        return () => {
            dispatch(showTopBarWithTransition());
            dispatch(showFooter());
            dispatch(showSideBar());
            dispatch(showTopBar());
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('beforeunload', logOutUser);
        };
    }, [dispatch, userDetails?.isQuestionnaireCompleted]);

    useEffect(() => {
        if (questionNum === 0) {
            dispatch(showTopBarWithTransition());
            setBtnContainerMargin(welcomeRef.current.scrollHeight);
            setBtnContainerWidth(welcomeRef.current.btnWidth);
        } else {
            dispatch(hideTopBarWithTransition());
        }

        if (!questionNum) return;
        switch (questionNum) {
            case 1:
                setBtnContainerMargin(goalsRef.current.scrollHeight);
                setBtnContainerWidth(goalsRef.current.btnWidth);
                break;

            case 2:
                setBtnContainerMargin(achievementsRef.current.scrollHeight);
                setBtnContainerWidth(achievementsRef.current.btnWidth);
                break;

            case 3:
                setBtnContainerMargin(feelingsRef.current.scrollHeight);
                setBtnContainerWidth(feelingsRef.current.btnWidth);
                break;

            case 4:
                setBtnContainerMargin(modalitiesRef.current.scrollHeight);
                setBtnContainerWidth(modalitiesRef.current.btnWidth);
                break;

            case 5:
                setBtnContainerMargin(regionRef.current.scrollHeight);
                setBtnContainerWidth(regionRef.current.btnWidth);
                break;

            case 6:
                setBtnContainerMargin(languagesRef.current.scrollHeight);
                setBtnContainerWidth(languagesRef.current.btnWidth);
                break;

            case 7:
                setBtnContainerMargin(ageRef.current.scrollHeight);
                setBtnContainerWidth(ageRef.current.btnWidth);
                break;
            case 8:
                setBtnContainerMargin(genderRef.current.scrollHeight);
                setBtnContainerWidth(genderRef.current.btnWidth);
                break;
            default:
                break;
        }
    }, [
        questionNum,
        dispatch,
        windowDimensions.width,
        goalsRef?.current?.scrollHeight,
        achievementsRef?.current?.scrollHeight,
        feelingsRef?.current?.scrollHeight,
        modalitiesRef?.current?.scrollHeight,
        regionRef?.current?.scrollHeight,
        languagesRef?.current?.scrollHeight,
        ageRef?.current?.scrollHeight,
        genderRef?.current?.scrollHeight
    ]);

    useEffect(() => {
        getQuestionnaireData();
    }, [getQuestionnaireData]);

    return (
        <>
            <TaoCalligraphyWatermark />
            <TaoLoader isLoading={isLoading} />
            <QuestionnaireWrapper cloudPosition={cloudPosition} birdsPosition={birdsPosition}>
                <img src={Questionnaire_Clouds} alt="clouds" className="clouds" />
                <div className="question-content">
                    {QUESTION_BY_CATEGORY?.map((value, index) => {
                        if (questionNum === 0 && questionNum === value?.questionNumber) {
                            return (
                                <React.Fragment key={index}>
                                    <CSSTransition
                                        timeout={750}
                                        classNames="questionnaire-screen"
                                        in={questionNum === 0}
                                        unmountOnExit>
                                        <WelcomeUser userDetail={userDetails} ref={welcomeRef} />
                                    </CSSTransition>
                                </React.Fragment>
                            );
                        }
                        if (questionNum !== 0 && questionNum === value?.questionNumber) {
                            return (
                                <React.Fragment key={index}>
                                    <CSSTransition
                                        timeout={750}
                                        classNames="questionnaire-screen"
                                        in={questionNum === value?.questionNumber}
                                        unmountOnExit>
                                        <UserSelection
                                            data={
                                                questionNum
                                                    ? questionnaireData[questionNum - 1]
                                                    : {}
                                            }
                                            questionNum={questionNum}
                                            userDetail={userDetails}
                                            ref={value?.ref}
                                            className={value?.className}
                                            largeChip={value?.largeChip}
                                            {...value}
                                        />
                                    </CSSTransition>
                                </React.Fragment>
                            );
                        }
                        return null;
                    })}

                    <div
                        className="question-btn-container"
                        style={{ top: btnContainerMargin + 'px', width: btnContainerWidth + 'px' }}>
                        {questionNum > 0 ? (
                            <QuestionFooterWrapper>
                                <TaoButton
                                    disabled={isLoading}
                                    className="btn back-btn"
                                    onClick={BackButtonHandler}>
                                    Back
                                </TaoButton>
                                <TaoButton
                                    variant="contained"
                                    disabled={isLoading}
                                    className="btn next-btn"
                                    onClick={nextClickHandler}>
                                    {questionNum !== 8 ? 'NEXT' : 'FINISH'}
                                </TaoButton>
                            </QuestionFooterWrapper>
                        ) : (
                            <TaoButton className="personalize-btn" onClick={moveToFirstStep}>
                                PERSONALIZE
                            </TaoButton>
                        )}
                    </div>
                </div>

                {windowDimensions.width > BREAKPOINTS_VALUE.PHABLET ? (
                    <WavesAnimationWrapper>
                        <Lottie
                            lottieRef={wavesLottieRef}
                            loop
                            autoplay
                            animationData={wavesAnimation}
                        />
                    </WavesAnimationWrapper>
                ) : windowDimensions.width <= BREAKPOINTS_VALUE.PHABLET && questionNum === 0 ? (
                    <WavesAnimationWrapper>
                        <Lottie
                            lottieRef={secondWavesAnimationRef}
                            loop
                            autoplay
                            animationData={wavesAnimation}
                        />
                    </WavesAnimationWrapper>
                ) : null}
                <img src={Questionnaire_birds} alt="birds" className="birds" />
            </QuestionnaireWrapper>
        </>
    );
};

export default Questionnaire;
