import { Box } from '@mui/system';
import styled from 'styled-components';
import { COLORS, FONTS, responsive } from 'Styles/Constants';
import BottomBackgroundImage from 'Assets/images/MeditationContent/meditation-rating-image.png';
import TransparentBackgroundImage from 'Assets/images/Common/pre-assessment.png';

export const ContentPhaseWrapper = styled(Box)`
    width: 100%;
    height: inherit;

    overflow: auto;
    overflow-x: hidden;

    .image-container {
        min-height: 700px;
        width: 100%;
        display: flex;
        height: 100%;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;

        .bg-image {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: top center;
            position: absolute;
            height: 65%;
            width: 100%;
            /* min-height: calc(100% - 300px); */
            min-height: 400px;
        }

        .dark-shadow {
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
            height: 65%;
            min-height: 550px;
            width: 100%;
            z-index: 10;
            position: absolute;
            bottom: 0;

            ${responsive.MOBILE`
                height: 80%;
            `}
        }

        .text-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-end;
            height: 100%;
            width: 100%;
            text-align: left;
            padding-bottom: 20px;
            margin: 100px 0 0;

            ${responsive.PHABLET`
                position: relative;
                padding-bottom: 10px;
            `}

            .text-content {
                width: 50%;
                z-index: 20;
                position: relative;
                gap: 40px;

                ${responsive.TABLET`
                    width: 90%;
                `}

                .title-container {
                    width: 100%;
                }

                .btn-container {
                    width: 30%;
                    min-width: 200px;
                    max-width: 200px;
                }

                .back-arrow {
                    top: 30px;
                    left: -8%;
                    z-index: 20;
                    position: absolute;

                    ${responsive.TABLET`
                        display: none;
                    `}
                }

                .marquee-child {
                    width: 100%;
                    margin-right: 100px;
                    white-space: nowrap;
                    .title {
                        font-family: ${FONTS.SECONDARY};
                        font-style: normal;
                        font-weight: 700;
                        font-size: 60px;
                        letter-spacing: -0.01em;
                        text-transform: capitalize;
                        color: #ffffff;
                    }
                }

                .description {
                    font-family: ${FONTS.PRIMARY};
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 108.1%;
                    letter-spacing: 0.05em;
                    color: #ffffff;
                    text-align: left;
                }
            }
        }

        .details-container {
            background-image: url(${TransparentBackgroundImage});
            background-size: cover;
            background-repeat: no-repeat;
            width: 100%;
            height: 40%;
            min-height: 390px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 40px;
            background-position: top;
            z-index: 20;
            position: relative;

            ${responsive.TABLET`
                padding-top: 15px;
            `}

            ${responsive.PHABLET`
                padding-top: 10px;
            `}

            .assessment-content {
                width: 35%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 30px 0 0;
                gap: 10px;
                text-transform: uppercase;

                ${responsive.DISPLAY_1650`
                    width: 45%;
                `}

                ${responsive.DESKTOP`
                    width: 50%;
                `}
    
                ${responsive.LAPTOP`
                    width: 60%;
                `}
    
                ${responsive.TABLET`
                    width: 70%;
                `}
    
                ${responsive.TABLET_800`
                    width: 80%;
                `}
    
                ${responsive.PHABLET`
                    width: 90%;
                `}
    
                ${responsive.MOBILE`
                    width: 95%;
                `}
    
                .title {
                    font-family: ${FONTS.PRIMARY};
                    font-style: normal;
                    font-size: 20px;
                    text-align: center;
                    color: ${COLORS.PRIMARY_WARM};
                    text-transform: capitalize;

                    ${responsive.MOBILE`
                        font-size: 20px;
                    `}
                }

                .description {
                    font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    text-align: center;
                    letter-spacing: 0.05em;
                    color: ${COLORS.PRIMARY_WARM};
                }

                .rating-margin {
                    margin: 10px 4px;
                    z-index: 40;
                    position: relative;

                    &.not-selected {
                        opacity: 0.5;
                    }
                }

                .pain-text {
                    font-family: ${FONTS.PRIMARY};
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 108.1%;
                    text-align: center;
                    letter-spacing: 0.05em;
                    color: ${COLORS.MEDIUM_GREY};
                    text-transform: capitalize;
                }

                .skip-text {
                    font-family: ${FONTS.PRIMARY};
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 108.1%;
                    text-align: center;
                    letter-spacing: 0.05em;
                    color: ${COLORS.MEDIUM_GREY};
                    text-transform: none;
                }
            }
            .details-content {
                width: 50%;
                display: flex;
                flex-direction: column;
                gap: 10px;

                ${responsive.TABLET`
                    width: 90%;
                `}

                .bottom-button {
                    margin: 15px 0;
                }

                .chips {
                    height: 50px;
                }

                .goal-chip {
                    padding: 13px 20px 10px;
                    font-family: ${FONTS.PRIMARY};
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 22px;
                    height: 46px;
                    border-radius: 26px;
                    text-transform: uppercase;
                    border: 2px solid transparent;
                    margin: 3px 5px;

                    &.non-selected-goal {
                        background-color: ${COLORS.LIGHT_GREY_SECONDARY};
                        color: ${COLORS.MEDIUM_GREY};
                    }

                    &.selected-goal {
                        border: 2px solid ${COLORS.PRIMARY};
                        color: ${COLORS.PRIMARY};
                    }
                }

                .btn-container {
                    width: 30%;
                    margin: 0 0 0 auto;
                }

                .about-description {
                    font-family: ${FONTS.PRIMARY_MEDIUM};
                    font-style: normal;
                    font-size: 18px;
                    line-height: 27px;
                    color: ${COLORS.TEXT_DARK};
                }

                .meditation-review-container {
                    margin: 10px 5px;
                    display: flex;
                    align-items: center;

                    .icon-margin {
                        margin: 0 5px;
                    }

                    .count-text {
                        line-height: 16px;
                        font-family: ${FONTS.PRIMARY_MEDIUM};
                        font-weight: 500;
                        color: ${COLORS.TEXT_DARK};
                        font-size: 16px;
                    }
                }

                .category-chip-wrapper {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 10px 20px;
                    width: max-content;
                    height: 36px;
                    background: ${COLORS.LIGHT_GREY_SECONDARY};
                    border-radius: 18px;
                    margin: 0 10px 0 0;

                    .category-chip-text {
                        font-family: ${FONTS.PRIMARY};
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        text-transform: uppercase;
                        color: ${COLORS.TEXT_DARK};
                    }
                }

                .two-buttons {
                    display: flex;

                    ${responsive.TABLET_725`
                        display: none;
                    `}
                }

                .get-button {
                    display: none;

                    ${responsive.TABLET_725`
                        display: flex;
                    `}
                }

                .path {
                    font-family: ${FONTS.PRIMARY};
                    text-transform: uppercase;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    letter-spacing: 0.5px;
                    color: ${COLORS.MEDIUM_GREY};
                }
            }
        }

        .button-container {
            position: absolute;
            width: 100%;
            padding: 0 20px;
            bottom: 20px;
            left: 0;

            ${responsive.PHABLET`
                padding: 0 40px;
                bottom: 10px;
            `}

            ${responsive.MOBILE`
                padding: 0 20px;
            `}
        }

        .mascot {
            z-index: 30;
            position: relative;
            width: 100%;
            transform: translate(35%, -140px);

            ${responsive.DESKTOP`
                transform: translate(25%,-140px);
            `}
            ${responsive.LAPTOP`
                transform: translate(20%,-140px);
            `}
            
            ${responsive.TABLET`
                transform: translate(25%,-100px);
            `}

            ${responsive.PHABLET`
                transform: translate(30%,-100px);
            `}

            .lottie-animation {
                position: absolute;
                transform: translate(-10%, -35%);

                ${responsive.TABLET`
                    transform: translate(-30%,-30%);
                `}

                ${responsive.PHABLET`
                    transform: translate(-70%,-20%);
                `}

                ${responsive.MOBILE`
                    transform: translate(-65%,-10%);
                `}

                /* FORCE UPDATE FOR MASCOT LOTTIE FILE */
                svg {
                    width: 400px !important;
                    height: 400px !important;

                    ${responsive.TABLET`
                        width: 280px !important;
                        height: 280px !important;
                    `}

                    ${responsive.PHABLET`
                        width: 220px !important;
                        height: 220px !important;
                    `}

                    ${responsive.MOBILE`
                        width: 170px !important;
                        height: 170px !important;
                    `}
                }
            }
        }
    }
`;

export const MeditationRatingWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    overflow-x: auto;
    border: 2px solid;

    .top-section {
        gap: 20px;
        align-items: flex-end;
        height: 50%;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 35px;

        .space {
            gap: 20px;
        }

        .title {
            font-family: ${FONTS.SECONDARY};
            font-style: normal;
            font-weight: 700;
            font-size: 30px;
            line-height: 42px;
            text-transform: capitalize;
            color: ${COLORS.PRIMARY_DARK};
            margin: 0 30px;
        }

        .text {
            font-family: ${FONTS.PRIMARY};
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.05em;
            color: ${COLORS.TEXT_DARK};
        }

        .rating-margin {
            margin: 10px 8px;
        }

        .pain-text {
            font-family: ${FONTS.PRIMARY};
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 108.1%;
            text-align: center;
            letter-spacing: 0.05em;
            color: ${COLORS.MEDIUM_GREY};
        }
    }

    .bottom-section {
        height: 50%;
        width: 100%;

        background-image: url(${BottomBackgroundImage});
        background-size: cover;
        background-repeat: no-repeat;

        .container {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            gap: 20px;
            margin-top: 65px;
        }

        .title {
            font-family: ${FONTS.PRIMARY};
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 35px;
            text-align: center;
            color: ${COLORS.PRIMARY_DARK};
        }

        .goal-chip {
            padding: 7px;
            /* margin: 3px 5px; */
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            height: 46px;
            border-radius: 26px;
            /* text-transform: uppercase; */
            border: 2px solid transparent;
            margin: 3px;

            &.non-selected-goal {
                border: 2px solid ${COLORS.PRIMARY};
                background-color: rgba(255, 255, 255, 0.6);
                &:hover {
                    background-color: transparent;
                }
            }

            &.selected-goal {
                color: white;
            }

            ${responsive.DISPLAY`
                font-size: 16px;
            `}
        }

        .mobile-filter-input {
            width: 314px;
            height: 45.31px;
            background: rgba(255, 255, 255, 0.66);
            border: 0.832432px solid rgba(132, 132, 132, 0.4);
            backdrop-filter: blur(24.973px);
            border-radius: 6.65946px;

            input {
                margin-left: 1rem;
                margin-top: 2px;
                font-weight: 400;
                font-size: 18px;
                color: #656565;

                &::placeholder {
                    color: #656565;
                }
            }
        }

        .button-container {
            width: 25%;
        }

        .save-close-button {
            width: 138px;
            height: 45px;
            padding: 8px 0px;
        }

        .save-journal-button {
            width: 161.49px;
            height: 45px;
            padding: 8px 0px;
            background-color: #fff;
            border: 2px solid #b08c3d;
            border-radius: 12px;
        }

        .button-text {
            font-family: ${FONTS.PRIMARY};
            font-style: normal;
            font-weight: 600;
            font-size: 13.3189px;
            line-height: 19px;
            text-align: right;
            text-transform: uppercase;
            color: ${FONTS.PRIMARY};
        }

        .skip-text {
            font-family: ${FONTS.PRIMARY};
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 108.1%;
            text-align: center;
            letter-spacing: 0.05em;
            text-transform: none;
            color: ${COLORS.TEXT_DARK};
        }
    }
`;
