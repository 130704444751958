import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Slider, Tooltip, Box, Typography, Button, IconButton } from '@mui/material';
import { useDispatch } from 'react-redux';
import { hideSideBar, showSideBar } from 'Redux/Sidebar/Actions';
import {
    handleTopBarInteractionDetail,
    hideTopBar,
    hideTopBarInteractionButton,
    showTopBar
} from 'Redux/App/Actions';
import {
    MeditationMediaPlayerWrapper,
    PlaybackSpeedPopOverWrapper,
    PopOverWrapper
} from './MeditationMediaPlayer.style';
import { ReactComponent as PreviousSVG } from 'Assets/images/FieldHealing/previousSVG.svg';
import { ReactComponent as ForwardSVG } from 'Assets/images/FieldHealing/forwardSVG.svg';
import { ReactComponent as PauseSVG } from 'Assets/images/FieldHealing/pause.svg';
import { ReactComponent as PlaySVG } from 'Assets/images/FieldHealing/play.svg';
import { convertHrsMinToSeconds, getTotalTimeInMins, getWindowDimensions } from 'Helpers';
import { ReactComponent as Subtitle } from 'Assets/images/FieldHealing/subtitle-shadow.svg';
import { ReactComponent as SettingIcon } from 'Assets/images/FieldHealing/setting-icon.svg';
import { ReactComponent as SelectedSubTitle } from 'Assets/images/FieldHealing/subtitle-selected.svg';
import { ReactComponent as FullScreenSVG } from 'Assets/images/FieldHealing/fullscreen-shadow.svg';
import { ReactComponent as FullSound } from 'Assets/images/FieldHealing/full-sound.svg';
import { ReactComponent as MuteSound } from 'Assets/images/FieldHealing/mute-sound.svg';
import { ReactComponent as DecreaseSound } from 'Assets/images/FieldHealing/decrease-sound.svg';
import { ReactComponent as CloseIcon } from 'Assets/images/Common/close-icon.svg';
import { BREAKPOINTS_VALUE } from 'Styles/Constants';
import RepeatSVG from '../RepeatSVG';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import TaoScrollbar from 'Components/common/Scrollbar';
import Api from 'Helpers/ApiHandler';
import { API_URL, URL_POST_ASSESSMENT } from 'Helpers/Paths';
import { MEDIA_TYPE } from 'Helpers/Constants';
import TaoLoader from 'Components/common/TaoBackdropLoader';
import { NotificationStyleWrapper } from 'Components/Pages/MeditationTimer/MeditationTimer.style';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import playerjs from 'player.js';
import Marquee from 'react-fast-marquee';

const SPEED_ADJUSTMENT_VALUEs = [
    { title: '0.5', value: 0.5 },
    { title: '0.75', value: 0.75 },
    { title: '1.0', value: 1 },
    { title: '1.5', value: 1.5 },
    { title: '2', value: 2 }
];
const SPEED_FORWARD = 'SPEED_FORWARD';
const SPEED_BACKWARD = 'SPEED_BACKWARD';

let SEEK_SECONDS = 15;
// const EN_AUTOGENERATED = ['en-x-autogen', 'en-x-autogenerated'];

const MeditationMediaPlayer = ({
    propsContentId,
    playOver,
    isInitialExperience = false,
    setTextData = null,
    apiUrl = '',
    contentParamsType = null,
    setContentPlayingOnFullScreen = null,
    ...props
}) => {
    const playerRef = useRef(null);
    const [mediaType, setMediaType] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [mediaPlay, setMediaPlay] = useState(false);
    const [duration, setDuration] = useState('00:00');
    const [elapsedTime, setElapsedTime] = useState('00:00');
    const [chaptersTime, setChaptersTime] = useState([]);
    const [mediaLengthInSeconds, setMediaLengthInSeconds] = useState(0);
    const [elapsedTimeInSeconds, setElapsedTimeInSeconds] = useState(0);
    const [trackLang, setTrackLang] = useState('');
    const [tracks, setTracks] = useState([]);
    const [selectedStep, setSelectedStep] = useState(0);
    const [isMediaFullScreen, setIsMediaFullScreen] = useState(false);
    const [repeat, setRepeat] = useState(false);
    const [link, setLink] = useState('');
    const [controlsVisible, setControlsVisible] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [contentDetails, setContentDetails] = useState({});
    const API = useMemo(() => new Api(), []);
    const [anchorEl, setAnchorEl] = useState(null);
    const [playbackAnchorEl, setPlaybackAnchorEl] = useState(null);
    const [playbackSpeed, setPlaybackSpeed] = useState(1);
    const elapsedTimeRef = useRef();
    const [scrollChapterLeft, setChapterScrollLeft] = useState(0);
    const playedSeconds = useRef(0);
    const [soundControlOpen, setSoundControlOpen] = useState(false);
    const [volume, setVolume] = useState(100);
    const [mutedSoundValue, setMutedSoundValue] = useState(100);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const userDetails = useSelector((state) => state.Auth.userInfo);
    const [showZenModePopup, setZenModePopup] = useState(true);
    const marqueeContent = useRef(null);
    const marqueeDiv = useRef(null);
    const [loop, setLoop] = useState(
        marqueeContent.current?.clientWidth > marqueeDiv.current?.clientWidth ? 0 : 1
    );

    const toggleFullScreen = () => {
        if (
            (document.fullScreenElement && document.fullScreenElement !== null) ||
            (!document.mozFullScreen && !document.webkitIsFullScreen)
        ) {
            if (document.documentElement.requestFullScreen) {
                document.documentElement.requestFullScreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                /* Firefox */
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullScreen) {
                /* Chrome, Safari & Opera */
                document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
            } else if (document.msRequestFullscreen) {
                /* IE/Edge */
                document.documentElement.msRequestFullscreen();
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                /* Firefox */
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                /* Chrome, Safari and Opera */
                document.webkitCancelFullScreen();
            } else if (document.msExitFullscreen) {
                /* IE/Edge */
                document.msExitFullscreen();
            }
        }
    };

    const onSelectStep = useCallback(
        (itemDetails) => {
            if (!itemDetails?.chapterTimeStamp) return;
            let seconds = convertHrsMinToSeconds(itemDetails?.chapterTimeStamp);
            if (seconds > mediaLengthInSeconds) return;
            setElapsedTime(getTotalTimeInMins(seconds));
            setElapsedTimeInSeconds(seconds);
            // if (mediaType === MEDIA_TYPE.AUDIO) playerRef.current.currentTime = seconds;
            // else {
            //     playerRef.current.setCurrentTime(seconds);
            // }
            playerRef.current.currentTime = seconds;
            setSelectedStep(itemDetails?.chapterId);
        },
        [mediaLengthInSeconds]
    );

    const showSnackbar = useCallback(() => {
        if (!userDetails?.isSilenceDuringMeditation) return;

        const notificationData = (
            <NotificationStyleWrapper className="flex f-v-center">
                <Box className="logo" />
                <Box className="flex f-column">
                    <Typography className="text primary">Zen Mode: ON</Typography>
                    <Typography className="text secondary">
                        We’ll turn off our notifications
                    </Typography>
                </Box>
                <Button
                    className="btn"
                    onClick={() => {
                        setZenModePopup(false);
                        closeSnackbar();
                    }}>
                    DON’T SHOW AGAIN
                </Button>
                <IconButton
                    className="close-icon"
                    onClick={() => {
                        closeSnackbar();
                    }}>
                    <CloseIcon />
                </IconButton>
            </NotificationStyleWrapper>
        );
        showZenModePopup && enqueueSnackbar(notificationData, { autoHideDuration: 5000 });
    }, [closeSnackbar, userDetails?.isSilenceDuringMeditation, enqueueSnackbar, showZenModePopup]);

    const getBunnyNetCurrentTime = async () => {
        const response = await new Promise((resolve, reject) => {
            playerRef?.current?.getCurrentTime?.((value) => resolve(value));
        });
        return response;
    };

    const fetchContentById = useCallback(async () => {
        if (!location?.state?.contentId && !propsContentId && !isInitialExperience) return;

        setMediaType(null);
        // playerRef?.current && playerRef?.current?.destroy?.();
        // if (playerRef?.current) playerRef.current = null;

        setIsLoading(true);
        let response = await API.get(isInitialExperience ? apiUrl : API_URL.CONTENT_LIST, {
            params: {
                contentId: location?.state?.contentId || propsContentId,
                type: contentParamsType
            }
        });
        if (response) {
            setContentDetails(response?.data?.data);
            dispatch(handleTopBarInteractionDetail(response?.data?.data || {}));
            setChaptersTime(response?.data?.data?.chapters);
            const {
                data: {
                    data: { type }
                }
            } = response;
            if (setTextData) {
                setTextData?.({ description: response?.data?.data?.description });
            }
            if (
                type === MEDIA_TYPE.GUIDED_AUDIO ||
                type === MEDIA_TYPE.AUDIO ||
                type === MEDIA_TYPE.MUSIC
            ) {
                setMediaType(MEDIA_TYPE.AUDIO);
                setLink(response?.data?.data?.contentFile);
            }

            if (type === MEDIA_TYPE.VIDEO) {
                const iframe = document.getElementById('bunny-player');
                iframe.src = response?.data?.data?.contentFileForIFrame;

                const player = new playerjs.Player('bunny-player');
                playerRef.current = player;
                player.mute();
                player.on('ready', () => {
                    player.on('play', () => {
                        setIsLoading(false);
                        setMediaPlay(true);
                        setMediaType(MEDIA_TYPE.VIDEO);
                        setLink(response?.data?.data?.contentFile);
                        // player.unmute();
                    });
                    player.on('pause', () => {
                        setMediaPlay(false);
                    });
                    setTimeout(() => {
                        player.getDuration((duration) => {
                            setDuration(getTotalTimeInMins(duration));
                            setMediaLengthInSeconds(parseInt(duration));
                        });
                    }, 1000);
                });
            }
            setIsLoading(false);
        }
    }, [
        contentParamsType,
        API,
        location?.state?.contentId,
        propsContentId,
        isInitialExperience,
        dispatch,
        apiUrl,
        setTextData
    ]);

    const selectTextTracks = async (item) => {
        setAnchorEl(null);
        if (trackLang && item?.label === trackLang) {
            setTrackLang('');
            await playerRef.current.disableTextTrack();
            return;
        }
        setTrackLang(item?.label);
        await playerRef.current.enableTextTrack(item?.language, item?.kind);
    };

    // const previous = async () => {
    //     let currentTime = playerRef.current.currentTime;

    //     playerRef.current.currentTime = currentTime - SEEK_SECONDS;
    //     !mediaPlay && setElapsedTimeInSeconds(parseInt(currentTime));
    //     playerRef?.current && setElapsedTime(getTotalTimeInMins(currentTime));
    // };

    const previous = async () => {
        let currentTime =
            mediaType === MEDIA_TYPE.AUDIO
                ? playerRef.current.currentTime
                : await getBunnyNetCurrentTime();

        if (mediaType === MEDIA_TYPE.AUDIO) {
            playerRef.current.currentTime = currentTime - SEEK_SECONDS;
        } else {
            currentTime > SEEK_SECONDS &&
                playerRef.current.setCurrentTime(currentTime - SEEK_SECONDS);
        }
        !mediaPlay && setElapsedTimeInSeconds(parseInt(currentTime));
        playerRef?.current && setElapsedTime(getTotalTimeInMins(currentTime));
    };

    // const forward = async () => {
    //     let currentTime = playerRef.current.currentTime;

    //     playerRef.current.currentTime = currentTime + SEEK_SECONDS;
    //     !mediaPlay && setElapsedTimeInSeconds(parseInt(currentTime));
    //     playerRef?.current && setElapsedTime(getTotalTimeInMins(currentTime));
    // };

    const forward = async () => {
        let currentTime =
            mediaType === MEDIA_TYPE.AUDIO
                ? playerRef.current.currentTime
                : await getBunnyNetCurrentTime();

        if (mediaType === MEDIA_TYPE.AUDIO) {
            playerRef.current.currentTime = currentTime + SEEK_SECONDS;
        } else {
            playerRef.current.setCurrentTime(currentTime + SEEK_SECONDS);
        }
        !mediaPlay && setElapsedTimeInSeconds(parseInt(currentTime));
        playerRef?.current && setElapsedTime(getTotalTimeInMins(currentTime));
    };

    const updatePlayTime = useCallback(
        async (time) => {
            if (isInitialExperience) return;

            if (!location?.state?.programContentId) {
                await API.post(API_URL.CONTENT_PLAY_TIME, {
                    params: {
                        contentId: location?.state?.contentId || propsContentId,
                        playTime: getTotalTimeInMins(time, true)
                    }
                });
                return;
            }
            await API.post(API_URL.PROGRAM_CONTENT_PLAY_TIME, {
                params: {
                    programContentId: location?.state?.programContentId,
                    playTime: getTotalTimeInMins(time, true)
                }
            });
        },
        [
            API,
            location?.state?.programContentId,
            location?.state?.contentId,
            propsContentId,
            isInitialExperience
        ]
    );

    const unMuteSoundWithPreviousEnable = async () => {
        setMutedSoundValue(volume);
        setVolume(0);
        if (mediaType !== MEDIA_TYPE.AUDIO) playerRef.current.setVolume(0);
        else playerRef.current.volume = 0;
    };

    const escFunction = useCallback(() => {
        setContentPlayingOnFullScreen?.(!isMediaFullScreen);
        setIsMediaFullScreen(!isMediaFullScreen);
    }, [isMediaFullScreen, setContentPlayingOnFullScreen]);

    const adjustSpeedControl = async (type) => {
        let currentSpeedIndex = SPEED_ADJUSTMENT_VALUEs.findIndex(
            (item) => item?.value === playbackSpeed
        );

        if (type === SPEED_BACKWARD && currentSpeedIndex) {
            setPlaybackSpeed(SPEED_ADJUSTMENT_VALUEs[currentSpeedIndex - 1]?.value);
            return;
        }
        if (type === SPEED_FORWARD && currentSpeedIndex < SPEED_ADJUSTMENT_VALUEs?.length - 1) {
            setPlaybackSpeed(SPEED_ADJUSTMENT_VALUEs[currentSpeedIndex + 1]?.value);
            return;
        }
    };

    useEffect(() => {
        if (mediaPlay) showSnackbar();
    }, [mediaPlay, showSnackbar]);

    useEffect(() => {
        elapsedTimeRef.current = elapsedTimeInSeconds;

        chaptersTime?.length &&
            chaptersTime?.forEach((item) => {
                let seconds = convertHrsMinToSeconds(item?.chapterTimeStamp);
                if (parseInt(seconds) <= parseInt(elapsedTimeInSeconds)) {
                    setSelectedStep(item?.chapterId);
                }
            });

        let myElement = document.getElementById('chapter-selected-scroll');
        if (!myElement) return;
        let menus = document.getElementsByClassName('step');
        for (let i = 0; i < menus.length; i++) {
            if (menus[i].classList.contains('selected-step')) {
                setChapterScrollLeft(menus[i].offsetLeft);
            }
        }

        if (
            parseInt(mediaLengthInSeconds) > 0 &&
            parseInt(elapsedTimeRef.current) === parseInt(mediaLengthInSeconds) &&
            repeat
        ) {
            let introExists = contentDetails?.chapters?.findIndex(
                (item) => item?.chapterType === 'INTRO'
            );
            if (introExists > -1) onSelectStep(contentDetails?.chapters[1]);
            else onSelectStep(contentDetails.chapters[0]);
        }
        if (
            parseInt(mediaLengthInSeconds) > 0 &&
            parseInt(elapsedTimeRef.current) === parseInt(mediaLengthInSeconds) &&
            !repeat
        ) {
            if (propsContentId) {
                elapsedTimeRef.current = 0;
                setElapsedTimeInSeconds(0);
                playOver && playOver();
                return;
            }
            setMediaPlay(false);
            if (
                (contentDetails?.isPostAssessmentCompleted && contentDetails?.isAssessmentDone) ||
                contentDetails?.isInstructional
            )
                return navigate(-1);
            navigate(URL_POST_ASSESSMENT, {
                state: {
                    contentId: location?.state?.contentId || location?.state?.programContentId
                }
            });
        }
    }, [
        elapsedTimeInSeconds,
        mediaLengthInSeconds,
        chaptersTime,
        repeat,
        navigate,
        location?.state?.programContentId,
        location?.state?.contentId,
        propsContentId,
        playOver,
        contentDetails?.isPostAssessmentCompleted,
        contentDetails?.isAssessmentDone,
        contentDetails?.chapters,
        onSelectStep,
        contentDetails?.isInstructional
    ]);

    useEffect(() => {
        const setSpeed = async () => {
            playerRef.current.playbackRate = playbackSpeed;
        };
        if (playerRef?.current) setSpeed();
    }, [playbackSpeed, mediaType, propsContentId]);

    useEffect(() => {
        fetchContentById();
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [fetchContentById]);

    useEffect(() => {
        dispatch(hideSideBar());
        return () => {
            dispatch(showSideBar());
            dispatch(handleTopBarInteractionDetail({}));
            dispatch(hideTopBarInteractionButton(false));
            updatePlayTime(playedSeconds.current);
        };
    }, [updatePlayTime, dispatch]);

    useEffect(() => {
        var interval = 0;
        function timer() {
            interval = setInterval(async () => {
                if (!mediaPlay) return;
                let currentTime =
                    mediaType === MEDIA_TYPE.AUDIO
                        ? playerRef?.current?.currentTime
                        : await getBunnyNetCurrentTime();

                if (!currentTime) return;
                let time = getTotalTimeInMins(currentTime);
                setElapsedTimeInSeconds(parseInt(currentTime));
                time && setElapsedTime(time);
                if (mediaPlay) {
                    playedSeconds.current = playedSeconds.current + playbackSpeed * 0.1;
                }
            }, 1000);
        }
        if (playerRef.current) timer();
        return () => clearInterval(interval);
    }, [mediaPlay, mediaType, playbackSpeed]);

    // HIDE CONTROLS ON FULL SCREEN FOR VIDEO
    useEffect(() => {
        let timer = null;

        if (!mediaType) return;

        const handleMouseMove = () => {
            // if (!document.fullscreenElement) return setControlsVisible(true);
            // if (timer) clearTimeout(timer);
            // timer = setTimeout(() => {
            //     setControlsVisible(false);
            //     setSoundControlOpen(false);
            //     setPlaybackAnchorEl(null);
            // }, 3000);
            // setControlsVisible(true);
        };

        window.addEventListener('mousemove', handleMouseMove, true);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove, true);
            if (timer) clearTimeout(timer);
        };
    }, [mediaType]);

    useEffect(() => {
        if (isMediaFullScreen) dispatch(hideTopBar());
        else dispatch(showTopBar());
    }, [isMediaFullScreen, dispatch]);

    useEffect(() => {
        setLoop(marqueeContent.current?.clientWidth > marqueeDiv.current?.clientWidth ? 0 : 1);
    }, [marqueeContent.current?.clientWidth, marqueeDiv.current?.clientWidth]);

    useEffect(() => {
        document.addEventListener('webkitfullscreenchange', escFunction, false);
        document.addEventListener('mozfullscreenchange', escFunction, false);
        document.addEventListener('fullscreenchange', escFunction, false);
        document.addEventListener('MSFullscreenChange', escFunction, false);
        return () => {
            document.removeEventListener('webkitfullscreenchange', escFunction, false);
            document.removeEventListener('mozfullscreenchange', escFunction, false);
            document.removeEventListener('fullscreenchange', escFunction, false);
            document.removeEventListener('MSFullscreenChange', escFunction, false);
        };
    }, [escFunction]);

    const open = Boolean(anchorEl);
    const id = open ? 'caption-popover' : undefined;

    const playbackOpen = Boolean(playbackAnchorEl);
    const playbackId = playbackOpen ? 'playback-popover' : undefined;

    return (
        <MeditationMediaPlayerWrapper
            screen={windowDimensions}
            $isLoading={isLoading}
            sx={{
                height:
                    propsContentId || isInitialExperience
                        ? isMediaFullScreen
                            ? '100vh'
                            : '100%'
                        : '100vh',
                minHeight: !isInitialExperience && '80vh'
            }}>
            <TaoLoader isLoading={isLoading} />
            {!isInitialExperience && (
                <Box
                    className={`image-background ${
                        document.fullscreenElement && 'full-screen-mode'
                    }`}
                    style={{
                        backgroundImage:
                            !document.fullscreenElement &&
                            `url(${contentDetails?.backgroundImageWeb})`
                    }}
                />
            )}
            <Box
                className={`white-background ${document.fullscreenElement && 'full-screen-mode'}`}
            />
            <Box
                className={`video-player-content ${
                    document.fullscreenElement && 'full-screen-mode'
                }`}
                sx={{ padding: propsContentId || isInitialExperience ? '100px 0 0' : '100px 0' }}>
                {isInitialExperience && !document.fullscreenElement && (
                    <Box className="full-width" ref={marqueeDiv}>
                        <Marquee gradient={false} speed={60} delay={2} loop={loop}>
                            <Typography className="title" ref={marqueeContent}>
                                {contentDetails?.title}
                            </Typography>
                        </Marquee>
                    </Box>
                )}
                <div className="full-width full-height">
                    {document.fullscreenElement && (
                        <Box
                            className={`player-custom-controller ${
                                !controlsVisible && 'hide-controls'
                            }`}>
                            <Box className="player-buttons flex f-v-center f-h-center ">
                                {!isInitialExperience && (
                                    <Box className="previous hover">
                                        <PreviousSVG onClick={() => previous()} />
                                    </Box>
                                )}

                                <Box
                                    className={`pause hover ${
                                        isInitialExperience && 'remove-spacing'
                                    }`}>
                                    {!mediaPlay ? (
                                        <PlaySVG
                                            onClick={() => {
                                                playerRef?.current?.play();
                                                setMediaPlay((prevState) => !prevState);
                                            }}
                                        />
                                    ) : (
                                        <PauseSVG
                                            onClick={() => {
                                                playerRef?.current?.pause();
                                                setMediaPlay((prevState) => !prevState);
                                                updatePlayTime(playedSeconds.current);
                                            }}
                                        />
                                    )}
                                </Box>

                                {!isInitialExperience && (
                                    <Box className="forward hover">
                                        <ForwardSVG onClick={() => forward()} />
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    )}

                    <Box className="player flex f-h-center full-width">
                        <Box
                            className="player-width"
                            position={document?.fullscreenElement ? 'initial' : 'relative'}>
                            {mediaType === MEDIA_TYPE.AUDIO ? (
                                <Box className="audio-player-content">
                                    <Box
                                        className={`audio-image-container ${
                                            document?.fullscreenElement && 'remove-border'
                                        }`}>
                                        <Box
                                            sx={{
                                                backgroundImage: `url(${contentDetails?.contentImage})`
                                            }}
                                            className={`audio-image ${
                                                document?.fullscreenElement && 'full-audio-height'
                                            } full-width`}
                                        />

                                        <div style={{ display: 'none' }}>
                                            <audio
                                                ref={playerRef}
                                                loop={repeat}
                                                src={link}
                                                muted
                                                autoPlay
                                                onDurationChange={() => {
                                                    let audioPromise = playerRef?.current?.play();

                                                    if (audioPromise !== undefined) {
                                                        audioPromise
                                                            .then((_) => {
                                                                setMediaPlay(true);
                                                            })
                                                            .catch((err) => {
                                                                setMediaPlay(false);
                                                            });
                                                    }

                                                    if (playerRef?.current) {
                                                        if (playerRef?.current?.muted)
                                                            playerRef.current.muted = false;
                                                        setDuration(
                                                            getTotalTimeInMins(
                                                                playerRef.current.duration
                                                            )
                                                        );
                                                        setMediaLengthInSeconds(
                                                            parseInt(playerRef.current.duration)
                                                        );
                                                    }
                                                }}
                                                controls
                                            />
                                        </div>
                                        <Box className="audio-text-background">
                                            <Tooltip title={contentDetails?.imageCaption}>
                                                <Typography className="audio-image-text elipsis">
                                                    {contentDetails?.imageCaption}
                                                </Typography>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                </Box>
                            ) : (
                                <Box className="bunny-video">
                                    <iframe
                                        title="bunny"
                                        id="bunny-player"
                                        loading="lazy"
                                        height="700"
                                        width="700"
                                        style={{
                                            border: 'none',
                                            position: 'absolute',
                                            top: 0,
                                            height: '100%',
                                            width: '100%'
                                        }}
                                        allowFullScreen
                                        controls="0"
                                    />
                                </Box>
                            )}

                            <Box
                                className={`videoOptionContainer ${
                                    mediaType === MEDIA_TYPE.AUDIO && 'audio-controls'
                                } ${document.fullscreenElement && 'full-screen'} ${
                                    (!controlsVisible || isLoading) && 'hide-controls'
                                }`}>
                                {link && (
                                    <Box
                                        className="flex full-width"
                                        position="relative"
                                        sx={{
                                            alignItems: 'center',
                                            gap: '10px'
                                        }}>
                                        <React.Fragment>
                                            {!!tracks?.length &&
                                                (!trackLang ? (
                                                    <Subtitle
                                                        onClick={(event) => {
                                                            if (tracks?.length === 1) {
                                                                return selectTextTracks(
                                                                    tracks?.[0]
                                                                );
                                                            }
                                                            setAnchorEl(event.currentTarget);
                                                        }}
                                                        className="hover"
                                                        aria-describedby={id}
                                                    />
                                                ) : (
                                                    <SelectedSubTitle
                                                        onClick={(event) => {
                                                            if (tracks?.length === 1) {
                                                                return selectTextTracks(
                                                                    tracks?.[0]
                                                                );
                                                            }
                                                            setAnchorEl(event.currentTarget);
                                                        }}
                                                        className="hover"
                                                        aria-describedby={id}
                                                    />
                                                ))}
                                            <PopOverWrapper
                                                id={id}
                                                open={open}
                                                // container={
                                                //     document?.fullscreenElement ? ref.current : null
                                                // }
                                                anchorEl={anchorEl}
                                                onClose={() => setAnchorEl(null)}
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left'
                                                }}>
                                                {tracks.map((item, index) => (
                                                    <Typography
                                                        onClick={() => selectTextTracks(item)}
                                                        key={index}
                                                        className={`captions-text-root ${
                                                            item.label === trackLang && 'selected'
                                                        } hover`}>
                                                        {item?.label}
                                                    </Typography>
                                                ))}
                                            </PopOverWrapper>
                                        </React.Fragment>

                                        <SettingIcon
                                            className={`setting-icon ${
                                                playbackId && 'active'
                                            } hover`}
                                            aria-describedby={playbackId}
                                            onClick={(event) =>
                                                setPlaybackAnchorEl(event.currentTarget)
                                            }
                                        />

                                        <PlaybackSpeedPopOverWrapper
                                            id={playbackId}
                                            open={playbackOpen}
                                            // container={
                                            //     document?.fullscreenElement ? ref.current : null
                                            // }
                                            classes={{
                                                paper: 'root'
                                            }}
                                            anchorEl={playbackAnchorEl}
                                            onClose={() => setPlaybackAnchorEl(null)}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left'
                                            }}
                                            transformOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right'
                                            }}>
                                            <Box className="playback-box">
                                                <Box className="flex">
                                                    <Typography className="adjust-speed-title">
                                                        Adjust Speed
                                                    </Typography>
                                                    <CloseIcon
                                                        className="hover"
                                                        onClick={() => setPlaybackAnchorEl(null)}
                                                    />
                                                </Box>
                                                <Box className="speed-values">
                                                    {SPEED_ADJUSTMENT_VALUEs.map((speed) => (
                                                        <Button
                                                            key={speed?.value}
                                                            onClick={() =>
                                                                setPlaybackSpeed(speed?.value)
                                                            }
                                                            className={`speed-btn ${
                                                                speed?.value === playbackSpeed &&
                                                                'active-speed'
                                                            }`}>
                                                            {speed?.title}
                                                        </Button>
                                                    ))}
                                                </Box>
                                                <Box className="flex" margin="8px 0 0">
                                                    <Button
                                                        className="flex f-v-center hover"
                                                        disabled={
                                                            SPEED_ADJUSTMENT_VALUEs[0].value ===
                                                            playbackSpeed
                                                        }
                                                        style={{
                                                            opacity:
                                                                SPEED_ADJUSTMENT_VALUEs[0].value ===
                                                                playbackSpeed
                                                                    ? 0.5
                                                                    : 1
                                                        }}
                                                        onClick={() =>
                                                            adjustSpeedControl(SPEED_BACKWARD)
                                                        }>
                                                        <Box className="arrow previous" />
                                                        <Typography className="slower">
                                                            Slower
                                                        </Typography>
                                                    </Button>
                                                    <Button
                                                        onClick={() =>
                                                            adjustSpeedControl(SPEED_FORWARD)
                                                        }
                                                        disabled={
                                                            SPEED_ADJUSTMENT_VALUEs[
                                                                SPEED_ADJUSTMENT_VALUEs?.length - 1
                                                            ].value === playbackSpeed
                                                        }
                                                        className="flex f-v-center hover"
                                                        style={{
                                                            margin: '0 0 0 auto',
                                                            opacity:
                                                                SPEED_ADJUSTMENT_VALUEs[
                                                                    SPEED_ADJUSTMENT_VALUEs?.length -
                                                                        1
                                                                ].value === playbackSpeed
                                                                    ? 0.5
                                                                    : 1
                                                        }}>
                                                        <Typography className="faster">
                                                            Faster
                                                        </Typography>
                                                        <Box className="arrow" />
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </PlaybackSpeedPopOverWrapper>

                                        <Box
                                            className="sound-control"
                                            onMouseLeave={() => setSoundControlOpen(false)}>
                                            <Box
                                                className={`control-ui flex f-v-center f-h-center ${
                                                    !soundControlOpen && 'hidden'
                                                }`}>
                                                <Box className="volume-slider">
                                                    <Slider
                                                        value={volume}
                                                        max={100}
                                                        min={0}
                                                        orientation="vertical"
                                                        classes={{
                                                            track: 'volume-slider-track',
                                                            rail: 'volume-slider-rail',
                                                            thumb: 'volume-slider-thumb'
                                                        }}
                                                        aria-label="Default"
                                                        valueLabelDisplay="off"
                                                        onChange={async (e, value) => {
                                                            setVolume(value);
                                                            playerRef.current.volume = value / 100;
                                                        }}
                                                    />
                                                </Box>
                                            </Box>

                                            <Box onMouseEnter={() => setSoundControlOpen(true)}>
                                                {volume > 50 && (
                                                    <FullSound
                                                        className="sound-control-icon hover"
                                                        onClick={() =>
                                                            soundControlOpen &&
                                                            unMuteSoundWithPreviousEnable()
                                                        }
                                                    />
                                                )}
                                                {volume <= 50 && volume >= 1 && (
                                                    <DecreaseSound
                                                        className="sound-control-icon hover"
                                                        onClick={() =>
                                                            soundControlOpen &&
                                                            unMuteSoundWithPreviousEnable()
                                                        }
                                                    />
                                                )}
                                                {volume < 1 && (
                                                    <MuteSound
                                                        className="sound-control-icon hover"
                                                        onClick={async () => {
                                                            if (!soundControlOpen) return;
                                                            setVolume(mutedSoundValue);
                                                            // if (mediaType !== MEDIA_TYPE.AUDIO)
                                                            //     await playerRef.current.setVolume(
                                                            //         mutedSoundValue / 100
                                                            //     );
                                                            // else
                                                            playerRef.current.volume =
                                                                mutedSoundValue / 100;
                                                        }}
                                                    />
                                                )}
                                            </Box>
                                        </Box>

                                        <FullScreenSVG
                                            className="hover"
                                            onClick={() => {
                                                toggleFullScreen();
                                            }}
                                        />
                                    </Box>
                                )}

                                {document.fullscreenElement && (
                                    <>
                                        <Box
                                            className={`progressBar-fullscreen ${
                                                !controlsVisible && 'hide-controls'
                                            }`}>
                                            <Slider
                                                value={elapsedTimeInSeconds}
                                                max={mediaLengthInSeconds}
                                                classes={{
                                                    track: 'slider-track',
                                                    rail: 'slider-rail',
                                                    thumb: 'slider-thumb'
                                                }}
                                                aria-label="Default"
                                                valueLabelDisplay="off"
                                                onChange={async (e, value) => {
                                                    if (mediaType === MEDIA_TYPE.AUDIO) {
                                                        playerRef.current.currentTime = value;
                                                    } else {
                                                        playerRef?.current &&
                                                            playerRef.current.setCurrentTime(value);
                                                    }
                                                    setElapsedTimeInSeconds(value);
                                                }}
                                            />
                                        </Box>
                                        <Box
                                            className={`time-fullscreen ${
                                                !controlsVisible && 'hide-controls'
                                            }`}>
                                            <Typography>{elapsedTime}</Typography>

                                            <Typography>{duration}</Typography>
                                        </Box>
                                    </>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </div>

                {!isLoading && !document.fullscreenElement && (
                    <Box className="playerDetailsWrapper">
                        <Box className="flex f-v-center">
                            {!isInitialExperience && (
                                <Box className="titleWrapper">
                                    <Box className="full-width" ref={marqueeDiv}>
                                        <Marquee gradient={false} speed={60} delay={2} loop={loop}>
                                            <Typography className="title" ref={marqueeContent}>
                                                {contentDetails?.title}
                                            </Typography>
                                        </Marquee>
                                    </Box>
                                    {windowDimensions.width < BREAKPOINTS_VALUE.TABLET && (
                                        <Box
                                            className="flex f-h-center f-v-center full-width"
                                            height={30}>
                                            {false && (
                                                <Box
                                                    onClick={() => {
                                                        if (mediaType === MEDIA_TYPE.VIDEO)
                                                            repeat
                                                                ? playerRef.current.setLoop(false)
                                                                : playerRef.current.setLoop(true);
                                                        setRepeat((prevState) => !prevState);
                                                    }}
                                                    style={{ cursor: 'pointer', margin: '0 5px' }}>
                                                    <RepeatSVG fill={repeat ? '#BC1218' : false} />
                                                </Box>
                                            )}

                                            <TaoScrollbar
                                                keyId="mobileChapters"
                                                horizontalScroll
                                                scrollLeft={scrollChapterLeft}>
                                                <Box className="flex" id="chapter-selected-scroll">
                                                    {contentDetails?.chapters?.map((item) => (
                                                        <Typography
                                                            key={item?.chapterId}
                                                            onClick={() => onSelectStep(item)}
                                                            className={`step ${
                                                                selectedStep === item?.chapterId &&
                                                                'selected-step'
                                                            }`}>
                                                            {item?.chapterName}
                                                        </Typography>
                                                    ))}
                                                </Box>
                                            </TaoScrollbar>
                                        </Box>
                                    )}
                                </Box>
                            )}
                        </Box>

                        <Box
                            className={`flex f-v-center playerManagement ${
                                isInitialExperience && 'remove-direction'
                            }`}>
                            <Box className="player-buttons flex f-v-center f-h-center ">
                                {!isInitialExperience && (
                                    <Box className="hover">
                                        <PreviousSVG onClick={() => previous()} />
                                    </Box>
                                )}
                                <Box
                                    className={`pause hover ${
                                        isInitialExperience && 'remove-spacing'
                                    }`}
                                    sx={{ padding: isInitialExperience ? '0' : '0 10px' }}>
                                    {!mediaPlay ? (
                                        <PlaySVG
                                            className="full-height full-width"
                                            onClick={() => {
                                                playerRef?.current?.play();
                                                setMediaPlay((prevState) => !prevState);
                                            }}
                                        />
                                    ) : (
                                        <PauseSVG
                                            className="full-height full-width"
                                            onClick={() => {
                                                playerRef?.current?.pause();
                                                setMediaPlay((prevState) => !prevState);
                                                updatePlayTime(playedSeconds.current);
                                            }}
                                        />
                                    )}
                                </Box>

                                {!isInitialExperience && (
                                    <Box className="forward hover">
                                        <ForwardSVG onClick={() => forward()} />
                                    </Box>
                                )}
                            </Box>

                            {windowDimensions.width > BREAKPOINTS_VALUE.TABLET && (
                                <Box
                                    className={`time flex f-v-center ${
                                        isInitialExperience && 'add-spacing'
                                    }`}>
                                    <Typography className="time-text">{elapsedTime}</Typography>
                                    <Typography className="time-divider">/</Typography>
                                    <Typography className="time-text">{duration}</Typography>
                                </Box>
                            )}

                            <Box
                                className={`progressBar flex ${
                                    isInitialExperience && 'full-width-progressBar'
                                }`}>
                                {windowDimensions.width <= BREAKPOINTS_VALUE.TABLET && (
                                    <Box
                                        className={`time flex f-v-center ${
                                            isInitialExperience && 'add-spacing'
                                        }`}>
                                        <Typography className="time-text">{elapsedTime}</Typography>
                                        <Typography className="time-divider">/</Typography>
                                        <Typography className="time-text">{duration}</Typography>
                                    </Box>
                                )}
                                <Slider
                                    value={elapsedTimeInSeconds}
                                    max={mediaLengthInSeconds}
                                    classes={{
                                        track: 'slider-track',
                                        rail: 'slider-rail',
                                        thumb: 'slider-thumb'
                                    }}
                                    aria-label="Default"
                                    valueLabelDisplay="off"
                                    onChange={async (e, value) => {
                                        if (mediaType === MEDIA_TYPE.AUDIO) {
                                            playerRef.current.currentTime = value;
                                        } else {
                                            playerRef?.current &&
                                                playerRef.current.setCurrentTime(value);
                                        }
                                        setElapsedTimeInSeconds(value);
                                    }}
                                />
                            </Box>

                            {windowDimensions.width >= BREAKPOINTS_VALUE.TABLET &&
                                !isInitialExperience && (
                                    <Box className="flex f-h-center f-v-center full-width chapters-repeat-container">
                                        {false && (
                                            <Box
                                                onClick={() => {
                                                    if (mediaType === MEDIA_TYPE.VIDEO)
                                                        repeat
                                                            ? playerRef.current.setLoop(false)
                                                            : playerRef.current.setLoop(true);
                                                    setRepeat((prevState) => !prevState);
                                                }}
                                                className="hover"
                                                height={24}
                                                width={24}
                                                style={{ cursor: 'pointer', margin: '0 5px' }}>
                                                <RepeatSVG fill={repeat ? '#BC1218' : false} />
                                            </Box>
                                        )}
                                        <TaoScrollbar
                                            keyId="chapters"
                                            horizontalScroll
                                            scrollLeft={scrollChapterLeft}>
                                            <Box
                                                className="flex full-width "
                                                id="chapter-selected-scroll"
                                                height={30}>
                                                {contentDetails?.chapters?.map((item, index) => (
                                                    <Typography
                                                        key={item?.chapterId || index}
                                                        onClick={() => onSelectStep(item)}
                                                        className={`step ${
                                                            selectedStep === item?.chapterId &&
                                                            'selected-step'
                                                        }`}>
                                                        {item?.chapterName}
                                                    </Typography>
                                                ))}
                                            </Box>
                                        </TaoScrollbar>
                                    </Box>
                                )}
                        </Box>
                    </Box>
                )}
            </Box>
        </MeditationMediaPlayerWrapper>
    );
};

export default MeditationMediaPlayer;
