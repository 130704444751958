import { isNumber } from 'lodash';
import moment from 'moment';
import { CONTENT, PROGRAM, TAO_CARD_TYPE } from './Constants';
import { URL_MEDITATION_CONTENT, URL_PROGRAMS_DETAILS } from './Paths';

export function isIEBrowser() {
    // BROWSER CHECK VARIABLES
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    const msie11 = ua.indexOf('Trident/');
    // const msedge = ua.indexOf('Edge/');
    return msie > 0 || msie11 > 0;
    // const isEdge = msedge > 0;
}

export function numberFormatter(num) {
    if (num > 999 && num < 1000000) {
        return (num / 1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
        return (num / 1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million
    } else if (num < 900) {
        return num; // if value < 1000, nothing to do
    }
}

export function numberFormatterWithoutSuffixValue(num, isToFixed) {
    if (num > 999 && num < 1000000) {
        return (num / 1000).toFixed(isToFixed ? isToFixed : 0); // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
        return (num / 1000000).toFixed(isToFixed ? isToFixed : 0); // convert to M for number from > 1 million
    } else if (num < 900) {
        return num; // if value < 1000, nothing to do
    }
}

export function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export const numberFormat = (number) => {
    return number.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
    });
};

export const convertMinsToHrsMins = (mins, isString = false, space = false) => {
    let h = Math.floor(mins / 60);
    let m = mins % 60;
    h = isString ? h : h < 10 ? '0' + h : h;
    m = isString ? m : m < 10 ? '0' + m : m;

    let stringValue = '';
    if (h) stringValue += `${h}h${space ? ' ' : ''}`;
    if (m) stringValue += `${m}m`;
    return isString ? stringValue : `${h}:${m}`;
};

export const getTotalTimeInMins = (seconds, hoursNecessary = false) => {
    seconds = Number(seconds);
    let h = Math.floor(seconds / 3600);
    let m = Math.floor((seconds % 3600) / 60);
    let s = Math.floor((seconds % 3600) % 60);
    let hDisplay = h < 10 ? '0' + h : h;
    let mDisplay = m < 10 ? '0' + m : m;
    let sDisplay = s < 10 ? '0' + s : s;

    let time = '';
    if (parseInt(hDisplay)) time += `${hDisplay}:`;
    if (hoursNecessary && !parseInt(hDisplay)) time += `${hDisplay}:`;
    time += `${mDisplay}:`;
    time += `${sDisplay}`;

    return time;
};

export const isEmpty = (obj) => {
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
    }
    return true;
};

export const digitsFormatter = (num) => {
    let formatter = Intl.NumberFormat('en', { notation: 'compact' });
    return isNumber(num) ? formatter.format(num) : 0;
};

export const convertHrsMinToSeconds = (time) => {
    let hms = time;
    let timeSplit = hms.split(':');
    return +timeSplit[0] * 60 * 60 + +timeSplit[1] * 60 + +timeSplit[2];
};

export const convertHrsMinToMins = (time) => {
    let hms = time;
    let timeSplit = hms.split(':');
    return +timeSplit[0] * 60 + +timeSplit[1];
};

export const getHrsAndMinStringFromMin = (
    hrsAndMin = '00:00',
    isString = false,
    space = false,
    split = false
) => {
    let responseConvertMinsToHrsMins = '0m';
    let responseConvertHrsMinToMins = convertHrsMinToMins(hrsAndMin);

    if (!responseConvertHrsMinToMins) {
        if (split) return '0';
        else return '0m';
    }
    responseConvertMinsToHrsMins = convertMinsToHrsMins(
        responseConvertHrsMinToMins || 0,
        isString,
        space
    );

    if (responseConvertMinsToHrsMins && split)
        responseConvertMinsToHrsMins = responseConvertMinsToHrsMins?.split('m');

    return responseConvertMinsToHrsMins;
};

export const detectBrowser = () => {
    if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) !== -1) {
        return 'Opera';
    } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
        return 'Google Chrome';
    } else if (navigator.userAgent.indexOf('Safari') !== -1) {
        return 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
        return 'Firefox';
    } else if (navigator.userAgent.indexOf('MSIE') !== -1 || !!document.documentMode === true) {
        return 'IE'; //crap
    } else {
        return '';
    }
};

export const detectOS = () => {
    let OSName = '';
    if (window.navigator.userAgent.indexOf('Windows NT 10.0') !== -1) OSName = 'Windows 10';
    if (window.navigator.userAgent.indexOf('Windows NT 6.3') !== -1) OSName = 'Windows 8.1';
    if (window.navigator.userAgent.indexOf('Windows NT 6.2') !== -1) OSName = 'Windows 8';
    if (window.navigator.userAgent.indexOf('Windows NT 6.1') !== -1) OSName = 'Windows 7';
    if (window.navigator.userAgent.indexOf('Windows NT 6.0') !== -1) OSName = 'Windows Vista';
    if (window.navigator.userAgent.indexOf('Windows NT 5.1') !== -1) OSName = 'Windows XP';
    if (window.navigator.userAgent.indexOf('Windows NT 5.0') !== -1) OSName = 'Windows 2000';
    if (window.navigator.userAgent.indexOf('Mac') !== -1) OSName = 'Mac/iOS';
    if (window.navigator.userAgent.indexOf('X11') !== -1) OSName = 'UNIX';
    if (window.navigator.userAgent.indexOf('Linux') !== -1) OSName = 'Linux';

    return OSName;
};

export const uniqueWithTypeAndId = (arr, keyProps) => {
    const result = arr.map((entry) => {
        const key = keyProps.map((k) => entry[k]).join('|');
        return [key, entry];
    });
    const map = new Map(result);
    return Array.from(map.values());
};

export const EMAIL_REGEX = /^(?!.{81})(?:\w+[.-])*\w{1,50}@\w{1,25}(?:[.-]\w+)*\.\w{2,3}$/;

export const programProgressDataFormat = (programDate) => {
    if (!programDate) return;

    let date = moment(programDate);
    if (moment()?.diff(date, 'days') >= 2) {
        return date?.format('MMM DD');
    }
    return date?.calendar?.().split?.(' ')?.[0];
};

export const convertHoursMinsSecondsToReadableString = (time) => {
    if (!time) return;

    let hours = parseInt(time.split(':')[0]);
    let minutes = parseInt(time.split(':')[1]);
    let string = '';

    if (hours) string += hours + ` ${hours > 1 ? 'hours' : 'hour'}  `;
    if (minutes) string += minutes + ` ${minutes > 1 ? 'mins' : 'min'}  `;

    return string;
};

const createImage = (url) =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', (error) => reject(error));
        image.setAttribute('crossOrigin', 'anonymous');
        image.src = url;
    });

export const getCroppedImg = async (imageSrc, crop, imageType) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = image?.width;
    canvas.height = image?.height;

    ctx.drawImage(
        image,
        crop.x,
        crop.y,
        crop.width,
        crop.height,
        0,
        0,
        canvas.width,
        canvas.height
    );

    return new Promise((resolve) => {
        canvas.toBlob((blob) => {
            resolve(blob);
        }, imageType);
    });
};

export const programTimeFormat = (timeFormat, time) => {
    let string = '';
    let hours = time.split(':')[0];
    let minutes = time.split(':')[1];

    string += hours;
    if (parseInt(minutes)) string += `:${minutes} `;

    return `${string}${timeFormat?.split(' ')?.[1]}`;
};

export const handleContentRedirection = (value, navigateTo) => {
    const {
        subscription: { isAccessible }
    } = value;

    let state = {};
    if (value?.type === CONTENT) state = { contentId: value?.id };
    else state = { programId: value?.id };

    return navigateTo(
        value?.type === CONTENT ? URL_MEDITATION_CONTENT : URL_PROGRAMS_DETAILS,
        {
            ...state
        },
        value?.type === CONTENT ? isAccessible : true
    );
};

export const dateFormatter = (givenDate) => {
    if (givenDate) {
        const options = { year: 'numeric', month: 'short', day: '2-digit' };
        const date = new Date(givenDate);
        return date.toLocaleDateString('en-US', options).toUpperCase();
    }
    return;
};
