import styled from 'styled-components';
import { Box } from '@mui/material';
import { COLORS, FONTS, responsive } from 'Styles/Constants';
import Flower from 'Assets/images/Signup/sales-page-flower-bg.svg';

export const BeginExperienceWrapper = styled(Box)`
    width: 100%;
    overflow: hidden;
    overflow-y: auto;

    .full-width-section {
        height: 100%;
        padding: 0 0 300px 0;

        &.remove-padding {
            padding: 0;
        }

        .container {
            width: 50%;
            margin: 0 auto;

            ${responsive.DESKTOP`
                width: 60%;
            `}

            ${responsive.LAPTOP`
                width: 80%;
            `}

            ${responsive.MOBILE`
                width: 90%;
            `}

            .description {
                color: ${COLORS.TEXT_DARK};
                font-size: 18px;
                /* margin: -20px 0; */

                .sub-text {
                    font-family: ${FONTS.PRIMARY_BOLD};
                }
            }
        }
    }
    .footer-container {
        position: fixed;
        width: 100%;
        bottom: 0;
        height: 200px;
        z-index: 3;
        background: linear-gradient(180deg, rgba(75, 161, 188, 0) 0%, rgba(75, 161, 188, 0.7) 100%);
        background-color: ${COLORS.WHITE};

        &.shadow {
            box-shadow: 0px -10px 15px 0px rgba(217, 217, 217, 1);
        }

        .shadow {
            height: 20px;
            position: sticky;
            width: 100%;
            top: 60px;
        }

        .redirection-links {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: absolute;
            bottom: 50px;
            transform: translate(-50%, -0%);
            left: 50%;
            z-index: 2;

            ${responsive.DESKTOP`
                width: 60%;
            `}

            ${responsive.LAPTOP`
                width: 80%;
            `}

            ${responsive.MOBILE`
                width: 90%;
            `}

            .redirection-btn {
                background: rgba(255, 255, 255, 0.9);
                box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
                backdrop-filter: blur(10px);
                border-radius: 12px;
                height: 60px;
                width: 370px;
                color: ${COLORS.PRIMARY_WARM};
                font-size: 16px;
                text-transform: uppercase;
                font-family: ${FONTS.PRIMARY_SEMI_BOLD};

                ${responsive.MOBILE`
                    width: 200px;
                `}
            }

            .footer-text {
                color: ${COLORS.WHITE};
                font-family: ${FONTS.PRIMARY};
                font-weight: 700;
                font-size: 18px;
                margin: 10px 0 0;
                text-decoration: underline;

                &:hover {
                    cursor: pointer;
                }

                &.divider {
                    padding: 0 5px;
                }
            }
        }

        .flower-image {
            background-image: url(${Flower});
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            bottom: 0;
            right: 0;
            height: 180px;
            width: 300px;
            z-index: 1;

            ${responsive.PHABLET`
                height: 150px;
                width: 250px;
            `}
        }
    }
`;
