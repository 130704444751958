import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
// MATERIAL
import { Box, Card, Chip, IconButton, Typography } from '@mui/material';
// COMMON
import TaoLoader from 'Components/common/TaoBackdropLoader';
import TaoSlider from 'Components/common/TaoSlider';
import { Footer } from 'Components/common';
// WRAPPER
import { HowToMeditateWrapper } from './HowToMeditate.style';
// ICON
import PlayButton from 'Assets/images/Common/play-button.svg';
import { BREAKPOINTS_VALUE } from 'Styles/Constants';
import MeditationCommonCard from 'Components/common/MeditationCard/MeditationCommonCard';
// WATERMARK
import TaoCalligraphyWatermark from 'Components/common/TaoCalligraphyWatermark';
// HELPER
import { convertHoursMinsSecondsToReadableString, getWindowDimensions } from 'Helpers';
import Api from 'Helpers/ApiHandler';
import { API_URL, URL_MEDITATION_READ, URL_HOW_TO_MEDITATE_CONTENT } from 'Helpers/Paths';
import { SLIDER_PER_PAGE } from 'Helpers/Constants';

// DOMPURIFY
import DOMPurify from 'dompurify';
// ROUTER
import { useNavigate } from 'react-router-dom';

const BUTTON_LIST = [
    { key: 1, value: 'Watch' },
    { key: 2, value: 'Read' }
];
const HOW_TO_MEDITATE_API_TYPE = {
    WATCH: 'Watch',
    READ: 'Read'
};
// const SLIDER_PER_PAGE = 4;

const HowToMeditate = () => {
    const API = useMemo(() => new Api(), []);
    const navigate = useNavigate();

    const divScrollRef = useRef(null);
    const watchSliderRef = useRef();
    const readSliderRef = useRef();

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [selectedChip, setSelectedChip] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [watch, setWatch] = useState([]);
    const [read, setRead] = useState([]);
    const [pagination, setPagination] = useState({
        readCurrentPage: 0,
        watchCurrentPage: 0,
        readTotalRecords: 0,
        watchTotalRecords: 0
    });

    const getHowToMeditateData = useCallback(
        async (
            type = '',
            paginationData = {
                current_page: 0,
                per_page: SLIDER_PER_PAGE,
                search: '',
                sort: {}
            }
        ) => {
            let response = await API.post(API_URL.HOW_TO_MEDITATE, {
                params: { type },
                data: paginationData
            });

            if (response) {
                if (response?.data?.data?.watch) {
                    setWatch((prev) => {
                        let arr = [...prev, ...response?.data?.data?.watch?.list];
                        return [...new Map(arr.map((item) => [item['contentId'], item])).values()];
                    });
                }

                if (response?.data?.data?.read) {
                    setRead((prev) => {
                        let arr = [...prev, ...response?.data?.data?.read?.list];
                        return [...new Map(arr.map((item) => [item['contentId'], item])).values()];
                    });
                }

                setPagination((prevState) => {
                    return {
                        ...prevState,
                        readCurrentPage:
                            type === '' || type === HOW_TO_MEDITATE_API_TYPE.READ
                                ? prevState.readCurrentPage + 1
                                : prevState.readCurrentPage,
                        watchCurrentPage:
                            type === '' || type === HOW_TO_MEDITATE_API_TYPE.WATCH
                                ? prevState.watchCurrentPage + 1
                                : prevState.watchCurrentPage,
                        readTotalRecords:
                            response?.data?.data?.read?.totalRecords || prevState?.readTotalRecords,
                        watchTotalRecords:
                            response?.data?.data?.watch?.totalRecords ||
                            prevState?.watchTotalRecords
                    };
                });
                setIsLoading(false);
            }
        },
        [API]
    );

    // const handleNextArrowClick = (sliderType) => {
    //     let paginationData = {},
    //         isNeedToCallAPI = false;

    //     if (sliderType === HOW_TO_MEDITATE_API_TYPE.WATCH) {
    //         isNeedToCallAPI = watch.length < pagination.watchTotalRecords;
    //         paginationData = {
    //             current_page: pagination?.watchCurrentPage,
    //             per_page: SLIDER_PER_PAGE,
    //             sort: {},
    //             search: ''
    //         };
    //     }

    //     if (sliderType === HOW_TO_MEDITATE_API_TYPE.READ) {
    //         isNeedToCallAPI = read.length < pagination.readTotalRecords;
    //         paginationData = {
    //             current_page: pagination?.readCurrentPage,
    //             per_page: SLIDER_PER_PAGE,
    //             sort: {},
    //             search: ''
    //         };
    //     }

    //     isNeedToCallAPI && getHowToMeditateData(sliderType, paginationData);
    // };

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        getHowToMeditateData();
    }, [getHowToMeditateData]);

    const loadMoreButtonJSX = (type, paginationObj) => (
        <Box
            className="load-more flex f-column f-v-center f-h-center hover"
            onClick={() => getHowToMeditateData(type, paginationObj)}>
            <Typography className="load-more-text">Load More</Typography>
            <Box className="load-more-arrow" />
        </Box>
    );

    return (
        <>
            {windowDimensions.width >= BREAKPOINTS_VALUE.TABLET && <TaoCalligraphyWatermark />}
            <HowToMeditateWrapper>
                <Box className="bg-image" />
                <TaoLoader isLoading={isLoading} />

                {windowDimensions.width < BREAKPOINTS_VALUE.TABLET && (
                    <Box className="mobileContainer full-width">
                        <Box className="detailsContainer" ref={divScrollRef}>
                            <Box className="spacing">
                                <Typography className="title">How-to Guides</Typography>
                            </Box>

                            <Box className="flex spacing">
                                {BUTTON_LIST.map((chip) => (
                                    <Chip
                                        sx={{ color: 'text.dark' }}
                                        onClick={() => setSelectedChip(chip.key)}
                                        key={chip.key}
                                        className={
                                            'goal-chip ' +
                                            (chip.key === selectedChip
                                                ? 'selected-goal'
                                                : 'non-selected-goal')
                                        }
                                        size="medium"
                                        color="primary"
                                        variant={chip.key === selectedChip ? 'outlined' : 'filled'}
                                        label={chip.value}
                                    />
                                ))}
                            </Box>

                            {selectedChip === 1 && (
                                <React.Fragment>
                                    {!isLoading && watch?.length > 0 && (
                                        <React.Fragment>
                                            <Box>
                                                {watch.map((value, index) => (
                                                    <Box
                                                        key={value.contentId}
                                                        className={`section spacing ${
                                                            index % 2 === 1 && 'section-color'
                                                        }`}>
                                                        <Box className="image-section flex f-v-center">
                                                            <img
                                                                src={value.thumbnailImage}
                                                                className="image"
                                                                alt=""
                                                            />
                                                            <IconButton
                                                                className="play-icon-button "
                                                                onClick={() =>
                                                                    navigate(
                                                                        URL_HOW_TO_MEDITATE_CONTENT,
                                                                        {
                                                                            state: {
                                                                                contentId:
                                                                                    value?.contentId
                                                                            }
                                                                        }
                                                                    )
                                                                }>
                                                                <img
                                                                    src={PlayButton}
                                                                    alt=""
                                                                    className="play-icon"
                                                                />
                                                            </IconButton>
                                                        </Box>

                                                        <Box className="content-section">
                                                            <Typography className="media-text title-text">
                                                                {value.title}
                                                            </Typography>

                                                            <Typography
                                                                className="description-media-text description-text"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: DOMPurify.sanitize(
                                                                        value?.description,
                                                                        {
                                                                            ALLOWED_TAGS: [
                                                                                'b',
                                                                                'a',
                                                                                'em',
                                                                                'strong',
                                                                                'i'
                                                                            ]
                                                                        }
                                                                    )
                                                                }}></Typography>

                                                            <Typography className="media-text min-text">
                                                                {convertHoursMinsSecondsToReadableString(
                                                                    value?.time || '00:00'
                                                                )}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                ))}
                                            </Box>
                                            {watch?.length &&
                                                watch?.length !== pagination?.watchTotalRecords &&
                                                loadMoreButtonJSX(HOW_TO_MEDITATE_API_TYPE.WATCH, {
                                                    current_page: pagination?.watchCurrentPage,
                                                    per_page: SLIDER_PER_PAGE,
                                                    sort: {},
                                                    search: ''
                                                })}
                                        </React.Fragment>
                                    )}
                                    {!isLoading && !watch?.length && (
                                        <Typography className="flex f-v-center f-h-center no-content">
                                            No Watch Data Found
                                        </Typography>
                                    )}
                                </React.Fragment>
                            )}

                            {selectedChip === 2 && (
                                <React.Fragment>
                                    {!isLoading && read?.length > 0 && (
                                        <React.Fragment>
                                            <Box className="mobile-read-section flex">
                                                {read.map((value) => (
                                                    <MeditationCommonCard
                                                        key={value.contentId}
                                                        title={value.title}
                                                        image={value.thumbnailImage}
                                                        description={value.description}
                                                        mins={value.time}
                                                        url={URL_MEDITATION_READ}
                                                        value={value}
                                                        max={30}
                                                        readNow={true}
                                                    />
                                                ))}
                                            </Box>

                                            {read?.length &&
                                                read?.length !== pagination?.readTotalRecords &&
                                                loadMoreButtonJSX(HOW_TO_MEDITATE_API_TYPE?.READ, {
                                                    current_page: pagination?.readCurrentPage,
                                                    per_page: SLIDER_PER_PAGE,
                                                    sort: {},
                                                    search: ''
                                                })}
                                        </React.Fragment>
                                    )}
                                    {!isLoading && !read?.length && (
                                        <Typography className="flex f-v-center f-h-center no-content">
                                            No Read Data Found
                                        </Typography>
                                    )}
                                </React.Fragment>
                            )}
                        </Box>
                    </Box>
                )}

                {/* Web View */}
                {windowDimensions.width >= BREAKPOINTS_VALUE.TABLET && (
                    <Box className="container">
                        <Box>
                            <Typography className="header-text">How-to Guides</Typography>
                        </Box>

                        <Box className="watch-box">
                            <Typography className="title">Watch</Typography>

                            <Box className="content-slider-wrapper">
                                {!isLoading && watch?.length > 0 && (
                                    <TaoSlider
                                        ref={watchSliderRef}
                                        gap={10}
                                        showShadow={false}
                                        cardHeight={120}
                                        slidesToShow={2}>
                                        {watch?.map((value) => {
                                            return (
                                                <Box
                                                    key={value.contentId}
                                                    className="card-content-container">
                                                    <Card className="meditation-card">
                                                        <Box
                                                            className="card-content hover"
                                                            onClick={() =>
                                                                navigate(
                                                                    URL_HOW_TO_MEDITATE_CONTENT,
                                                                    {
                                                                        state: {
                                                                            contentId:
                                                                                value?.contentId
                                                                        }
                                                                    }
                                                                )
                                                            }>
                                                            <img
                                                                className="watch-content-image"
                                                                src={value.contentImage}
                                                                alt=""
                                                            />
                                                            <IconButton className="play-icon-button">
                                                                <img
                                                                    src={PlayButton}
                                                                    alt=""
                                                                    className="play-icon"
                                                                />
                                                            </IconButton>
                                                        </Box>
                                                    </Card>
                                                    <Box
                                                        className="flex f-column full-width"
                                                        style={{ paddingLeft: '10px' }}>
                                                        <Typography className="media-text title-text">
                                                            {value.title}
                                                        </Typography>

                                                        <Typography
                                                            className="description-media-text description-text"
                                                            dangerouslySetInnerHTML={{
                                                                __html: DOMPurify.sanitize(
                                                                    value?.description,
                                                                    {
                                                                        ALLOWED_TAGS: [
                                                                            'b',
                                                                            'a',
                                                                            'em',
                                                                            'strong',
                                                                            'i'
                                                                        ]
                                                                    }
                                                                )
                                                            }}></Typography>

                                                        <Typography className="media-text min-text">
                                                            {convertHoursMinsSecondsToReadableString(
                                                                value?.time || '00:00'
                                                            )}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            );
                                        })}
                                    </TaoSlider>
                                )}

                                {!isLoading && !watch?.length && (
                                    <Typography className="flex f-v-center f-h-center watch-empty-content">
                                        No Watch Data Found
                                    </Typography>
                                )}
                            </Box>
                        </Box>

                        <Box className="read-box">
                            <Typography className="title">Read</Typography>
                            <Box className="content-slider-wrapper">
                                {!isLoading && read?.length > 0 && (
                                    <TaoSlider
                                        ref={readSliderRef}
                                        gap={10}
                                        cardHeight={200}
                                        showShadow={false}
                                        slidesToShow={2}>
                                        {read.map((value) => (
                                            <MeditationCommonCard
                                                key={value.contentId}
                                                title={value.title}
                                                image={value.thumbnailImage}
                                                description={value.description}
                                                mins={value.time}
                                                url={URL_MEDITATION_READ}
                                                value={value}
                                                max={30}
                                                readNow={true}
                                            />
                                        ))}
                                    </TaoSlider>
                                )}
                                {!isLoading && !read?.length && (
                                    <Typography className="flex f-v-center f-h-center no-content">
                                        No Read Data Found
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    </Box>
                )}
                {/* </TaoScrollbar> */}
                {windowDimensions.width >= BREAKPOINTS_VALUE.TABLET && (
                    <Box className="footer-wrapper">
                        <Footer
                            isBottomZero={true}
                            showWhiteBackground={false}
                            topBorderColor="gray"
                        />
                    </Box>
                )}
            </HowToMeditateWrapper>
        </>
    );
};

export default HowToMeditate;
