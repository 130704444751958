import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

//CUSTOM
import { WellnessLibraryWrapper } from './WellnessLibrary.style';
import { URL_WELLNESS_CATEGORY, URL_SEARCH } from 'Helpers/Paths';

//ICONS
import Ribbon from 'Assets/images/Common/ribbon.svg';
import {
    WellnessCategoryLoader,
    TitleLoader
} from 'Components/common/ShimmerEffectLoader/ShimmerEffectLoader';
import { updateMenu } from 'Redux/Sidebar/Actions';
import { useDispatch } from 'react-redux';
import SeeAllTypography from 'Components/common/SeeAll/Typography/SeeAllTypography';
import usePermissions from 'Components/common/Permissions';
import { ACCESS_LEVELS_MODULEs } from 'Helpers/Constants';
import { SubscriptionDarkLock } from 'Components/common/SubscriptionLock';
import TaoSlider from 'Components/common/TaoSlider';
import { useRef } from 'react';

export const WellnessLibrary = ({ wellnessCategoryList, wellnessCategoryLoading }) => {
    const navigate = useNavigate();
    const libraryPermission = usePermissions(ACCESS_LEVELS_MODULEs.CONTENT_LIBRARY);
    const searchPermission = usePermissions(ACCESS_LEVELS_MODULEs.SEARCH);
    const dispatch = useDispatch();
    const wellnessRef = useRef(null);

    return (
        <React.Fragment>
            <WellnessLibraryWrapper>
                <Box className="wellness-title" id="wellness-title-id">
                    <Typography color="primary" className="wellness-title-text">
                        Wellness Library
                    </Typography>

                    {libraryPermission?.permissionLoaded && (
                        <React.Fragment>
                            {!libraryPermission?.canAccess && (
                                <SubscriptionDarkLock
                                    height={20}
                                    customStyle={{ margin: '0 0 0 5px' }}
                                />
                            )}
                            <SeeAllTypography
                                redirectionURL={URL_SEARCH}
                                disableArrow={!searchPermission?.canAccess}
                            />
                        </React.Fragment>
                    )}
                </Box>

                <Box
                    className={`wellness-list ${
                        !libraryPermission?.canAccess && 'not-accessible'
                    }`}>
                    {wellnessCategoryLoading && (
                        <TaoSlider
                            gap={5}
                            ref={wellnessRef}
                            cardHeight={80}
                            slidesToShow={6}
                            showShadow={false}>
                            {[1, 2, 3, 4, 5, 6]?.map((value, index) => (
                                <Box key={index} className="wellness-list-loader-wrapper">
                                    <Box className="loader-circle flex f-h-center">
                                        <WellnessCategoryLoader
                                            width={60}
                                            height={60}
                                            fillWidth={60}
                                            fillHeight={60}
                                            customStyle={{ display: 'flex', alignItems: 'center' }}
                                        />
                                    </Box>
                                    <TitleLoader
                                        width={40}
                                        height={10}
                                        fillWidth={40}
                                        fillHeight={10}
                                        marginLeft={0}
                                    />
                                </Box>
                            ))}
                        </TaoSlider>
                    )}
                    {!wellnessCategoryLoading && (
                        <TaoSlider
                            gap={5}
                            ref={wellnessRef}
                            cardHeight={80}
                            slidesToShow={6}
                            showShadow={false}>
                            {wellnessCategoryList?.map((value, index) => (
                                <Box
                                    key={index}
                                    className="wellness-content"
                                    onClick={() => {
                                        dispatch(updateMenu('wellness'));
                                        navigate(URL_WELLNESS_CATEGORY, {
                                            state: { wellnessCategoryDetails: value }
                                        });
                                    }}>
                                    <IconButton className="wellness-icon-button">
                                        <Box
                                            className="wellness-icon"
                                            style={{
                                                backgroundImage: `url(${value?.icon})`
                                            }}
                                        />
                                        {value?.isFeatured && (
                                            <Box
                                                className="wellness-ribbon-icon"
                                                style={{
                                                    backgroundImage: `url(${Ribbon})`
                                                }}
                                            />
                                        )}
                                    </IconButton>
                                    <Typography color="primary" className="wellness-category-name">
                                        {value?.categoryName}
                                    </Typography>
                                </Box>
                            ))}
                        </TaoSlider>
                    )}
                </Box>
            </WellnessLibraryWrapper>
        </React.Fragment>
    );
};
