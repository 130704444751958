import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SalesPageOneWrapper } from './SalesPageOne.style';
import { Box, Button, Typography } from '@mui/material';
import TaoCalligraphyWatermark from 'Components/common/TaoCalligraphyWatermark';
import { SlickDotSlider } from 'Components/common/TaoSlider/SlickDotSlider';
import { hideTopBarInteractionButton } from 'Redux/App/Actions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { API_URL, URL_LOGIN, URL_SALES_PAGE_TWO, URL_SIGN_UP } from 'Helpers/Paths';
import Api from 'Helpers/ApiHandler';
import { hideSideBar, showSideBar } from 'Redux/Sidebar/Actions';
import { useSelector } from 'react-redux';

const SalesPageOne = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isLoggedIn = useSelector((state) => state.Auth.isLoggedIn);
    const [slides, setSlides] = useState([]);

    const API = useMemo(() => new Api(), []);

    const fetchSlider = useCallback(async () => {
        const response = await API.get(API_URL.TESTIMONIAL);
        if (response?.data) setSlides(response?.data?.data);
    }, [API]);

    const handleNavigate = (url, state = {}) => {
        if (url) {
            navigate(url, {
                state
            });
        }
    };

    useEffect(() => {
        fetchSlider();
        dispatch(hideTopBarInteractionButton(false));
    }, [dispatch, fetchSlider]);

    useEffect(() => {
        if (isLoggedIn) dispatch(hideSideBar());
        return () => {
            if (isLoggedIn) dispatch(showSideBar());
        };
    }, [dispatch, isLoggedIn]);

    return (
        <SalesPageOneWrapper>
            <Box className="bg-color" />
            <TaoCalligraphyWatermark />
            <Box className="container">
                <SlickDotSlider>
                    {slides.map((item) => (
                        <React.Fragment key={item?.id}>
                            <Typography className="title">{item?.title}</Typography>
                            <Typography className="description">{item?.description}</Typography>
                        </React.Fragment>
                    ))}
                </SlickDotSlider>
            </Box>
            <Box className="footer-container">
                <Button
                    className="redirection-btn"
                    onClick={() => {
                        navigate(URL_SALES_PAGE_TWO);
                    }}>
                    GET STARTED
                </Button>
                <Box className="flex f-h-center">
                    <Typography className="footer-text" onClick={() => handleNavigate(URL_SIGN_UP)}>
                        Create Account
                    </Typography>
                    <Typography className="footer-text divider">|</Typography>
                    <Typography className="footer-text" onClick={() => handleNavigate(URL_LOGIN)}>
                        Login
                    </Typography>
                </Box>
            </Box>
            <Box className="flower-image" />
        </SalesPageOneWrapper>
    );
};

export default SalesPageOne;
