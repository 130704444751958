export const ACTION_TYPES = {
    SHOW_TOP_BAR: 'SHOW_TOP_BAR',
    HIDE_TOP_BAR: 'HIDE_TOP_BAR',

    SHOW_SUBSCRIPTION_MODAL: 'SHOW_SUBSCRIPTION_MODAL',
    HIDE_SUBSCRIPTION_MODAL: 'HIDE_SUBSCRIPTION_MODAL',

    SHOW_TOP_BAR_WITH_TRANSITION: 'SHOW_TOP_BAR_WITH_TRANSITION',
    HIDE_TOP_BAR_WITH_TRANSITION: 'HIDE_TOP_BAR_WITH_TRANSITION',

    SHOW_FOOTER: 'SHOW_FOOTER',
    HIDE_FOOTER: 'HIDE_FOOTER',

    SHOW_USER_MENU_TOGGLE: 'SHOW_USER_MENU_TOGGLE',

    SHOW_LOGIN_PAGE_HEADER: 'SHOW_LOGIN_PAGE_HEADER',
    SHOW_QUESTIONNAIRE_PAGE_HEADER: 'SHOW_QUESTIONNAIRE_PAGE_HEADER',
    SHOW_NORMAL_HEADER: 'SHOW_NORMAL_HEADER',

    TOAST_MESSAGE_ENABLE: 'TOAST_MESSAGE_ENABLE',
    TOAST_MESSAGE_DISABLE: 'TOAST_MESSAGE_DISABLE',

    CONTENT_DETAIL: 'CONTENT_DETAIL',
    HIDE_INTERACTION_BUTTON: 'HIDE_INTERACTION_BUTTON',
    SCROLL_INTO_VIEW_ID: 'SCROLL_INTO_VIEW_ID',

    SET_PERMISSIONS: 'SET_PERMISSIONS'
};

export const showTopBar = () => {
    return {
        type: ACTION_TYPES.SHOW_TOP_BAR
    };
};

export const hideTopBar = () => {
    return {
        type: ACTION_TYPES.HIDE_TOP_BAR
    };
};

export const showUserMenu = (data) => {
    return {
        type: ACTION_TYPES.SHOW_USER_MENU_TOGGLE,
        data
    };
};

export const showTopBarWithTransition = () => {
    return {
        type: ACTION_TYPES.SHOW_TOP_BAR_WITH_TRANSITION
    };
};

export const hideTopBarWithTransition = () => {
    return {
        type: ACTION_TYPES.HIDE_TOP_BAR_WITH_TRANSITION
    };
};

export const showFooter = () => {
    return {
        type: ACTION_TYPES.SHOW_FOOTER
    };
};

export const hideFooter = () => {
    return {
        type: ACTION_TYPES.HIDE_FOOTER
    };
};

export const showToast = (message, variant, duration = 0) => {
    return {
        type: ACTION_TYPES.TOAST_MESSAGE_ENABLE,
        message: message || '',
        variant: variant || '',
        duration: duration
    };
};
export const hideToast = () => {
    return {
        type: ACTION_TYPES.TOAST_MESSAGE_DISABLE
    };
};

export const handleTopBarInteractionDetail = (interactionDetail = {}) => {
    return {
        type: ACTION_TYPES.CONTENT_DETAIL,
        interactionDetail
    };
};

export const handleScrollToViewId = (scrollIntoViewId = false) => {
    return {
        type: ACTION_TYPES.SCROLL_INTO_VIEW_ID,
        scrollIntoViewId
    };
};

export const showSubscriptionModal = () => {
    return {
        type: ACTION_TYPES.SHOW_SUBSCRIPTION_MODAL
    };
};

export const hideSubscriptionModal = () => {
    return {
        type: ACTION_TYPES.HIDE_SUBSCRIPTION_MODAL
    };
};

export const hideTopBarInteractionButton = (hideInteractionButton) => {
    return {
        type: ACTION_TYPES?.HIDE_INTERACTION_BUTTON,
        hideInteractionButton
    };
};

export const setPermissions = (permissions) => {
    return {
        type: ACTION_TYPES?.SET_PERMISSIONS,
        permissions
    };
};

// export const showLoginPageHeader = () => {
//     return {
//         type: ACTION_TYPES.SHOW_LOGIN_PAGE_HEADER
//     }
// }

// export const showQuestionnaireHeader = () => {
//     return {
//         type: ACTION_TYPES.SHOW_QUESTIONNAIRE_PAGE_HEADER
//     }
// }

// export const showNormalHeader = () => {
//     return {
//         type: ACTION_TYPES.SHOW_NORMAL_HEADER
//     }
// }
