import styled from 'styled-components';
import SignupBackground from 'Assets/images/Signup/signup-background.webp';
import CheckBoxChecked from 'Assets/images/Common/checkbox-check-mark.svg';

// import AppleIcon from "Assets/images/Signup/apple-icon.svg"
import { COLORS, FONTS, responsive } from 'Styles/Constants';
import { LinearProgress, Box } from '@mui/material';
export const SignupWrapper = styled.div`
    background: url(${SignupBackground});
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;

    .container {
        display: flex;
        flex-direction: row;
        justify-content: center;

        position: relative;

        overflow: auto;
        z-index: 100;
        align-items: center;
        height: 90vh;
    }

    .signup-container {
        display: flex;
        flex-direction: column;
        width: 25%;
        min-height: 55%;

        ${responsive.DESKTOP`
            width: 35%;
        `}
        ${responsive.LAPTOP`
            width: 37%;
        `}

        ${responsive.TABLET`
            width: 55%;
        `}

        ${responsive.PHABLET`
            width: 70%;
        `}

        ${responsive.MOBILE`
            width: 90%;
            justify-content: space-between;
            height: calc(100vh - 90px);
        `}

        .slide-text {
            font-size: 18px;
            font-style: italic;
            color: ${COLORS.TEXT_DARK};
            font-family: ${FONTS.QUINARY};
            line-height: 28px;
            font-weight: 400;
            letter-spacing: 0.01em;
        }
    }

    .title {
        font-size: 40px;
        font-weight: 700;
        font-family: ${FONTS.SECONDARY};
        color: ${COLORS.PRIMARY_DARK};
    }

    .text-field-container {
        width: 48%;
    }

    .policy-div {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;

        .checkbox-root {
            color: ${COLORS.TEXT_DARK};
            padding: 0 10px 0 0;
        }

        .policy-color {
            color: ${COLORS.TEXT_DARK};
            font-size: 16px;

            &.link {
                font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                margin: 0 5px;
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }

    .login-text-container {
        display: flex;
        align-items: center;
        width: fit-content;
        margin-top: min(34px, 5%);

        .login-text {
            color: #656565;
            font-family: ${FONTS.PRIMARY};
            font-size: 16px;
            line-height: 23px;
            letter-spacing: 0em;
            text-align: left;

            .login-link {
                font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                margin-left: 5px;
                cursor: pointer;
                text-decoration: underline;
                text-decoration-color: #656565;
            }
        }
    }

    .checkbox-container {
        margin-top: min(34px, 5%);
        align-items: center;

        .checkbox-text {
            color: #656565;
            font-family: ${FONTS.PRIMARY};
            font-size: 16px;
            line-height: 23px;
            letter-spacing: 0em;
            text-align: left;
        }
    }

    .margin-bottom-spacing {
        margin-bottom: 10px;

        .confirm-password-container {
            display: flex;
            flex-direction: row;
            gap: 6px;
            align-items: center;
            align-content: center;
            justify-content: end;
            margin-top: 10px;

            .text {
                font-family: ${FONTS.PRIMARY};
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: ${COLORS.MEDIUM_GREY};

                &.success {
                    color: #83d841;
                }

                &.danger {
                    color: #d32f2f;
                }
            }
        }
    }

    .button {
        width: 100%;

        &:hover {
            background: linear-gradient(180deg, #cbac69 0%, #b6954f 100%);
        }
    }

    .image {
        position: absolute;
        bottom: 0px;
        background: url(${SignupBackground});
        width: 100%;
        height: 500px;
        overflow: hidden;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        z-index: 1;
    }

    .arrow-icon {
        margin-left: -35px;
        margin-right: 10px;
        margin-top: 7px;

        ${responsive.MOBILE`
            margin-left: 0;
        `}
    }

    .sign-up-options-container {
        background: rgba(232, 232, 232, 0.4);
        backdrop-filter: blur(30px);
        border-radius: 18px;
        padding: 5px;
        margin: 12px 0 0 0;
    }

    .divider-container {
        display: flex;
        flex-direction: row;
        margin-top: 5px;
        align-items: center;
        justify-content: center;
        color: #656565;

        ${responsive.MOBILE`
            color: #fff;
        `}
    }

    .divider {
        height: 2px;
        width: 30%;
        background: linear-gradient(to right, rgba(80, 85, 92, 0), rgba(80, 85, 92, 1)); //#8195b1

        ${responsive.MOBILE`
            width: 27%;
            background: linear-gradient(to right, rgba(255, 255, 255, 0) , rgba(255, 255, 255, 1));
        `}
    }

    .social-icon {
        padding: 12px 50px;
        display: flex;
        flex-direction: row;
        justify-content: center;

        .social-background {
            height: 50px;
            width: 50px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin: 0 10px;

            ${responsive.PHABLET`
                margin: 0 5px;
            `}

            &.google-background {
                background-color: #fff;
            }
            &.facebook-background {
                background-color: #4267b2;
                overflow: hidden;
            }
            &.apple-background {
                background-color: #000;
            }
        }
    }

    .top-margin {
        ${responsive.MOBILE`
            margin-top: 20px;
        `}
    }

    .button-link {
        text-decoration: underline;
        text-transform: capitalize;
    }

    .button-color {
        background: linear-gradient(180deg, #cbac69 0%, #b6954f 100%);
        border-radius: 12px;
        font-family: ${FONTS.PRIMARY_SEMI_BOLD};
        color: ${COLORS.WHITE};
        margin: 5px 0 0;
        height: 60px;
        font-size: 16px;
    }
`;

export const LinearProgressWrapper = styled(LinearProgress)`
    .bg-color {
        background-color: ${(props) => props?.background};
    }
`;

export const PasswordStrengthWrapper = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: space-between;

    .progress-bar-wrapper {
        width: 70%;
    }
    .password-strength-text-wrapper {
        display: flex;
        align-items: center;
        min-width: max-content;
        margin: 0 0 0 10px;

        .password-strength-text {
            font-family: ${FONTS.PRIMARY};
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: ${COLORS.MEDIUM_GREY};
        }
    }
`;

export const CustomIcon = styled('span')`
    width: 24px;
    height: 24px;
    background-color: ${COLORS.WHITE};
    border: 1px solid ${COLORS.WHITE};
    border-radius: 4px;
`;

export const CustomCheckedIcon = styled(CustomIcon)`
    background-color: ${COLORS.WHITE};
    ::before {
        display: block;
        width: 16px;
        height: 12px;
        background-image: url(${CheckBoxChecked});
        background-repeat: no-repeat;
        margin: 5px 0 0 3px;
        content: '';
    }
`;
