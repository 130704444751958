import { Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { hideSubscriptionModal } from 'Redux/App/Actions';
import { SubscriptionModalWrapper } from './subscription.style';
import { ReactComponent as CrossIcon } from 'Assets/images/Common/close-icon.svg';

const Subscription = ({ title, ...props }) => {
    const dispatch = useDispatch();
    const showSubscriptionModal = useSelector((state) => state.App.showSubscriptionModal);

    return (
        <SubscriptionModalWrapper
            open={showSubscriptionModal}
            onClose={() => dispatch(hideSubscriptionModal())}
            classes={{ paper: 'root' }}>
            <CrossIcon
                className="cross-icon hover"
                onClick={() => dispatch(hideSubscriptionModal())}
            />
            <Typography className="text">
                {'Please update your subscription plan from mobile app.'}
            </Typography>
        </SubscriptionModalWrapper>
    );
};

export default Subscription;
