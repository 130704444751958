import { Chip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Footer } from 'Components/common';
import React, { useCallback, useEffect, useMemo } from 'react';
import { ProgramsWrapper /* GetContentDialogWrapper */ } from './Programs.style';
// ICONS
import { ReactComponent as FilledRating } from 'Assets/images/Common/filled-star.svg';
import { ReactComponent as UnFilledRating } from 'Assets/images/Common/outlined-star.svg';
import { ReactComponent as User } from 'Assets/images/Programs/user.svg';
import { ReactComponent as PlaylistIcon } from 'Assets/images/Common/playlist-button.svg';
// import { ReactComponent as Heart } from 'Assets/images/Common/golden-heart.svg';
import WeekCalendar from 'Components/common/WeekCalendar';

import { TaoButton } from 'Components/common/Button.style';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import { BREAKPOINTS_VALUE } from 'Styles/Constants';
import { useState } from 'react';
import ProgramList from './ProgramList';
import ProgramProgress from './ProgramProgress';
import ProgramAbout from './ProgramAbout';
import { getWindowDimensions } from 'Helpers/Utils';
import { useLocation, useNavigate } from 'react-router-dom';

import Api from 'Helpers/ApiHandler';
import { API_URL, URL_POST_ASSESSMENT } from 'Helpers/Paths';
import TaoLoader from 'Components/common/TaoBackdropLoader';
import TaoScrollbar from 'Components/common/Scrollbar';
import moment from 'moment';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import {
    handleTopBarInteractionDetail,
    hideTopBarInteractionButton,
    showSubscriptionModal
} from 'Redux/App/Actions';
import { useDispatch } from 'react-redux';
import PurchaseContentModal from 'Components/common/Modal/Purchase/Purchase';

const CHIPS = {
    PROGRAM: {
        ID: 1,
        TYPE: 'Program'
    },
    PROGRESS: {
        ID: 2,
        TYPE: 'Progress'
    },
    ABOUT: {
        ID: 3,
        TYPE: 'About'
    }
};

const TODAY_DATE = moment()?.format('YYYY-MM-DD');

const Programs = () => {
    const [selectedChip, setSelectedChip] = useState(CHIPS.PROGRAM.ID);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [programDetails, setProgramDetails] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [programContent, setProgramContent] = useState([]);
    const [programContentLoading, setProgramContentLoading] = useState(true);
    const [images, setImages] = useState([]);
    const API = useMemo(() => new Api(), []);
    const [currentSelectedDay, setCurrentSelectedDay] = useState(0);
    const [firstDayFirstContent, setFirstDayFirstContent] = useState(null);
    const [showBuyModal, setShowBuyModal] = useState(false);
    const queryParams = new URLSearchParams(location?.search);
    const linkProgramId = queryParams && queryParams?.get?.('programId');

    const onDaySelection = useCallback(
        async (day, programDateData = null, programData) => {
            if (selectedChip === CHIPS.PROGRAM.ID) setProgramContentLoading(true);
            else setIsLoading(true);

            const URL =
                selectedChip === CHIPS.PROGRAM.ID
                    ? API_URL.PROGRAM_CONTENT_DETAILS
                    : API_URL.PROGRAM_PROGRESS_DETAILS;
            let response = await API.post(URL, {
                params: {
                    programId: location?.state?.programId || linkProgramId,
                    isRedirectedFromHistory: location?.state?.isRedirectedFromHistory,
                    dayNo: day
                }
            });

            if (response) {
                setProgramContent(response?.data?.data);

                if (day === 1) setFirstDayFirstContent(response?.data?.data?.[0]);
                setCurrentSelectedDay(parseInt(day));
                if (selectedChip === CHIPS.PROGRAM.ID) {
                    setImages(
                        response?.data?.data?.map((item) => ({ url: item?.backgroundImageWeb }))
                    );
                    // setCurrentImageIndex(0);
                }

                if (
                    response?.data?.data &&
                    response?.data?.data?.[response?.data?.data?.length - 1] &&
                    programData?.daysList[programData?.daysList?.length - 1].programDay === day
                ) {
                    let isLastDay = programData?.completedDay === programData?.totalDays || 0;

                    let lastDayLastContentPlayed =
                        response?.data?.data?.[response?.data?.data?.length - 1]?.isPlayed;

                    let programCompletedWithLastContent = isLastDay && lastDayLastContentPlayed;

                    if (
                        !programData?.isRatingSkipped &&
                        programDateData?.programDate &&
                        !programData?.isPostAssessmentCompleted &&
                        ((isLastDay &&
                            moment(programDateData?.programDate, 'YYYY-MM-DD').isBefore(
                                TODAY_DATE
                            )) ||
                            (moment(programDateData?.programDate, 'YYYY-MM-DD').isSame(
                                TODAY_DATE
                            ) &&
                                programCompletedWithLastContent))
                    ) {
                        return navigate(URL_POST_ASSESSMENT, {
                            state: {
                                contentId: location?.state?.programId || linkProgramId,
                                isRedirectedFromHistory: location?.state?.isRedirectedFromHistory,
                                type: 'PROGRAM_ASSESSMENT',
                                title: programData?.title
                            }
                        });
                    }
                }
            }

            setIsLoading(false);
            setProgramContentLoading(false);
        },
        [
            API,
            location?.state?.programId,
            selectedChip,
            navigate,
            location?.state?.isRedirectedFromHistory,
            linkProgramId
        ]
    );

    const fetchProgramDetails = useCallback(async () => {
        setIsLoading(true);
        let response = await API.get(API_URL.USER_PROGRAM, {
            params: {
                programId: location?.state?.programId || linkProgramId,
                isRedirectedFromHistory: location?.state?.isRedirectedFromHistory
            }
        });
        if (response) {
            let responseData = { ...response?.data?.data };
            responseData.daysList = responseData?.daysList?.filter((day) => day?.programDay);
            setProgramDetails(responseData);

            if (
                responseData &&
                !responseData?.isProgramJoined &&
                responseData.isPaidContent &&
                !responseData?.isPurchased
            ) {
                dispatch(hideTopBarInteractionButton(true));
            }

            dispatch(handleTopBarInteractionDetail(responseData || {}));

            setIsLoading(false);
            let isLastDay =
                response?.data?.data?.completedDay === response?.data?.data?.totalDays || 0;

            onDaySelection(
                response?.data?.data?.completedDay ||
                    response?.data?.data?.daysList?.[0]?.programDay ||
                    1,
                isLastDay
                    ? response?.data?.data?.daysList?.[response?.data?.data?.daysList?.length - 1]
                    : response?.data?.data?.daysList?.[0],
                responseData
            );
        }
    }, [
        API,
        location?.state?.programId,
        onDaySelection,
        location?.state?.isRedirectedFromHistory,
        dispatch,
        linkProgramId
    ]);

    const joinProgram = async () => {
        setIsLoading(true);
        let response = await API.post(API_URL.USER_PROGRAM, {
            params: { programId: location?.state?.programId }
        });

        if (response) fetchProgramDetails();
    };

    const handleOpenGetContent = () => {
        setShowBuyModal(true);
    };

    const handleCloseGetContent = () => {
        setShowBuyModal(false);
    };

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        fetchProgramDetails();

        return () => {
            dispatch(handleTopBarInteractionDetail({}));
            dispatch(hideTopBarInteractionButton(false));
        };
    }, [fetchProgramDetails, dispatch]);

    return (
        <>
            <ProgramsWrapper>
                <TaoLoader isLoading={isLoading} />
                <Box className="container-wrapper">
                    <Box className="slide-container">
                        {!programContentLoading && (
                            <Fade arrows={false} duration={10000} cssClass="slide-wrapper">
                                {images.map((item, index) => (
                                    <Box key={index}>
                                        <img
                                            src={item?.url}
                                            alt="bg"
                                            style={{ height: '100%', width: '100%' }}
                                        />
                                    </Box>
                                ))}
                            </Fade>
                        )}
                    </Box>
                    <Box className="white-fade" />
                    <Box className="container">
                        <Box className="left-container">
                            <Box className="flex full-width header-container">
                                <Box className="flex f-column">
                                    <Box className="flex">
                                        <Box className="flex f-column">
                                            <Box style={{ paddingBottom: '15px' }}>
                                                <Typography className="title">
                                                    {programDetails?.title}
                                                </Typography>
                                            </Box>

                                            <Box className="flex f-v-center">
                                                {[1, 2, 3, 4, 5].map((record, index) => (
                                                    <React.Fragment key={index}>
                                                        {programDetails?.averageRatingCount >=
                                                        record ? (
                                                            <FilledRating />
                                                        ) : (
                                                            <UnFilledRating />
                                                        )}
                                                    </React.Fragment>
                                                ))}

                                                <span className="rating-count">
                                                    {programDetails?.totalRatingCount}
                                                </span>
                                                <Box
                                                    className="flex f-v-center"
                                                    style={{ marginLeft: '20px' }}>
                                                    <User />
                                                    <span className="user-count">
                                                        {programDetails?.totalUserJoinedCount}
                                                    </span>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                            <Box className="timer-container">
                                <CircularProgressbarWithChildren
                                    value={
                                        (100 * programDetails?.completedDay) /
                                            programDetails?.totalDays || 0
                                    }
                                    Meditations
                                    strokeWidth={8}
                                    className="progressBar"
                                    styles={buildStyles({
                                        rotation: 0.75,
                                        trailColor: '#fff',
                                        pathColor: '#FFD26D',
                                        textColor: '#fff'
                                    })}>
                                    <Box className="timer">
                                        {!programDetails?.isProgramJoined ? (
                                            <Typography className="timer-text">
                                                NOT STARTED
                                            </Typography>
                                        ) : (
                                            <React.Fragment>
                                                <Typography className="timer-text">Day</Typography>
                                                <Typography className="timer-text">
                                                    {programDetails?.completedDay}/
                                                    {programDetails?.totalDays}
                                                </Typography>
                                            </React.Fragment>
                                        )}
                                    </Box>
                                </CircularProgressbarWithChildren>
                            </Box>

                            {windowDimensions.width > BREAKPOINTS_VALUE.TABLET && (
                                <Box className="button-container">
                                    {programDetails?.subscription?.isAccessible &&
                                        ((!programDetails?.isProgramJoined &&
                                            programDetails.isPaidContent &&
                                            programDetails?.isPurchased) ||
                                            (!programDetails?.isProgramJoined &&
                                                !programDetails.isPaidContent) ||
                                            (programDetails?.isProgramJoined &&
                                                programDetails?.canRejoin &&
                                                !location?.state?.isRedirectedFromHistory)) && (
                                            <TaoButton onClick={() => joinProgram()}>
                                                <PlaylistIcon
                                                    height={'24px'}
                                                    width={'24px'}
                                                    style={{ marginRight: '7px' }}
                                                />
                                                {programDetails?.canRejoin ? 'Re - Join' : 'Join'}
                                            </TaoButton>
                                        )}

                                    {programDetails?.subscription?.isAccessible &&
                                        !programDetails?.isProgramJoined &&
                                        programDetails.isPaidContent &&
                                        !programDetails?.isPurchased && (
                                            <>
                                                <TaoButton onClick={() => handleOpenGetContent()}>
                                                    <PlaylistIcon
                                                        height={'24px'}
                                                        width={'24px'}
                                                        style={{ marginRight: '7px' }}
                                                    />
                                                    GET
                                                </TaoButton>
                                            </>
                                        )}

                                    {!isLoading && !programDetails?.subscription?.isAccessible && (
                                        <TaoButton
                                            onClick={() => dispatch(showSubscriptionModal())}>
                                            Subscribe
                                        </TaoButton>
                                    )}
                                </Box>
                            )}
                        </Box>

                        <Box className="right-container">
                            <Box className="flex full-width goal-chips-overflow">
                                <TaoScrollbar horizontalScroll keyId="chips">
                                    {Object.keys(CHIPS).map((chip) =>
                                        CHIPS?.[chip]?.ID === CHIPS.PROGRESS.ID &&
                                        !programDetails?.isProgramJoined ? (
                                            ''
                                        ) : (
                                            <Chip
                                                sx={{ color: 'text.dark' }}
                                                onClick={() => {
                                                    setProgramContentLoading(true);
                                                    setSelectedChip(CHIPS?.[chip]?.ID);
                                                }}
                                                key={CHIPS?.[chip]?.ID}
                                                className={
                                                    'goal-chip ' +
                                                    (CHIPS?.[chip]?.ID === selectedChip
                                                        ? 'selected-goal'
                                                        : 'non-selected-goal')
                                                }
                                                size="medium"
                                                color="primary"
                                                variant={
                                                    CHIPS?.[chip]?.ID === selectedChip
                                                        ? 'outlined'
                                                        : 'filled'
                                                }
                                                label={CHIPS?.[chip]?.TYPE}
                                            />
                                        )
                                    )}
                                </TaoScrollbar>
                            </Box>

                            {selectedChip !== CHIPS.ABOUT.ID &&
                                !!programDetails?.daysList?.length && (
                                    <WeekCalendar
                                        days={programDetails?.daysList}
                                        isProgramCalendar={true}
                                        onDaySelection={onDaySelection}
                                        completedDay={programDetails?.completedDay}
                                        isProgramJoined={programDetails?.isProgramJoined}
                                        cardRootClass={'week-calendar-container'}
                                        currentSelectedDay={currentSelectedDay}
                                        programDetails={programDetails}
                                    />
                                )}

                            {selectedChip === CHIPS.PROGRAM.ID ? (
                                <Box className="program-list-container">
                                    <ProgramList
                                        programDetails={programDetails}
                                        list={programContent || []}
                                        firstDayFirstContent={firstDayFirstContent}
                                        completedDay={programDetails?.completedDay}
                                        currentSelectedDay={currentSelectedDay}
                                        isLoading={programContentLoading}
                                    />
                                </Box>
                            ) : selectedChip === CHIPS.PROGRESS.ID &&
                              programDetails?.isProgramJoined ? (
                                <TaoScrollbar>
                                    <Typography classes={{ root: 'info-static-text' }}>
                                        Track your daily progress throughout the program. Find your
                                        next steps on the “Program” tab.
                                    </Typography>
                                    {!programContent?.length && (
                                        <Typography classes={{ root: 'no-progress-text' }}>
                                            No Progress Yet
                                        </Typography>
                                    )}
                                    {programContent.map((ele, index) => (
                                        <ProgramProgress
                                            key={index}
                                            data={ele}
                                            totalLength={programContent.length}
                                            swap={index % 2}
                                            index={index}
                                        />
                                    ))}
                                </TaoScrollbar>
                            ) : (
                                <TaoScrollbar>
                                    <ProgramAbout data={programDetails} />
                                </TaoScrollbar>
                            )}
                        </Box>
                    </Box>
                    {windowDimensions.width < BREAKPOINTS_VALUE.TABLET && (
                        <Box className="button-container">
                            {programDetails?.subscription?.isAccessible &&
                                ((!programDetails?.isProgramJoined &&
                                    programDetails.isPaidContent &&
                                    programDetails?.isPurchased) ||
                                    (!programDetails?.isProgramJoined &&
                                        !programDetails.isPaidContent) ||
                                    (programDetails?.isProgramJoined &&
                                        programDetails?.canRejoin &&
                                        !location?.state?.isRedirectedFromHistory)) && (
                                    <TaoButton onClick={() => joinProgram()}>
                                        <PlaylistIcon
                                            height={'24px'}
                                            width={'24px'}
                                            style={{ marginRight: '7px' }}
                                        />
                                        {programDetails?.canRejoin ? 'Re - Join' : 'Join'}
                                    </TaoButton>
                                )}

                            {programDetails?.subscription?.isAccessible &&
                                !programDetails?.isProgramJoined &&
                                programDetails.isPaidContent &&
                                !programDetails?.isPurchased && (
                                    <>
                                        <TaoButton onClick={() => handleOpenGetContent()}>
                                            <PlaylistIcon
                                                height={'24px'}
                                                width={'24px'}
                                                style={{ marginRight: '7px' }}
                                            />
                                            GET
                                        </TaoButton>
                                    </>
                                )}

                            {!isLoading && !programDetails?.subscription?.isAccessible && (
                                <TaoButton onClick={() => dispatch(showSubscriptionModal())}>
                                    Subscribe
                                </TaoButton>
                            )}
                        </Box>
                    )}
                </Box>
            </ProgramsWrapper>
            {windowDimensions.width > BREAKPOINTS_VALUE.TABLET && (
                <Footer showWhiteBackground={true} />
            )}
            <PurchaseContentModal
                open={showBuyModal}
                onClose={handleCloseGetContent}
                {...programDetails}
                // onPurchase={onPurchase}
            />
        </>
    );
};

export default Programs;
