import { Fade, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { ProgramProgressWrapper } from './Programs.style';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import { numberFormat, programProgressDataFormat } from 'Helpers';

const ProgramProgress = (props) => {
    return (
        <Fade in={true}>
            <ProgramProgressWrapper {...props}>
                <Box className="day-progress" style={{ position: 'relative' }}>
                    {props.swap && props.totalLength - 1 !== props.index ? (
                        <Box className="curve-image" />
                    ) : (
                        ''
                    )}
                    {!props.swap && props.totalLength - 1 !== props.index ? (
                        <Box className="curve-image-left" />
                    ) : (
                        ''
                    )}

                    <Box className="progress-wrapper">
                        <CircularProgressbarWithChildren
                            value={props?.data?.userMeditations}
                            maxValue={props?.data?.totalMeditations}
                            styles={buildStyles({
                                background: {
                                    fill: 'conic-gradient(from 0deg at 50% 50%, #FFF6E1 0deg, #FFD26D 258.14deg, #FFF6E1 360deg)'
                                }
                            })}>
                            <Box className="progress-inner-content">
                                <Box className="number">
                                    <Typography className="number-font">
                                        {numberFormat(props?.index + 1)}
                                    </Typography>
                                    <Typography className="date-font">
                                        {programProgressDataFormat(props?.data?.date)}
                                    </Typography>
                                </Box>
                            </Box>
                        </CircularProgressbarWithChildren>
                    </Box>

                    <Box
                        className="flex f-column f-h-center f-v-center"
                        style={{ width: '50%', gap: '30px' }}>
                        <Box className="flex f-column" style={{ gap: '15px' }}>
                            <Box className="flex f-h-center">
                                <Typography className="days-highlight">
                                    {props?.data?.userMeditations}
                                </Typography>

                                <Typography className="total-days">
                                    /{props?.data?.totalMeditations}
                                </Typography>
                            </Box>

                            <Box>
                                <Typography className="text">Meditations</Typography>
                            </Box>
                        </Box>

                        <Box className="flex f-column" style={{ gap: '15px' }}>
                            <Box className="flex f-h-center">
                                <Typography className="days-highlight">
                                    {props?.data?.minutes}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography className="text">minutes</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </ProgramProgressWrapper>
        </Fade>
    );
};

export default ProgramProgress;
