import { Popover } from '@mui/material';
import { Box } from '@mui/system';
import styled from 'styled-components';
import { BREAKPOINTS_VALUE, COLORS, FONTS, responsive } from 'Styles/Constants';

export const MeditationMediaPlayerWrapper = styled(Box)`
    /* height: 100vh; */
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: ${(props) => (props?.$isLoading ? 'hidden' : 'auto')};
    z-index: 2;
    position: relative;

    .image-background {
        position: absolute;
        height: 70%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        z-index: 1;

        ${responsive.TABLET`
            height: 85%;
        `}

        &.full-screen-mode {
            background: #000;
        }
    }

    .slider-track {
        background-color: #ffd26d;
        border: none;
    }
    .slider-rail {
        background-color: ${COLORS.MEDIUM_GREY};
    }
    .slider-thumb {
        background-color: ${COLORS.PRIMARY_DARK};
    }

    .white-background {
        height: 70%;
        width: 100%;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 45.31%);
        position: absolute;
        bottom: 0;
        z-index: 1;

        &.full-screen-mode {
            background: #000;
        }
    }

    .video-player-content {
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50%;

        ${responsive.DESKTOP`
            width: 60%;
        `}

        ${responsive.LAPTOP`
            width: 80%;
        `}

        ${responsive.MOBILE`
            width: 90%;
        `}

        &.full-screen-mode {
            width: 100%;
            padding: 0;
        }

        .playerDetailsWrapper {
            padding: 10px 0;
            width: 100%;

            .back-arrow {
                margin-right: 15px;

                ${responsive.TABLET`
                    position: absolute;
                    top: 10px;
                    left: 0;
                    height: 50px;
                    display: flex;
                    align-items: center;
                `}
            }

            .titleWrapper {
                width: 100%;

                ${responsive.TABLET`
                    text-align: center;
                `}

                .title {
                    font-family: ${FONTS.SECONDARY_BOLD};
                    font-size: 45px;
                    color: ${COLORS.PRIMARY};
                    text-transform: capitalize;

                    ${responsive.TABLET`
                        font-size: 36px;
                    `}
                }
            }

            .playerManagement {
                padding-top: 12px;

                ${responsive.TABLET`
                    flex-direction: column;
                `}

                &.remove-direction {
                    ${responsive.TABLET`
                        flex-direction: row;
                    `}
                }

                .progressBar {
                    width: 60%;
                    padding: 0 30px;

                    &.full-width-progressBar {
                        width: 100%;
                    }

                    ${responsive.TABLET`
                        width: 95%;
                        padding: 0;
                    `}
                }
                .chapters-repeat-container {
                    max-width: 25%;
                }

                .time {
                    color: ${COLORS.GREY_TEXT_COLOR};
                    font-family: ${FONTS.PRIMARY};
                    padding-left: 20px;

                    ${responsive.TABLET`
                        padding: 0;
                        margin: 0 15px 0 0;
                    `}

                    &.add-spacing {
                        ${responsive.TABLET`
                            padding-left: 15px;
                        `}
                    }
                    .time-text {
                        width: 45px;
                        font-size: 16px;
                    }

                    .time-divider {
                        margin: 0 5px;
                    }
                }
            }
        }

        .player-custom-controller {
            position: absolute;
            top: 50%;
            z-index: 10;
            width: 100%;
            transform: translate(0, -50%);

            &.hide-controls {
                visibility: hidden;
            }

            .player-buttons {
                ${responsive.PHABLET`
                    height: 110px;
                    width: 100%;
                `}

                .pause {
                    height: 100px;
                    width: 100px;
                    padding: 0 15px;
                    filter: drop-shadow(0px 2.95072px 25.4076px rgba(0, 0, 0, 0.15));

                    &.remove-spacing {
                        padding: 0;
                    }
                }
            }
        }

        .player {
            border-top-left-radius: 25px;
            border-bottom-right-radius: 25px;
            height: 100%;

            .player-width {
                width: 100%;

                .video-player-radius {
                    border-radius: 24px 8px 24px 8px;

                    &.full-screen-video {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        background-color: black;
                        border-radius: 0;
                    }
                }

                .bunny-video {
                    position: relative;
                    padding-top: 56.25%;
                    border-radius: 24px 8px 24px 8px;
                    overflow: hidden;
                    box-shadow: 0px 9px 18px 0px #00000026;
                }
                /* iframe {
                    border-radius: 24px 8px;
                    pointer-events: none;
                }

                .vimeo-video {
                    padding: 0;

                    div {
                        padding: 0 !important;
                        position: inherit !important;
                    }
                } */

                .audio-player-content {
                    width: 100%;
                    height: 100%;

                    .audio-image-container {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        background: ${COLORS.WHITE};
                        border: 3px solid ${COLORS.TEXT_DARK};
                        box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.15);
                        border-radius: 20px;
                        position: relative;
                        overflow: hidden;

                        padding-top: 56.25%;

                        &.remove-border {
                            border-radius: 0;
                        }

                        .audio-image {
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: contain;
                            /* height: 50vh; */
                            border-top-left-radius: 20px;
                            border-top-right-radius: 20px;

                            height: calc(100% - 40px);
                            width: 100%;
                            position: absolute;
                            top: 0;

                            &.full-audio-height {
                                height: 100%;
                            }

                            /* ${responsive.LAPTOP`
                                height: 60vh;
                            `}
                            ${responsive.TABLET`
                                height: 40vh;
                            `}
                            ${responsive.MOBILE`
                                height: 35vh;
                            `} */
                        }
                        .audio-text-background {
                            width: 100%;
                            background-color: ${COLORS.PRIMARY};
                            height: 40px;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            ${responsive.PHABLET`
                                justify-content: flex-start;
                                padding: 0 0 0 10px;
                            `}

                            .audio-image-text {
                                font-family: ${FONTS.PRIMARY};
                                font-size: 21px;
                                text-align: center;
                                letter-spacing: 0.01em;
                                text-transform: capitalize;
                                color: ${COLORS.WHITE};
                                max-width: 70%;

                                ${responsive.TABLET`
                                    max-width: 50%
                                `}
                                ${responsive.PHABLET`
                                    max-width: 60%
                                `}
                            }
                        }
                    }
                }

                .videoOptionContainer {
                    display: none;
                    /* display: flex; */
                    flex-direction: column;
                    justify-content: space-between;
                    width: 100%;
                    position: absolute;
                    bottom: 3%;
                    z-index: 10;
                    padding: 0 20px;

                    &.audio-controls {
                        display: flex;
                        bottom: 7px;
                    }

                    &.full-screen {
                        position: absolute;
                        bottom: 0;
                        top: auto;
                    }
                    &.hide-controls {
                        visibility: hidden;
                    }

                    .setting-icon {
                        margin: 0 0 0 auto;
                        transition: transform 0.5s;

                        &.active {
                            transform: rotate(90deg);
                        }
                    }

                    .sound-control {
                        height: 30px;
                        width: 35px;

                        .control-ui {
                            background: rgba(101, 101, 101, 0.5);
                            border-radius: 20px;
                            width: inherit;
                            height: 140px;
                            position: absolute;
                            bottom: 0;
                            z-index: 10;
                            transition: all 0.5s;
                            padding: 5px;

                            &.hidden {
                                height: 0;
                                opacity: 0;
                            }

                            .volume-slider {
                                height: 70%;
                                padding: 0 0 22px;

                                .volume-slider-track {
                                    background-color: #ffd26d;
                                    border: none;
                                    width: 8px;
                                }
                                .volume-slider-rail {
                                    width: 8px;
                                    background-color: ${COLORS.WHITE};
                                    opacity: 1;
                                }
                                .volume-slider-thumb {
                                    background-color: ${COLORS.WHITE};
                                }
                            }
                        }

                        .sound-control-icon {
                            z-index: 20;
                            position: relative;
                            height: 30px;
                            width: 35px;
                        }
                    }
                }

                .progressBar-fullscreen {
                    width: 100%;

                    &.hide-controls {
                        visibility: hidden;
                    }
                }
                .time-fullscreen {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    color: ${COLORS.PRIMARY};

                    &.hide-controls {
                        visibility: hidden;
                    }
                }
            }

            .step {
                font-family: ${FONTS.PRIMARY};
                font-size: 20px;
                color: ${COLORS.MEDIUM_GREY};
                padding: 0 7px;
                cursor: pointer;
                min-width: max-content;
            }

            .selected-step {
                font-family: ${FONTS.PRIMARY_MEDIUM};
            }
        }

        /* .playerDetailsWrapper {
            padding: 12px;
            width: 100%;
        } */

        .titleWrapper {
            width: 100%;

            ${responsive.TABLET`
                text-align: center;
                display: flex;
                flex-direction: column;
            `}
        }

        .title {
            font-family: ${FONTS.SECONDARY};
            font-weight: 700;
            font-size: 45px;
            color: ${COLORS.PRIMARY};
            text-transform: capitalize;
            padding: 0 40px 0 0;

            ${responsive.TABLET`
                font-size: 36px;
            `}
        }

        .playerManagement {
            padding-top: 12px;

            ${responsive.TABLET`
                flex-direction: column;
            `}
        }

        .previous,
        .forward {
            cursor: pointer;
            filter: drop-shadow(0px 2.95072px 25.4076px rgba(0, 0, 0, 0.15));
        }

        .pause {
            height: 100px;
            width: 100px;
            padding: 0 15px;
            cursor: pointer;
            display: flex;
            align-items: center;

            &.remove-spacing {
                height: 80px;
                padding: 0;

                ${responsive.TABLET`
                    height: 60px;
                    width: 60px;
                `}
            }
        }

        .back-arrow {
            margin-right: 15px;

            ${responsive.TABLET`
                position: absolute;
                top: 10px;
                left: 0;
            `}
        }

        .steps-margin {
            margin-right: 10px;
        }

        .step {
            font-family: ${FONTS.PRIMARY};
            font-size: 20px;
            color: ${COLORS.MEDIUM_GREY};
            padding: 0 7px;
            cursor: pointer;
            min-width: max-content;
        }

        .selected-step {
            font-family: ${FONTS.PRIMARY_MEDIUM};
        }

        .circle {
            padding: 0 10px;
            border: 1px solid ${COLORS.MEDIUM_GREY};
            border-radius: 26px;
            margin-right: 7px;
            display: flex;
            align-items: center;
            cursor: pointer;
            background-color: #fff;
        }

        .download {
            @media only screen and (min-width: ${BREAKPOINTS_VALUE.TABLET}px) {
                display: none;
            }
        }

        .line {
            height: 30px;
            width: 1px;
            border: 1px solid ${COLORS.MEDIUM_GREY};
            margin: 0 10px;
        }
    }
`;

export const PopOverWrapper = styled(Popover)`
    .captions-text-root {
        padding: 5px 10px;
        color: ${COLORS.TEXT_DARK};

        &:hover {
            color: ${COLORS.PRIMARY};
        }

        &.selected {
            color: ${COLORS.PRIMARY};
            background-color: ${COLORS.LIGHT_GREY};
        }
    }
`;

export const PlaybackSpeedPopOverWrapper = styled(Popover)`
    .root {
        height: 120px;
        width: 350px;
        background: rgba(242, 242, 242, 0.95);
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
        border-radius: 12px;
        overflow: hidden;
        margin: -10px 0 0 70px;

        ${responsive.PHABLET`
            width: 300px;
            margin: -10px 0 0 0;
        `}
    }

    .playback-box {
        overflow: hidden;
        padding: 8px 16px;

        .adjust-speed-title {
            color: ${COLORS.BLACK};
            font-size: 16px;
            font-family: ${FONTS.PRIMARY_SEMI_BOLD};
            text-align: center;
            margin: 0 auto;
        }

        .speed-values {
            display: flex;
            justify-content: space-between;
            margin: 8px 0 0;

            .speed-btn {
                background-color: ${COLORS.WHITE};
                box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
                border-radius: 15px;
                width: max-content;
                height: 30px;
                min-width: 50px;
                text-transform: capitalize;
                color: ${COLORS.BLACK};

                &.active-speed {
                    font-family: ${FONTS.PRIMARY_SEMI_BOLD};
                    background-color: ${COLORS.PRIMARY_DARK};
                    color: ${COLORS.WHITE};
                }
            }
        }
        .slower,
        .faster {
            text-transform: uppercase;
            color: ${COLORS.TEXT_DARK};
            font-size: 16px;

            &.end {
                margin: 8px 0 0 auto;
            }
        }

        .arrow {
            height: 10px;
            width: 10px;
            border-top: 1px solid ${COLORS.TEXT_DARK};
            border-right: 1px solid ${COLORS.TEXT_DARK};
            transform: rotate(45deg);

            &.previous {
                transform: rotate(225deg);
            }
        }
    }
`;
