import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Box, Button, Typography } from '@mui/material';
import { getWindowDimensions } from 'Helpers';
import Lottie from 'lottie-react';

// MASCOT
import PointingUpToStanding from 'Assets/AnimationFiles/TourAnimation/pointing-up-to-standing.json';
import PointingDownLeft from 'Assets/AnimationFiles/TourAnimation/points-down-and-left.json';
import PointingUpRight from 'Assets/AnimationFiles/TourAnimation/points-up-and-right.json';
import StandingToPointingUp from 'Assets/AnimationFiles/TourAnimation/standing-to-pointing-up.json';
import PointingIdle from 'Assets/AnimationFiles/TourAnimation/pointing-up-idle.json';
import PointingRightIdle from 'Assets/AnimationFiles/TourAnimation/pointing-up-right-idle.json';
// import PointingDownRight from 'Assets/AnimationFiles/TourAnimation/points-down-and-right.json';
import PointingUpLeft from 'Assets/AnimationFiles/TourAnimation/points-up-and-left.json';

import StandingDollAnimation from 'Assets/AnimationFiles/Questionnaire/MascotStandingIdleLoop.json';

import { WebsiteTourWrapper } from './WebsiteTour.style';
import { BREAKPOINTS_VALUE } from 'Styles/Constants';
import DOMPurify from 'dompurify';
import Slider from 'react-slick';

const WHITE_CIRCLE_DIMENSION = {
    WIDTH: '200px',
    HEIGHT: '200px',
    WIDTH_BY_TWO: '100px',
    HEIGHT_BY_TWO: '100px'
};

const TOP_BAR_WHITE_CIRCLE_DIMENSION = {
    WIDTH: '60px',
    HEIGHT: '60px',
    WIDTH_BY_TWO: '30px',
    HEIGHT_BY_TWO: '30px'
};

const CONTENT_DIMENSION = {
    WIDTH: '500px',
    HEIGHT: '200px',
    WIDTH_BY_TWO: '250px',
    HEIGHT_BY_TWO: '100px'
};
const MASCOT_WIDTH = 300;
const MASCOT_TIMEOUT = 500;

const STEPs_INDEX = [
    { step: 'INIT', content: 'This app has video, guided audio, and music meditation.' },
    { step: 'ABOUT', content: 'See what this meditation is <b>about</b>.' },
    { step: 'REVIEW', content: '<b>Review</b> what others have experienced.' },
    { step: 'LIKE', content: 'Like...' },
    { step: 'SHARE', content: 'Share...' },
    { step: 'FAV', content: 'Favorite...' },

    // OTHERS
    { step: 'OTHER', content: 'While you meditate, <b>Zen Mode</b> will disable notifications.' },
    { step: 'OTHER', content: 'You can mange zen mode setting in your <b>profile</b>.' },
    { step: 'OTHER', content: 'Ready to Start the Mediation?' }
];

// 6 STEPS
const LOTTIE_ANIMATION = [
    PointingUpToStanding,
    PointingDownLeft,
    PointingDownLeft,
    PointingUpRight,
    PointingUpRight,
    PointingUpRight,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation
];

const IDLE_LOTTIE_ANIMATION = [
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    PointingRightIdle,
    PointingRightIdle,
    PointingRightIdle,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation
];

const MOBILE_LOTTIE_ANIMATION = [
    PointingUpToStanding,
    PointingUpLeft,
    PointingUpLeft,
    StandingToPointingUp,
    StandingToPointingUp,
    StandingToPointingUp,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation
];

const MOBILE_IDLE_LOTTIE_ANIMATION = [
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation,
    PointingIdle,
    PointingIdle,
    PointingIdle,
    StandingDollAnimation,
    StandingDollAnimation,
    StandingDollAnimation
];

const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: false,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false,
    touchMove: false
};

const FooterButtons = forwardRef(
    (
        {
            backButtonTitle = '',
            nextButtonTitle = '',
            currentStep,
            setCurrentStep,
            updateButtons,
            isFinalStep = false,
            ...props
        },
        ref
    ) => (
        <Box className="button-parent-wrapper">
            <Button
                onClick={() => {
                    if (isFinalStep) return props?.startFormInit();
                    ref.current.slickPrev();
                    setCurrentStep(currentStep - 1);
                }}
                className={`btn back ${!currentStep && 'hide'}`}>
                <Typography
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(backButtonTitle)
                    }}
                    className={`font ${isFinalStep && 'text-transform'}`}
                />
            </Button>
            <Button
                onClick={() => {
                    if (isFinalStep) return props?.closeTutorial();
                    if (currentStep + 1 === STEPs_INDEX?.length - 1) updateButtons();
                    ref.current.slickNext();
                    setCurrentStep(currentStep + 1);
                }}
                className={`btn font primary`}>
                <Typography
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(nextButtonTitle)
                    }}
                    className={`font ${isFinalStep && 'text-transform'}`}
                />
            </Button>
        </Box>
    )
);

const WebsiteTour = ({ isLoading, ...props }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [isCompletedAnimation, setIsCompletedAnimation] = useState(false);
    const [loop, setLoop] = useState(false);
    const animationRef = useRef(null);
    const slickRef = useRef(null);
    const buttonRef = useRef(null);
    const [mascotPosition, setMascotPosition] = useState({
        transform: undefined
    });

    const [tourStaringId, setStartingTourId] = useState({
        width: undefined,
        height: undefined,
        transform: undefined
    });
    const [contentDimension, setContentDimension] = useState({
        width: CONTENT_DIMENSION.WIDTH,
        height: CONTENT_DIMENSION.HEIGHT,
        halfWidth: CONTENT_DIMENSION.WIDTH_BY_TWO,
        halfHeight: CONTENT_DIMENSION.HEIGHT_BY_TWO
    });
    const [polygonDimension, setPolygonDimension] = useState('');

    const [boxPosition, setBoxPosition] = useState('');
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    const setDimensions = useCallback(
        (animationDimension) => {
            if (windowDimensions.width <= BREAKPOINTS_VALUE.PHABLET) {
                setStartingTourId({
                    transform: `translate(calc(${animationDimension?.left}px - 75px + ${
                        animationDimension.width / 2
                    }px), calc(${animationDimension?.top}px - 75px + ${
                        animationDimension?.height / 2
                    }px))`,
                    width: '150px',
                    height: '150px'
                });
                return;
            }

            setStartingTourId({
                transform: `translate(calc(${animationDimension?.left}px - ${
                    WHITE_CIRCLE_DIMENSION.WIDTH_BY_TWO
                } + ${animationDimension.width / 2}px), calc(${animationDimension?.top}px - ${
                    WHITE_CIRCLE_DIMENSION.HEIGHT_BY_TWO
                } + ${animationDimension?.height / 2}px))`,
                width: WHITE_CIRCLE_DIMENSION.WIDTH,
                height: WHITE_CIRCLE_DIMENSION.HEIGHT
            });
        },
        [windowDimensions.width]
    );

    const setTopBarDimensions = (animationDimension) => {
        setStartingTourId({
            transform: `translate(calc(${animationDimension?.left}px - ${
                TOP_BAR_WHITE_CIRCLE_DIMENSION.WIDTH_BY_TWO
            } + ${animationDimension.width / 2}px), calc(${animationDimension?.top}px - ${
                TOP_BAR_WHITE_CIRCLE_DIMENSION.HEIGHT_BY_TWO
            } + ${animationDimension?.height / 2}px))`,
            width: TOP_BAR_WHITE_CIRCLE_DIMENSION.WIDTH,
            height: TOP_BAR_WHITE_CIRCLE_DIMENSION.HEIGHT
        });
    };

    const fetchLikeIconContent = useCallback(() => {
        let animationDimension = document
            ?.getElementById?.('like-icon-id')
            ?.getBoundingClientRect?.();

        if (animationDimension)
            setTimeout(() => setTopBarDimensions(animationDimension), MASCOT_TIMEOUT);
    }, []);

    const fetchFavIconContent = useCallback(() => {
        let animationDimension = document
            ?.getElementById?.('fav-icon-id')
            ?.getBoundingClientRect?.();

        if (animationDimension)
            setTimeout(() => setTopBarDimensions(animationDimension), MASCOT_TIMEOUT);
    }, []);

    const fetchShareIconContent = useCallback(() => {
        let animationDimension = document
            ?.getElementById?.('top-bar-share-id')
            ?.getBoundingClientRect?.();

        if (animationDimension)
            setTimeout(() => setTopBarDimensions(animationDimension), MASCOT_TIMEOUT);
    }, []);

    const fetchAboutTabContent = useCallback(() => {
        let animationDimension = document?.getElementById?.('about-tab')?.getBoundingClientRect?.();

        if (animationDimension) setTimeout(() => setDimensions(animationDimension), MASCOT_TIMEOUT);
    }, [setDimensions]);

    const fetchReviewTabContent = useCallback(() => {
        let animationDimension = document
            ?.getElementById?.('review-tab')
            ?.getBoundingClientRect?.();

        if (animationDimension) setTimeout(() => setDimensions(animationDimension), MASCOT_TIMEOUT);
    }, [setDimensions]);

    const initAnimation = useCallback(() => {
        let animationDimension = document
            ?.getElementById?.('tour-staring-box')
            ?.getBoundingClientRect?.();

        if (windowDimensions.width <= BREAKPOINTS_VALUE.PHABLET) {
            setStartingTourId({
                transform: `translate(calc(${windowDimensions.width}px - ${
                    MASCOT_WIDTH / 2
                }px - 25px), calc(${windowDimensions.height - MASCOT_WIDTH - 80}px))`,
                width: '150px',
                height: '150px'
            });
            setMascotPosition({
                transform: `translate(calc(${windowDimensions.width}px - 200px), calc(${
                    windowDimensions.height - MASCOT_WIDTH - 80
                }px - 25px))`
            });
            setBoxPosition(
                `translate(20px, calc(${windowDimensions.height}px - ${contentDimension.height} - 20px - 15px))`
            );
            setPolygonDimension(`translate(-70px, -30px) rotate(45deg)`);
            return;
        }
        if (windowDimensions.width <= BREAKPOINTS_VALUE.TABLET) {
            setStartingTourId({
                transform: `translate(calc(${
                    windowDimensions.width
                }px - ${MASCOT_WIDTH}px + 50px), ${MASCOT_WIDTH / 2}px)`,
                width: WHITE_CIRCLE_DIMENSION.WIDTH,
                height: WHITE_CIRCLE_DIMENSION.HEIGHT
            });
            setBoxPosition(
                `translate(calc(${windowDimensions.width}px - ${contentDimension.width} - 250px), 150px)`
            );
            setPolygonDimension(
                `translate(0px, calc(${contentDimension.halfHeight} - 17px - 20px)) rotate(45deg)`
            );
            setMascotPosition({
                transform: `translate(calc(${windowDimensions.width}px - ${MASCOT_WIDTH}px), 100px)`
            });
            return;
        }
        if (animationDimension && windowDimensions.width >= BREAKPOINTS_VALUE.TABLET) {
            let MINUS_TOP_FOR_TEXT = 150;
            let initTransform = `translate(calc(${animationDimension?.left}px), calc(${animationDimension?.top}px - ${WHITE_CIRCLE_DIMENSION.HEIGHT} - ${MINUS_TOP_FOR_TEXT}px - 20px))`;
            let initMascotTransform = `translate(calc(${animationDimension?.left}px - 50px), calc(${animationDimension?.top}px - ${WHITE_CIRCLE_DIMENSION.HEIGHT} - ${MINUS_TOP_FOR_TEXT}px - 20px - 50px))`;
            setStartingTourId({
                transform: initTransform,
                width: WHITE_CIRCLE_DIMENSION.WIDTH,
                height: WHITE_CIRCLE_DIMENSION.HEIGHT
            });
            setBoxPosition(
                `translate(calc(${animationDimension?.left}px - ${contentDimension.width} - 10px), calc(${animationDimension?.top}px - ${WHITE_CIRCLE_DIMENSION.HEIGHT} - ${MINUS_TOP_FOR_TEXT}px - 20px))`
            );
            setPolygonDimension(
                `translate(0px, calc(${contentDimension.halfHeight} - 17px - 20px)) rotate(45deg)`
            );

            setMascotPosition({ transform: initMascotTransform });
            return;
        }
    }, [
        windowDimensions.width,
        contentDimension.width,
        contentDimension.halfHeight,
        windowDimensions.height,
        contentDimension.height
    ]);

    const replayAnimation = () => {
        animationRef.current.stop();
        animationRef.current.play();
    };

    const updateAnimationStatus = () => {
        setIsCompletedAnimation(false);
        setLoop(false);
    };

    const jumpToStep = useCallback(() => {
        switch (STEPs_INDEX?.[currentStep]?.step) {
            case 'INIT':
                updateAnimationStatus();
                initAnimation();
                break;
            case 'ABOUT':
                updateAnimationStatus();
                replayAnimation();
                fetchAboutTabContent();
                break;
            case 'REVIEW':
                updateAnimationStatus();
                replayAnimation();
                fetchReviewTabContent();
                break;
            case 'SHARE':
                replayAnimation();
                fetchShareIconContent();
                break;
            case 'FAV':
                replayAnimation();
                fetchFavIconContent();
                break;
            case 'LIKE':
                replayAnimation();
                fetchLikeIconContent();
                break;
            default:
                updateAnimationStatus();
                initAnimation();
                return;
        }
    }, [
        initAnimation,
        currentStep,
        fetchAboutTabContent,
        fetchReviewTabContent,
        fetchShareIconContent,
        fetchFavIconContent,
        fetchLikeIconContent
    ]);

    const updateButtons = () => {
        buttonRef.current.slickNext();
    };

    const startFormInit = () => {
        slickRef?.current?.slickGoTo(0);
        buttonRef?.current?.slickPrev();
        setCurrentStep(0);
    };

    useEffect(() => {
        jumpToStep();
        if (windowDimensions.width <= BREAKPOINTS_VALUE.PHABLET) {
            setContentDimension((prev) => ({ ...prev, width: '90%', height: '200px' }));
            return;
        }
        if (windowDimensions.width <= BREAKPOINTS_VALUE.TABLET) {
            setContentDimension((prev) => ({ ...prev, width: '300px', height: '250px' }));
            return;
        }
    }, [windowDimensions?.height, windowDimensions?.width, isLoading, jumpToStep]);

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions());
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <React.Fragment>
            {!isLoading &&
                createPortal(
                    <WebsiteTourWrapper>
                        <Box className="tour">
                            <Box
                                className="tour-starting-box"
                                style={{
                                    transform: tourStaringId?.transform,
                                    width: tourStaringId?.width,
                                    height: tourStaringId?.width
                                }}
                            />
                        </Box>
                        <Box className="content-container">
                            <Lottie
                                className="mascot"
                                animationData={
                                    windowDimensions.width > BREAKPOINTS_VALUE.PHABLET
                                        ? isCompletedAnimation
                                            ? IDLE_LOTTIE_ANIMATION[currentStep]
                                            : LOTTIE_ANIMATION[currentStep]
                                        : isCompletedAnimation
                                        ? MOBILE_IDLE_LOTTIE_ANIMATION[currentStep]
                                        : MOBILE_LOTTIE_ANIMATION[currentStep]
                                }
                                style={{
                                    transform: mascotPosition?.transform
                                }}
                                onComplete={() => {
                                    setIsCompletedAnimation(true);
                                    setLoop(true);
                                }}
                                lottieRef={animationRef}
                                loop={loop}
                            />
                            <Box
                                className="tour-content"
                                style={{
                                    transform: boxPosition,
                                    width: contentDimension?.width,
                                    height: contentDimension?.height
                                }}>
                                <Box className="polygon" style={{ transform: polygonDimension }} />
                                <Box className="content">
                                    <Slider {...settings} ref={slickRef}>
                                        {STEPs_INDEX.map((item, index) => (
                                            <Box
                                                className="text"
                                                key={index}
                                                dangerouslySetInnerHTML={{
                                                    __html: DOMPurify.sanitize(item?.content)
                                                }}
                                            />
                                        ))}
                                    </Slider>

                                    <Box className="footer-buttons">
                                        <Slider {...settings} ref={buttonRef}>
                                            <FooterButtons
                                                ref={slickRef}
                                                currentStep={currentStep}
                                                setCurrentStep={setCurrentStep}
                                                backButtonTitle="back"
                                                nextButtonTitle="next"
                                                updateButtons={updateButtons}
                                            />
                                            <FooterButtons
                                                ref={slickRef}
                                                currentStep={currentStep}
                                                setCurrentStep={setCurrentStep}
                                                backButtonTitle="NO <br/> Continue Tutorial"
                                                nextButtonTitle="YES <br/> Close Tutorial"
                                                updateButtons={updateButtons}
                                                isFinalStep={true}
                                                startFormInit={startFormInit}
                                                closeTutorial={() => props?.setTourEnable(false)}
                                            />
                                        </Slider>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                        <Box className="close-tour">
                            <Typography
                                className="hover close-tour-text"
                                onClick={() => props?.setTourEnable(false)}>
                                X Close Tutorial
                            </Typography>
                        </Box>
                    </WebsiteTourWrapper>,
                    document.body
                )}
        </React.Fragment>
    );
};

export default WebsiteTour;
